import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, Container } from "reactstrap";
import { FrappeGantt } from "frappe-gantt-react";
import { MetaTags } from "react-meta-tags";
import Breadcrumbs from "components/Common/Breadcrumb";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getProjects, updateProject } from "store/actions";
import moment from "moment";

const ViewMode = {
  Day: "Day",
  Week: "Week",
  Month: "Month",
};

class ProjectsChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "Day",
    };
  }

  componentDidMount() {
    const { onGetProjects } = this.props;
    onGetProjects();
  }

  getGanttChartData = () => {
    const { projects } = this.props;

    let data = [];

    projects?.forEach(project => {
      data.push({
        id: `${project.id}`,
        name: project.name,
        start: project.start_date,
        end: project.end_date,
        progress: '0',
      });
    });

    return data;
  };

  onDateChange = (project, start, end) => {
    const { onUpdateProject } = this.props;
    const values = {
      ...project,
      start_date: moment(start).format("YYYY-MM-DD"),
      end_date: moment(end).format("YYYY-MM-DD"),
    };
    onUpdateProject(values);
  };

  onClick = project =>
    this.props.history.push(`/projects-overview/${project.id}`);

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Projects Overview | AD&amp;VISION - Event Tool</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Projects" breadcrumbItem="Overview" />
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  Projects
                  <div className="btn-group float-end" role="group">
                    {Object.keys(ViewMode).map(mode => (
                      <button
                        key={mode}
                        type="button"
                        className={`btn btn-outline-primary w-sm ${
                          mode === this.state.mode ? "active" : ""
                        }`}
                        onClick={() => this.setState({ mode })}
                      >
                        {ViewMode[mode]}
                      </button>
                    ))}
                  </div>
                </CardTitle>
                {this.getGanttChartData().length > 0 && (
                  <FrappeGantt
                    tasks={this.getGanttChartData()}
                    viewMode={this.state.mode}
                    onClick={this.onClick}
                    onDateChange={this.onDateChange}
                  />
                )}
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ProjectsChart.propTypes = {
  history: PropTypes.any,
  projects: PropTypes.array,
  onGetProjects: PropTypes.func,
  onUpdateProject: PropTypes.func,
};

const mapStateToProps = ({ projects }) => ({
  projects: projects.projects,
});

const mapDispatchToProps = dispatch => ({
  onGetProjects: () => dispatch(getProjects()),
  onUpdateProject: project => dispatch(updateProject(project)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectsChart));
