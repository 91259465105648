import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// get project
export const getProjects = (archived = false) =>
  get(`${url.GET_PROJECTS}?archived=${archived ? 1 : 0}`);

// get project details
export const getProjectsDetails = id => get(`${url.GET_PROJECT_DETAIL}/${id}`);

// add project
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project);

// update project
export const updateProject = project =>
  post(`${url.UPDATE_PROJECT}/${project.id}`, project);

// import project details
export const importProjectDetails = project =>
  post(`${url.IMPORT_PROJECT_DETAILS}/${project.id}`, project);

// delete project
export const deleteProject = (id, archive = false) =>
  del(`${url.DELETE_PROJECT}/${id}?archive=${archive ? 1 : 0}`);
