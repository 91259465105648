/* ROADSHOWS */
export const GET_ROADSHOWS = "GET_ROADSHOWS";
export const GET_ROADSHOWS_SUCCESS = "GET_ROADSHOWS_SUCCESS";
export const GET_ROADSHOWS_FAIL = "GET_ROADSHOWS_FAIL";

/* ROADSHOWS DETAIL*/
export const GET_ROADSHOW_DETAIL = "GET_ROADSHOW_DETAIL";
export const GET_ROADSHOW_DETAIL_SUCCESS = "GET_ROADSHOW_DETAIL_SUCCESS";
export const GET_ROADSHOW_DETAIL_FAIL = "GET_ROADSHOW_DETAIL_FAIL";

export const ADD_NEW_ROADSHOW = "ADD_NEW_ROADSHOW";
export const ADD_ROADSHOW_SUCCESS = "ADD_ROADSHOW_SUCCESS";
export const ADD_ROADSHOW_FAIL = "ADD_ROADSHOW_FAIL";

export const UPDATE_ROADSHOW = "UPDATE_ROADSHOW";
export const UPDATE_ROADSHOW_SUCCESS = "UPDATE_ROADSHOW_SUCCESS";
export const UPDATE_ROADSHOW_FAIL = "UPDATE_ROADSHOW_FAIL";

export const DELETE_ROADSHOW = "DELETE_ROADSHOW";
export const DELETE_ROADSHOW_SUCCESS = "DELETE_ROADSHOW_SUCCESS";
export const DELETE_ROADSHOW_FAIL = "DELETE_ROADSHOW_FAIL";
