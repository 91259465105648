import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// get client
export const getClients = () => get(url.GET_CLIENTS);

// get client details
export const getClientsDetails = id => get(`${url.GET_CLIENT_DETAIL}/${id}`);

// add client
export const addNewClient = client => post(url.ADD_NEW_CLIENT, client);

// update client
export const updateClient = client =>
  post(`${url.UPDATE_CLIENT}/${client.id}`, client);

// delete client
export const deleteClient = id => del(`${url.DELETE_CLIENT}/${id}`);
