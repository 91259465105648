import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// get event
export const getEvents = () => get(url.GET_EVENTS);

// get event details
export const getEventsDetails = id => get(`${url.GET_EVENT_DETAIL}/${id}`);

// add event
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event);

// update event
export const updateEvent = event =>
  post(`${url.UPDATE_EVENT}/${event.id}`, event);

// delete event
export const deleteEvent = id => del(`${url.DELETE_EVENT}/${id}`);
