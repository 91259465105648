import { call, put, takeEvery } from "redux-saga/effects";

// Brand Redux States
import {
  GET_BRANDS,
  GET_BRAND_DETAIL,
  ADD_NEW_BRAND,
  DELETE_BRAND,
  UPDATE_BRAND,
} from "./actionTypes";
import {
  getBrandsSuccess,
  getBrandsFail,
  getBrandDetailSuccess,
  getBrandDetailFail,
  addBrandFail,
  addBrandSuccess,
  updateBrandSuccess,
  updateBrandFail,
  deleteBrandSuccess,
  deleteBrandFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getBrands,
  getBrandsDetails,
  addNewBrand,
  updateBrand,
  deleteBrand,
} from "helpers/Api/brands";

function* fetchBrands() {
  try {
    const response = yield call(getBrands);
    if (response.success) {
      yield put(getBrandsSuccess(response.data));
    } else {
      yield put(getBrandsFail(response.message));
    }
  } catch (error) {
    yield put(getBrandsFail(error));
  }
}

function* fetchBrandDetail({ brandId }) {
  try {
    if (brandId === undefined) {
      yield put(getBrandDetailSuccess(null));
    } else {
      const response = yield call(getBrandsDetails, brandId);
      if (response.success) {
        yield put(getBrandDetailSuccess(response.data));
      } else {
        yield put(getBrandDetailFail(response.message));
      }
    }
  } catch (error) {
    yield put(getBrandDetailFail(error));
  }
}

function* onUpdateBrand({ payload: brand }) {
  try {
    const response = yield call(updateBrand, brand);
    if (response.success) {
      yield put(updateBrandSuccess(response.data));
    } else {
      yield put(updateBrandFail(response.message));
    }
  } catch (error) {
    yield put(updateBrandFail(error));
  }
}

function* onDeleteBrand({ payload: brand }) {
  try {
    const response = yield call(deleteBrand, brand);
    if (response.success) {
      yield put(deleteBrandSuccess(response.data));
    } else {
      yield put(deleteBrandFail(response.message));
    }
  } catch (error) {
    yield put(deleteBrandFail(error));
  }
}

function* onAddNewBrand({ payload: brand }) {
  try {
    const response = yield call(addNewBrand, brand);
    if (response.success) {
      yield put(addBrandSuccess(response.data));
    } else {
      yield put(addBrandFail(response.message));
    }
  } catch (error) {
    yield put(addBrandFail(error));
  }
}

function* brandsSaga() {
  yield takeEvery(GET_BRANDS, fetchBrands);
  yield takeEvery(GET_BRAND_DETAIL, fetchBrandDetail);
  yield takeEvery(ADD_NEW_BRAND, onAddNewBrand);
  yield takeEvery(UPDATE_BRAND, onUpdateBrand);
  yield takeEvery(DELETE_BRAND, onDeleteBrand);
}

export default brandsSaga;
