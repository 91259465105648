import React, { Component } from "react";
import PropTypes, { string } from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import { map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Card
import CardContact from "./card-contact";

import { getUsers } from "../../store/contacts/actions";
import UserForm from "pages/Forms/UserForm";

class ContactsGrid extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      page: 1,
      perPage: 8,
      activeTab: "",
      filter: "",
    };
  }

  componentDidMount() {
    const { users, onGetUsers } = this.props;
    if (users && !users.length) {
      onGetUsers();
    }
  }

  handlePageClick = page => {
    this.setState({ page });
  };

  toggleTab = tab => {
    if (this.state.activeTab === tab) {
      this.setState({ page: 1, activeTab: "", filter: "" });
    } else {
      this.setState({ page: 1, activeTab: tab, filter: tab });
    }
  };

  filterUser = user => {
    if (this.state.filter === "agency") {
      return !["promoter", "client"].includes(user.designation?.toLowerCase());
    }
    return (
      !this.state.filter ||
      user.designation?.toLowerCase() === this.state.filter
    );
  };

  render() {
    const { page, perPage } = this.state;
    const { users } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Contacts | AD&amp;VISION Event Management</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Contacts" breadcrumbItem="Users" />
            <Row>
              <Col lg="6">
                <Link to="/contacts-list">
                  <i
                    className="mdi mdi-format-list-bulleted"
                    style={{ fontSize: "2em" }}
                  ></i>
                </Link>
              </Col>
              <Col lg="6">
                <div className="text-sm-end">
                  <ButtonGroup>
                    <Button
                      color="primary"
                      className="font-16 btn-block btn btn-primary"
                      onClick={() => this.setState({ modal: true })}
                    >
                      <i className="mdi mdi-plus-circle-outline me-1" />
                      Create New User
                    </Button>
                    <Button
                      color="success"
                      className="font-16 btn-block btn btn-primary"
                      onClick={() =>
                        this.props.history.push("/contacts-import")
                      }
                    >
                      <i className="mdi mdi-import me-1" />
                      Import
                    </Button>
                  </ButtonGroup>
                </div>
              </Col>
            </Row>
            <ul
              className="nav nav-tabs nav-tabs-custom justify-content-center py-2"
              role="tablist"
            >
              <Nav justified className="mb-4">
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === "" ? "active" : ""}
                    onClick={() => {
                      this.toggleTab("");
                    }}
                  >
                    <i className="bx bx-chat font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">All</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      this.state.activeTab === "client" ? "active" : ""
                    }
                    onClick={() => {
                      this.toggleTab("client");
                    }}
                  >
                    <i className="bx bx-chat font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">Client</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      this.state.activeTab === "promoter" ? "active" : ""
                    }
                    onClick={() => {
                      this.toggleTab("promoter");
                    }}
                  >
                    <i className="bx bx-group font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">Promoter</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      this.state.activeTab === "agency" ? "active" : ""
                    }
                    onClick={() => {
                      this.toggleTab("agency");
                    }}
                  >
                    <i className="bx bx-book-content font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">Agency</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </ul>
            <Row>
              {map(
                users
                  .filter(this.filterUser)
                  .slice((page - 1) * perPage, page * perPage),
                (user, contactkey) => (
                  <CardContact user={user} key={"_user_" + contactkey} />
                )
              )}
            </Row>
            <Row>
              <Col lg="12">
                <Pagination className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                  <PaginationItem disabled={page === 1}>
                    <PaginationLink
                      previous
                      href="#"
                      onClick={() => this.handlePageClick(page - 1)}
                    />
                  </PaginationItem>
                  {map(
                    Array(
                      Math.ceil(users.filter(this.filterUser).length / perPage)
                    ),
                    (item, i) => (
                      <PaginationItem active={i + 1 === page} key={"_k" + i}>
                        <PaginationLink
                          onClick={() => this.handlePageClick(i + 1)}
                          href="#"
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    )
                  )}
                  <PaginationItem
                    disabled={page === Math.ceil(users.length / perPage)}
                  >
                    <PaginationLink
                      next
                      href="#"
                      onClick={() => this.handlePageClick(page + 1)}
                    />
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </Container>

          <Modal isOpen={this.state.modal}>
            <ModalHeader
              toggle={() => this.setState({ modal: !this.state.modal })}
              tag="h4"
            >
              Add User
            </ModalHeader>
            <ModalBody>
              <UserForm onDone={() => this.setState({ modal: false })} />
            </ModalBody>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

ContactsGrid.propTypes = {
  history: PropTypes.any,
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsGrid));
