import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, NavLink, Table } from "reactstrap";
import { map } from "lodash";

class AttachedFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Attached Files</CardTitle>
          <div className="table-responsive">
            <Table className="table table-nowrap align-middle table-hover mb-0">
              <tbody>
                {map(this.props.files, (file, i) => (
                  <tr key={"_file_" + i}>
                    <td>
                      <div className="avatar-xs">
                        <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                          <i className="bx bxs-file" />
                        </span>
                      </div>
                    </td>
                    <td>
                      <h5 className="font-size-14 mb-1">
                        <NavLink
                          href={file.link}
                          target="blank"
                          className="p-0 text-dark"
                        >
                          {file.name}
                        </NavLink>
                      </h5>
                      <small>Size : {file.size}</small>
                    </td>
                    <td>
                      <div className="text-center">
                        <NavLink
                          href={file.link}
                          target="blank"
                          className="text-dark"
                        >
                          <i className="bx bx-download h3 m-0" />
                        </NavLink>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    );
  }
}

AttachedFiles.propTypes = {
  files: PropTypes.array,
};

export default AttachedFiles;
