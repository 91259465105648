import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// get venue
export const getVenues = () => get(url.GET_VENUES);

// get venue details
export const getVenuesDetails = id => get(`${url.GET_VENUE_DETAIL}/${id}`);

// add venue
export const addNewVenue = venue => post(url.ADD_NEW_VENUE, venue);

// update venue
export const updateVenue = venue =>
  post(`${url.UPDATE_VENUE}/${venue.id}`, venue);

// delete venue
export const deleteVenue = id => del(`${url.DELETE_VENUE}/${id}`);
