import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import Dropzone from "react-dropzone";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
  InputGroup,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {
  addNewProject,
  getProjectDetail,
  getProjects,
  updateProject,
} from "store/projects/actions";
import { addNewUser, getUsers } from "store/contacts/actions";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//Import Date Picker
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { any } from "prop-types";
import {
  addNewClient,
  addNewVenue,
  getClients,
  getTrainings,
  getVenues,
} from "store/actions";
import BrandForm from "pages/Forms/BrandForm";
import ClientForm from "pages/Forms/ClientForm";
import authHeader from "helpers/jwt-token-access/auth-token-header";
import VenueForm from "pages/Forms/VenueForm";
import { statuses } from "helpers/constants";

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "undo",
      "redo",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "de",
};

class ProjectsCreate extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      modalType: null,
      start_date: new Date(),
      end_date: new Date(),
      selectedFiles: [],
      client: "",
      client_id: "",
    };
    this.handleAcceptedFiles.bind(this);
  }

  componentDidMount() {
    const {
      match: { params },
      onGetProjectDetail,
      onGetTrainings,
      onGetProjects,
      users,
      onGetUsers,
      clients,
      venues,
      onGetClients,
      onGetVenues,
    } = this.props;
    onGetTrainings();
    onGetProjects();
    onGetProjectDetail(params?.id);
    if (users && !users.length) {
      onGetUsers();
    }

    if (clients?.length <= 0) {
      onGetClients();
    }

    if (venues?.length <= 0) {
      onGetVenues();
    }
  }

  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files });
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.clients.length &&
      prevProps.clients.length < this.props.clients.length
    ) {
      this.updateClient();
    }
  }

  updateClient = () => {
    console.log("updateClient");
    const newClient = this.props.clients.at(-1);
    this.setState({ client: newClient });
  };

  render() {
    const { projectDetail, clients, users, venues, trainings } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              {projectDetail?.id ? "Update" : "New"} Project | AD&amp;VISION -
              Event Management
            </title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Projects"
              breadcrumbItem={projectDetail?.id ? "Update" : "New"}
            />
            <Row>
              <Col lg="12">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id: projectDetail?.id,
                    status: (projectDetail && projectDetail.status) || 0,
                    name: (projectDetail && projectDetail.name) || "",
                    client: (projectDetail && projectDetail.client) || "",
                    client_id: (projectDetail && projectDetail.client_id) || "",
                    brand: (projectDetail && projectDetail.brand) || "",
                    brand_id: (projectDetail && projectDetail.brand_id) || "",
                    team: (projectDetail && projectDetail.team) || [],
                    trainings: (projectDetail && projectDetail.trainings) || [],
                    description:
                      (projectDetail && projectDetail.description) || "",
                    start_date:
                      (projectDetail && projectDetail.start_date) || "",
                    end_date: (projectDetail && projectDetail.end_date) || "",
                    budget: (projectDetail && projectDetail.budget) || "",
                    lks: (projectDetail && projectDetail.lks) || "",
                    kva: (projectDetail && projectDetail.kva) || "",
                    venue: (projectDetail && projectDetail.venue) || "",
                    location: (projectDetail && projectDetail.location) || "",
                    notes: (projectDetail && projectDetail.notes) || "",
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("Please Enter Project Name"),
                    description: Yup.string().required(
                      "Please Enter Project Description"
                    ),
                    start_date: Yup.date().required("Please Select Start Date"),
                    end_date: Yup.date().required("Please Select End Date"),
                    client: Yup.object().required("Please Select a Client"),
                    brand: Yup.object().required("Please Select a Brand"),
                    budget: Yup.number()
                      .min(1)
                      .required("Please Enter Project Budget"),
                  })}
                  onSubmit={async values => {
                    const filesData = new FormData();
                    try {
                      if (this.state.selectedFiles.length > 0) {
                        this.state.selectedFiles.forEach(file =>
                          filesData.append("files[]", file)
                        );
                        const res = await fetch(
                          `${process.env.REACT_APP_API_URL}/v1/upload`,
                          {
                            method: "POST",
                            body: filesData,
                            headers: authHeader(),
                          }
                        );
                        const json = await res.json();
                        values.files = json;
                      }
                    } catch (error) {
                      console.log("UploadError", error);
                    }
                    values.trainings = values.trainings.map(t => t.id);
                    if (projectDetail?.id) {
                      this.props.onUpdateProject(values);
                    } else {
                      this.props.onAddNewProject(values);
                    }
                    this.props.history.push("/projects-grid");
                  }}
                >
                  {({ values, errors, touched, setFieldValue }) => (
                    <React.Fragment>
                      <Form>
                        <Card>
                          <CardBody>
                            <CardTitle className="mb-4">Basic Data</CardTitle>
                            <Row>
                              <Col>
                                <Label className="form-label">Status</Label>
                                <Select
                                  options={statuses}
                                  value={statuses.find(
                                    s => s.value === values.status
                                  )}
                                  getOptionValue={option => option.value}
                                  getOptionLabel={option => option.name}
                                  onChange={newValue => {
                                    setFieldValue("status", newValue.value);
                                  }}
                                  className={
                                    errors.status && touched.status
                                      ? " is-invalid"
                                      : ""
                                  }
                                />
                                <ErrorMessage
                                  name="status"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-2">
                                  <Label
                                    htmlFor="name"
                                    className="col-form-label"
                                  >
                                    Name
                                  </Label>
                                  <Field
                                    name="name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.name && touched.name
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="mb-2">
                                  <Label
                                    htmlFor="start_date"
                                    className="col-form-label"
                                  >
                                    Staff
                                  </Label>
                                  <CreatableSelect
                                    name="team"
                                    isClearable
                                    isMulti
                                    value={values.team}
                                    options={users}
                                    getOptionLabel={option =>
                                      option.name || "Create New"
                                    }
                                    getOptionValue={option => option.id}
                                    onChange={(newValue, actionMeta) => {
                                      setFieldValue("team", newValue);
                                      console.log(newValue, actionMeta);
                                    }}
                                    onCreateOption={inputValue => {
                                      console.log("inputValue", inputValue);
                                      this.setState({
                                        modal: true,
                                        modalType: "user",
                                      });
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <Row className="mb-2">
                                  <Col lg="6">
                                    <Label
                                      htmlFor="start_date"
                                      className="col-form-label"
                                    >
                                      Client
                                    </Label>
                                    <CreatableSelect
                                      options={clients}
                                      value={values.client}
                                      getOptionValue={option => option.id}
                                      getOptionLabel={option =>
                                        option.__isNew__
                                          ? "Create New"
                                          : option.name
                                      }
                                      onCreateOption={inputValue => {
                                        console.log("inputValue", inputValue);
                                        this.setState({
                                          modal: true,
                                          modalType: "client",
                                        });
                                      }}
                                      onChange={newValue => {
                                        setFieldValue("client", newValue);
                                        setFieldValue(
                                          "client_id",
                                          newValue?.id
                                        );
                                        console.log(newValue, newValue?.id);
                                        setFieldValue("brand", "");
                                        setFieldValue("brand_id", "");
                                      }}
                                      className={
                                        errors.client && touched.client
                                          ? " is-invalid"
                                          : ""
                                      }
                                    />
                                    <ErrorMessage
                                      name="client"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                  <Col lg="6">
                                    <Label
                                      htmlFor="start_date"
                                      className="col-form-label"
                                    >
                                      Brand
                                    </Label>
                                    <CreatableSelect
                                      value={values.brand}
                                      options={values.client?.brands}
                                      getOptionValue={option => option.id}
                                      getOptionLabel={option =>
                                        option.name || "Create New"
                                      }
                                      onCreateOption={inputValue => {
                                        console.log("inputValue", inputValue);
                                        this.setState({
                                          modal: true,
                                          modalType: "brand",
                                        });
                                      }}
                                      onChange={newValue => {
                                        setFieldValue("brand", newValue);
                                        setFieldValue("brand_id", newValue?.id);
                                      }}
                                      className={
                                        errors.brand && touched.brand
                                          ? " is-invalid"
                                          : ""
                                      }
                                    />
                                    <ErrorMessage
                                      name="brand"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                                <div className="mb-2">
                                  <Row>
                                    <Col lg="6">
                                      <Label
                                        htmlFor="venue"
                                        className="col-form-label"
                                      >
                                        Venue
                                      </Label>
                                      <CreatableSelect
                                        options={venues}
                                        value={values.venue}
                                        getOptionValue={option => option.id}
                                        getOptionLabel={option =>
                                          option.__isNew__
                                            ? "Create New"
                                            : option.name
                                        }
                                        onCreateOption={inputValue => {
                                          console.log("inputValue", inputValue);
                                          this.setState({
                                            modal: true,
                                            modalType: "venue",
                                          });
                                        }}
                                        onChange={newValue => {
                                          setFieldValue("venue", newValue);
                                        }}
                                        className={
                                          errors.venue && touched.venue
                                            ? " is-invalid"
                                            : ""
                                        }
                                      />
                                      <ErrorMessage
                                        name="venue"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                    <Col lg="6">
                                      <Label
                                        htmlFor="start_date"
                                        className="col-form-label"
                                      >
                                        Location
                                      </Label>
                                      <CreatableSelect
                                        name="location"
                                        isClearable
                                        value={values.location}
                                        options={[
                                          { id: 1, name: "01327 – Dresden." },
                                          { id: 2, name: "01445 – Radebeul." },
                                          { id: 3, name: "01454 – Radeberg." },
                                          { id: 4, name: "01465 – Bogu bers." },
                                          {
                                            id: 5,
                                            name: "01468 – Radeburg.",
                                          },
                                          {
                                            id: 6,
                                            name: "01558 – Großenhain.",
                                          },
                                          { id: 7, name: "01809 – Dohna." },
                                          { id: 8, name: "01855 – Sebnitz." },
                                        ]}
                                        getOptionLabel={option =>
                                          option.name || "Create New"
                                        }
                                        getOptionValue={option => option.id}
                                        onChange={(newValue, actionMeta) => {
                                          setFieldValue("location", newValue);
                                          console.log(newValue, actionMeta);
                                        }}
                                        onCreateOption={inputValue =>
                                          console.log("inputValue", inputValue)
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                              <Col lg="12">
                                <div className="mb-2">
                                  <Label
                                    htmlFor="start_date"
                                    className="col-form-label"
                                  >
                                    Trainings
                                  </Label>
                                  <CreatableSelect
                                    name="trainings"
                                    isClearable
                                    isMulti
                                    value={values.trainings}
                                    options={trainings}
                                    getOptionLabel={option => option.title}
                                    getOptionValue={option => option.id}
                                    onChange={newValue => {
                                      setFieldValue("trainings", newValue);
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col lg="12">
                                <Label
                                  htmlFor="description"
                                  className="col-form-label"
                                >
                                  Description
                                </Label>
                                <div
                                  className={
                                    errors.description && touched.description
                                      ? " is-invalid"
                                      : ""
                                  }
                                >
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={values.description}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      setFieldValue("description", data);
                                    }}
                                    onBlur={(event, editor) => {
                                      const data = editor.getData();
                                      setFieldValue("description", data);
                                    }}
                                  />
                                </div>
                                <ErrorMessage
                                  name="description"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardBody>
                            <CardTitle className="mb-4">Briefing</CardTitle>
                            <Row>
                              <Col lg="6">
                                <div className="mb-2">
                                  <Label
                                    htmlFor="budget"
                                    className="col-form-label"
                                  >
                                    Budget
                                  </Label>
                                  <InputGroup>
                                    <InputGroupText>EUR</InputGroupText>
                                    <Field
                                      name="budget"
                                      type="number"
                                      min="0"
                                      className={
                                        "form-control mb-0" +
                                        (errors.budget && touched.budget
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="budget"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </InputGroup>
                                </div>
                                <div className="mb-2">
                                  <Label
                                    htmlFor="budget"
                                    className="col-form-label"
                                  >
                                    LKS#
                                  </Label>
                                  <Field
                                    name="lks"
                                    min="0"
                                    className={
                                      "form-control" +
                                      (errors.lks && touched.lks
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="lks"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="mb-2">
                                  <Label className="col-form-label">
                                    Attached Files
                                  </Label>
                                  <Dropzone
                                    onDrop={acceptedFiles =>
                                      this.handleAcceptedFiles(acceptedFiles)
                                    }
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone">
                                        <div
                                          className="dz-message needsclick p-0"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div className="dz-message needsclick">
                                            <div className="mb-2">
                                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                                            </div>
                                            <h4>
                                              Drop files here or click to
                                              upload.
                                            </h4>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  <div
                                    className="dropzone-previews mt-3"
                                    id="file-previews"
                                  >
                                    {this.state.selectedFiles.map((f, i) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                          key={i + "-file"}
                                        >
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col className="col-auto">
                                                <img
                                                  data-dz-thumbnail=""
                                                  height="80"
                                                  className="avatar-sm rounded bg-light"
                                                  alt={f.name}
                                                  src={f.preview}
                                                />
                                              </Col>
                                              <Col>
                                                <Link
                                                  to="#"
                                                  className="text-muted font-weight-bold"
                                                >
                                                  {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                  <strong>
                                                    {f.formattedSize}
                                                  </strong>
                                                </p>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Card>
                                      );
                                    })}
                                  </div>
                                </div>
                              </Col>
                              <Col lg="6">
                                <Row className="mb-2">
                                  <Col lg="6">
                                    <Label
                                      htmlFor="start_date"
                                      className="col-form-label"
                                    >
                                      Start Date
                                    </Label>
                                    <Field
                                      name="start_date"
                                      type="date"
                                      className={
                                        "form-control" +
                                        (errors.start_date && touched.start_date
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="start_date"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>

                                  <Col lg="6">
                                    <Label
                                      htmlFor="start_date"
                                      className="col-form-label"
                                    >
                                      End Date
                                    </Label>
                                    <Field
                                      name="end_date"
                                      type="date"
                                      className={
                                        "form-control" +
                                        (errors.end_date && touched.end_date
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="end_date"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Col>
                                </Row>
                                <div className="mb-2">
                                  <Label
                                    htmlFor="budget"
                                    className="col-form-label"
                                  >
                                    KVA#
                                  </Label>
                                  <Field
                                    name="kva"
                                    min="0"
                                    className={
                                      "form-control" +
                                      (errors.kva && touched.kva
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="kva"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="mb-2">
                                  <Label
                                    htmlFor="notes"
                                    className="col-form-label"
                                  >
                                    Notes
                                  </Label>
                                  <Field
                                    name="notes"
                                    as="textarea"
                                    rows={8}
                                    className={
                                      "form-control" +
                                      (errors.notes && touched.notes
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="notes"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="justify-content-end">
                              <Col lg="auto">
                                <Button type="submit" color="primary">
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <Modal isOpen={this.state.modal}>
                          <ModalHeader
                            toggle={() =>
                              this.setState({ modal: !this.state.modal })
                            }
                            tag="h4"
                          >
                            Add {this.state.modalType}
                          </ModalHeader>
                          <ModalBody>
                            {this.state.modalType === "brand" && (
                              <BrandForm
                                client={this.state.client}
                                onDone={() => this.setState({ modal: false })}
                              />
                            )}
                            {this.state.modalType === "client" && (
                              <ClientForm
                                onDone={() => this.setState({ modal: false })}
                              />
                            )}
                            {this.state.modalType === "venue" && (
                              <VenueForm
                                onDone={() => this.setState({ modal: false })}
                              />
                            )}
                          </ModalBody>
                        </Modal>
                      </Form>
                    </React.Fragment>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ProjectsCreate.propTypes = {
  history: any,
  projectDetail: PropTypes.any,
  match: PropTypes.object,
  onGetProjectDetail: PropTypes.func,
  onUpdateProject: PropTypes.func,
  projects: PropTypes.array,
  onGetProjects: PropTypes.func,
  onAddNewProject: PropTypes.func,
  users: PropTypes.array,
  trainings: PropTypes.array,
  onGetTrainings: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onGetUsers: PropTypes.func,
  clients: PropTypes.array,
  onGetClients: PropTypes.func,
  onAddNewClient: PropTypes.func,
  venues: PropTypes.array,
  onGetVenues: PropTypes.func,
  onAddNewVenue: PropTypes.func,
};

const mapStateToProps = ({
  Clients,
  contacts,
  projects,
  Venues,
  Trainings,
}) => ({
  users: contacts.users,
  projects: projects.projects,
  projectDetail: projects.projectDetail,
  clients: Clients.clients,
  venues: Venues.venues,
  trainings: Trainings.trainings,
});

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
  onGetProjects: () => dispatch(getProjects()),
  onAddNewProject: project => dispatch(addNewProject(project)),
  onAddNewUser: user => dispatch(addNewUser(user)),
  onGetClients: () => dispatch(getClients()),
  onGetProjectDetail: id => dispatch(getProjectDetail(id)),
  onUpdateProject: id => dispatch(updateProject(id)),
  onAddNewClient: client => dispatch(addNewClient(client)),
  onGetVenues: () => dispatch(getVenues()),
  onAddNewVenue: venue => dispatch(addNewVenue(venue)),
  onGetTrainings: () => dispatch(getTrainings()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectsCreate));
