import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Col,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  addNewEvent,
  deleteEvent,
  getEventDetail,
  getEvents,
  updateEvent,
} from "store/actions";
import { connect } from "react-redux";
import moment from "moment";
import DeleteModal from "components/Common/DeleteModal";
import { statuses } from "helpers/constants";
import _ from "lodash";

class EventForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
    };
  }

  componentDidMount() {
    const { id, eventDetail, onGetEventDetail, events, onGetEvents } =
      this.props;
    if (events.length === 0) {
      onGetEvents();
    }

    onGetEventDetail(id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(
      "EventForm Update",
      prevProps.eventDetail?.updated_at,
      this.props.eventDetail?.updated_at
    );
    if (
      !_.isEmpty(prevProps.eventDetail) &&
      (prevProps.eventDetail?.updated_at !==
        this.props.eventDetail?.updated_at ||
        _.isEmpty(this.props.eventDetail))
    ) {
      this.props.onDone();
    }
  }

  onClickDelete = e => {
    this.setState({ deleteModal: true });
  };

  handleDelete = () => {
    const { eventDetail, onDeleteEvent } = this.props;
    if (eventDetail?.id) {
      onDeleteEvent(eventDetail?.id);
    }
  };

  render() {
    const types = [
      ...new Set(this.props.events.map(e => `${e.type}`.toLowerCase())),
    ].map(e => ({
      value: e.toLowerCase(),
      name: e.toUpperCase(),
    }));

    const { eventDetail, roadshowDetail, onAddNewEvent, onUpdateEvent } =
      this.props;

    return (
      <>
        <DeleteModal
          show={this.state.deleteModal}
          onDeleteClick={this.handleDelete}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: eventDetail?.id,
            roadshow_id: roadshowDetail?.id,
            status: eventDetail?.status ?? "",
            type: eventDetail?.type ?? "",
            name: eventDetail?.name ?? "",
            description: eventDetail?.description ?? "",
            lks: eventDetail?.lks ?? "",
            budget: eventDetail?.budget ?? 0,
            cost: eventDetail?.cost ?? 0,
            start_date: eventDetail?.start_date ?? "",
            end_date: eventDetail?.end_date ?? "",
            notes: eventDetail?.notes ?? "",
          }}
          validationSchema={Yup.object().shape({
            status: Yup.number().integer().min(0).max(3).required(),
            type: Yup.string().required(),
            name: Yup.string().required(),
            description: Yup.string(),
            lks: Yup.string(),
            budget: Yup.number(),
            cost: Yup.number(),
            start_date: Yup.date().required(),
            end_date: Yup.date().required(),
            notes: Yup.string(),
          })}
          onSubmit={values => {
            if (values.id) {
              onUpdateEvent(values);
            } else {
              onAddNewEvent(values);
            }
          }}
        >
          {({ values, errors, setFieldValue, touched, handleSubmit }) => (
            <Form>
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <Label className="form-label">Status</Label>
                    <Select
                      options={statuses}
                      value={statuses.find(s => s.value === values.status)}
                      getOptionValue={option => option.value}
                      getOptionLabel={option => option.name}
                      onChange={newValue => {
                        setFieldValue("status", newValue.value);
                      }}
                      className={
                        "form-control" +
                        (errors.status && touched.status ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="status"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Type</Label>
                    <CreatableSelect
                      options={types}
                      value={types.find(s => s.value === values.type)}
                      getOptionValue={option => option.value}
                      getOptionLabel={option =>
                        option.__isNew__ ? option.label : option.name
                      }
                      onChange={newValue => {
                        setFieldValue("type", newValue.value);
                      }}
                      onCreateOption={inputValue => {
                        types.push({
                          value: inputValue.toLowerCase(),
                          name: inputValue.toUpperCase(),
                        });
                        setFieldValue("type", inputValue);
                      }}
                      className={
                        "form-control" +
                        (errors.type && touched.type ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="type"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Name</Label>
                    <Field
                      name="name"
                      type="text"
                      className={
                        "form-control" +
                        (errors.name && touched.name ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Description</Label>
                    <Field
                      name="description"
                      as="textarea"
                      className={
                        "form-control" +
                        (errors.description && touched.description
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">LKS_no</Label>
                    <Field
                      name="lks"
                      type="text"
                      className={
                        "form-control" +
                        (errors.lks && touched.lks ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="lks"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <Row className="mb-3">
                    <Col md="6">
                      <Label htmlFor="budget" className="col-form-label">
                        Budget
                      </Label>
                      <InputGroup>
                        <InputGroupText>EUR</InputGroupText>
                        <Field
                          name="budget"
                          type="number"
                          min="0"
                          className={
                            "form-control mb-0" +
                            (errors.budget && touched.budget
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="budget"
                          component="div"
                          className="invalid-feedback"
                        />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <Label htmlFor="cost" className="col-form-label">
                        Cost
                      </Label>
                      <InputGroup>
                        <InputGroupText>EUR</InputGroupText>
                        <Field
                          name="cost"
                          type="number"
                          min="0"
                          className={
                            "form-control mb-0" +
                            (errors.cost && touched.cost ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="cost"
                          component="div"
                          className="invalid-feedback"
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="6">
                      <Label className="form-label">Start Date</Label>
                      <Flatpickr
                        placeholder="D/M/Y"
                        options={{
                          inline: false,
                          altInput: true,
                          altFormat: "d/m/Y",
                          dateFormat: "Y-m-d",
                        }}
                        name="start_date"
                        value={values.start_date}
                        onChange={([value]) =>
                          setFieldValue(
                            "start_date",
                            moment(value).format("YYYY-MM-DD")
                          )
                        }
                        className={
                          "form-control" +
                          (errors.start_date && touched.start_date
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="start_date"
                        component="div"
                        className="invalid-feedback"
                      />
                    </Col>
                    <Col md="6">
                      <Label className="form-label">End Date</Label>
                      <Flatpickr
                        placeholder="D/M/Y"
                        options={{
                          inline: false,
                          altInput: true,
                          altFormat: "d/m/Y",
                          dateFormat: "Y-m-d",
                        }}
                        name="end_date"
                        value={values.end_date}
                        onChange={([value]) =>
                          setFieldValue(
                            "end_date",
                            moment(value).format("YYYY-MM-DD")
                          )
                        }
                        className={
                          "form-control" +
                          (errors.end_date && touched.end_date
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="end_date"
                        component="div"
                        className="invalid-feedback"
                      />
                    </Col>
                  </Row>
                  <div className="mb-3">
                    <Label className="form-label">Notes</Label>
                    <Field
                      name="notes"
                      as="textarea"
                      className={
                        "form-control" +
                        (errors.notes && touched.notes ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="notes"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <ButtonGroup>
                      <Button type="submit" color="success">
                        Save
                      </Button>
                      {eventDetail?.id && (
                        <>
                          <Button
                            onClick={e => {
                              setFieldValue("id", "");
                              handleSubmit(e);
                            }}
                            color="info"
                          >
                            Duplicate
                          </Button>
                          <Button onClick={this.onClickDelete} color="danger">
                            Delete
                          </Button>
                        </>
                      )}
                    </ButtonGroup>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

EventForm.propTypes = {
  id: PropTypes.any,
  events: PropTypes.any,
  roadshowDetail: PropTypes.object,
  eventDetail: PropTypes.object,
  onDone: PropTypes.func,
  onGetEventDetail: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetEvents: PropTypes.func,
};

const mapStateToProps = ({ Events }) => ({
  eventDetail: Events.eventDetail,
  events: Events.events,
});

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetEventDetail: eventId => dispatch(getEventDetail(eventId)),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventForm);
