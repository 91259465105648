//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";
export const POST_LOGIN = "/auth/login";
export const POST_PASSWORD_FORGET = "/auth/password/reset-request";
export const POST_PASSWORD_RESET = "/auth/password/reset";
export const GET_CURRENT_ADMIN = "/auth/admin/me";
export const GET_CURRENT_USER = "/auth/me";

//Surveys
export const GET_SURVEYS = "/v1/surveys";
export const GET_SURVEY_DETAIL = "/v1/surveys";
export const ADD_NEW_SURVEY = "/v1/surveys";
export const UPDATE_SURVEY = "/v1/surveys";
export const DELETE_SURVEY = "/v1/surveys";

//Trainings
export const GET_TRAININGS = "/v1/trainings";
export const GET_TRAINING_DETAIL = "/v1/trainings";
export const ADD_NEW_TRAINING = "/v1/trainings";
export const UPDATE_TRAINING = "/v1/trainings";
export const DELETE_TRAINING = "/v1/trainings";
export const SUBMIT_TRAINING = "/v1/training_submissions";

//Brands
export const GET_BRANDS = "/v1/brands";
export const GET_BRAND_DETAIL = "/v1/brands";
export const ADD_NEW_BRAND = "/v1/brands";
export const UPDATE_BRAND = "/v1/brands";
export const DELETE_BRAND = "/v1/brands";

//Venues
export const GET_VENUES = "/v1/venues";
export const GET_VENUE_DETAIL = "/v1/venues";
export const ADD_NEW_VENUE = "/v1/venues";
export const UPDATE_VENUE = "/v1/venues";
export const DELETE_VENUE = "/v1/venues";

//Clients
export const GET_CLIENTS = "/v1/clients";
export const GET_CLIENT_DETAIL = "/v1/clients";
export const ADD_NEW_CLIENT = "/v1/clients";
export const UPDATE_CLIENT = "/v1/clients";
export const DELETE_CLIENT = "/v1/clients";

//Events
export const GET_EVENTS = "/v1/events";
export const GET_EVENT_DETAIL = "/v1/events";
export const ADD_NEW_EVENT = "/v1/events";
export const UPDATE_EVENT = "/v1/events";
export const DELETE_EVENT = "/v1/events";

//Locations
export const GET_LOCATIONS = "/v1/locations";
export const GET_LOCATION_DETAIL = "/v1/locations";
export const ADD_NEW_LOCATION = "/v1/locations";
export const UPDATE_LOCATION = "/v1/locations";
export const DELETE_LOCATION = "/v1/locations";

//Roadshows
export const GET_ROADSHOWS = "/v1/roadshows";
export const GET_ROADSHOW_DETAIL = "/v1/roadshows";
export const ADD_NEW_ROADSHOW = "/v1/roadshows";
export const UPDATE_ROADSHOW = "/v1/roadshows";
export const DELETE_ROADSHOW = "/v1/roadshows";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

//CALENDER
// export const GET_EVENTS = "/events";
// export const ADD_NEW_EVENT = "/add/event";
// export const UPDATE_EVENT = "/update/event";
// export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

//CHATS
export const GET_CHATS = "/v1/conversations?private=1";
export const GET_GROUPS = "/v1/conversations?private=0";
export const GET_CONTACTS = "/v1/contacts";
export const GET_MESSAGES = "/v1/conversations";
export const ADD_MESSAGE = "/v1/conversations/send";

//ORDERS
export const GET_ORDERS = "/v1/orders";
export const ADD_NEW_ORDER = "/add/order";
export const UPDATE_ORDER = "/update/order";
export const DELETE_ORDER = "/delete/order";

//CART DATA
export const GET_CART_DATA = "/cart";

//CUSTOMERS
export const GET_CUSTOMERS = "/customers";
export const ADD_NEW_CUSTOMER = "/add/customer";
export const UPDATE_CUSTOMER = "/update/customer";
export const DELETE_CUSTOMER = "/delete/customer";

//SHOPS
export const GET_SHOPS = "/shops";

//CRYPTO
export const GET_WALLET = "/wallet";
export const GET_CRYPTO_ORDERS = "/crypto/orders";

//INVOICES
export const GET_INVOICES = "/invoices";
export const GET_INVOICE_DETAIL = "/invoice";

//PROJECTS
export const GET_PROJECTS = "/v1/projects";
export const GET_PROJECT_DETAIL = "/v1/projects";
export const ADD_NEW_PROJECT = "/v1/projects";
export const UPDATE_PROJECT = "/v1/projects";
export const DELETE_PROJECT = "/v1/projects";
export const IMPORT_PROJECT_DETAILS = "/v1/projects/import-details";

//TASKS
export const GET_TASKS = "/v1/tasks";
export const GET_TASK_DETAIL = "/v1/tasks";
export const ADD_NEW_TASK = "/v1/tasks";
export const UPDATE_TASK = "/v1/tasks";
export const DELETE_TASK = "/v1/tasks";

//CONTACTS
export const GET_USERS = "/v1/users";
export const GET_USER_PROFILE = "/v1/users";
export const ADD_NEW_USER = "/v1/users";
export const ADD_BULK_USERS = "/v1/users/bulk-import";
export const UPDATE_USER = "/v1/users";
export const DELETE_USER = "/v1/users";

//Mails
export const GET_INBOX_MAILS = "/inboxmails";
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail";
export const DELETE_INBOX_MAIL = "/delete/inboxmail";

//starred mail
export const GET_STARRED_MAILS = "/starredmails";

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails";

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails";

//Send mail
export const GET_SENT_MAILS = "/sentmails";

//Trash mail
export const GET_TRASH_MAILS = "/trashmails";

//dashboard charts data
export const GET_WEEKLY_DATA = "/v1/weekly-data";
export const GET_YEARLY_DATA = "/v1/yearly-data";
export const GET_MONTHLY_DATA = "/v1/monthly-data";

export const TOP_SELLING_DATA = "/top-selling-data";

export const GET_EARNING_DATA = "/earning-charts-data";
