import {
  GET_BRANDS,
  GET_BRANDS_FAIL,
  GET_BRANDS_SUCCESS,
  GET_BRAND_DETAIL,
  GET_BRAND_DETAIL_FAIL,
  GET_BRAND_DETAIL_SUCCESS,
  ADD_NEW_BRAND,
  ADD_BRAND_SUCCESS,
  ADD_BRAND_FAIL,
  UPDATE_BRAND,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAIL,
  DELETE_BRAND,
  DELETE_BRAND_SUCCESS,
  DELETE_BRAND_FAIL,
} from "./actionTypes";

export const getBrands = () => ({
  type: GET_BRANDS,
});

export const getBrandsSuccess = brands => ({
  type: GET_BRANDS_SUCCESS,
  payload: brands,
});

export const getBrandsFail = error => ({
  type: GET_BRANDS_FAIL,
  payload: error,
});

export const getBrandDetail = brandId => ({
  type: GET_BRAND_DETAIL,
  brandId,
});

export const getBrandDetailSuccess = brandDetails => ({
  type: GET_BRAND_DETAIL_SUCCESS,
  payload: brandDetails,
});

export const getBrandDetailFail = error => ({
  type: GET_BRAND_DETAIL_FAIL,
  payload: error,
});

export const addNewBrand = brand => ({
  type: ADD_NEW_BRAND,
  payload: brand,
});

export const addBrandSuccess = brand => ({
  type: ADD_BRAND_SUCCESS,
  payload: brand,
});

export const addBrandFail = error => ({
  type: ADD_BRAND_FAIL,
  payload: error,
});

export const updateBrand = brand => ({
  type: UPDATE_BRAND,
  payload: brand,
});

export const updateBrandSuccess = brand => ({
  type: UPDATE_BRAND_SUCCESS,
  payload: brand,
});

export const updateBrandFail = error => ({
  type: UPDATE_BRAND_FAIL,
  payload: error,
});

export const deleteBrand = brand => ({
  type: DELETE_BRAND,
  payload: brand,
});

export const deleteBrandSuccess = brand => ({
  type: DELETE_BRAND_SUCCESS,
  payload: brand,
});

export const deleteBrandFail = error => ({
  type: DELETE_BRAND_FAIL,
  payload: error,
});
