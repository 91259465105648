import { call, put, takeEvery } from "redux-saga/effects";

// Roadshow Redux States
import {
  GET_ROADSHOWS,
  GET_ROADSHOW_DETAIL,
  ADD_NEW_ROADSHOW,
  DELETE_ROADSHOW,
  UPDATE_ROADSHOW,
} from "./actionTypes";
import {
  getRoadshowsSuccess,
  getRoadshowsFail,
  getRoadshowDetailSuccess,
  getRoadshowDetailFail,
  addRoadshowFail,
  addRoadshowSuccess,
  updateRoadshowSuccess,
  updateRoadshowFail,
  deleteRoadshowSuccess,
  deleteRoadshowFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getRoadshows,
  getRoadshowsDetails,
  addNewRoadshow,
  updateRoadshow,
  deleteRoadshow,
} from "helpers/Api/roadshows";

function* fetchRoadshows() {
  try {
    const response = yield call(getRoadshows);
    if (response.success) {
      yield put(getRoadshowsSuccess(response.data));
    } else {
      yield put(getRoadshowsFail(response.message));
    }
  } catch (error) {
    yield put(getRoadshowsFail(error));
  }
}

function* fetchRoadshowDetail({ roadshowId }) {
  try {
    if (roadshowId === undefined) {
      yield put(getRoadshowDetailSuccess(null));
    } else {
      const response = yield call(getRoadshowsDetails, roadshowId);
      if (response.success) {
        yield put(getRoadshowDetailSuccess(response.data));
      } else {
        yield put(getRoadshowDetailFail(response.message));
      }
    }
  } catch (error) {
    yield put(getRoadshowDetailFail(error));
  }
}

function* onUpdateRoadshow({ payload: roadshow }) {
  try {
    const response = yield call(updateRoadshow, roadshow);
    if (response.success) {
      yield put(updateRoadshowSuccess(response.data));
    } else {
      yield put(updateRoadshowFail(response.message));
    }
  } catch (error) {
    yield put(updateRoadshowFail(error));
  }
}

function* onDeleteRoadshow({ payload: roadshow }) {
  try {
    const response = yield call(deleteRoadshow, roadshow);
    if (response.success) {
      yield put(deleteRoadshowSuccess(response.data));
    } else {
      yield put(deleteRoadshowFail(response.message));
    }
  } catch (error) {
    yield put(deleteRoadshowFail(error));
  }
}

function* onAddNewRoadshow({ payload: roadshow }) {
  try {
    const response = yield call(addNewRoadshow, roadshow);
    if (response.success) {
      yield put(addRoadshowSuccess(response.data));
    } else {
      yield put(addRoadshowFail(response.message));
    }
  } catch (error) {
    yield put(addRoadshowFail(error));
  }
}

function* roadshowsSaga() {
  yield takeEvery(GET_ROADSHOWS, fetchRoadshows);
  yield takeEvery(GET_ROADSHOW_DETAIL, fetchRoadshowDetail);
  yield takeEvery(ADD_NEW_ROADSHOW, onAddNewRoadshow);
  yield takeEvery(UPDATE_ROADSHOW, onUpdateRoadshow);
  yield takeEvery(DELETE_ROADSHOW, onDeleteRoadshow);
}

export default roadshowsSaga;
