import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Col,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import moment from "moment";
import { addNewRoadshow, deleteRoadshow, updateRoadshow } from "store/actions";
import { connect } from "react-redux";
import DeleteModal from "components/Common/DeleteModal";
import { statuses } from "helpers/constants";

class RoadshowForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
    };
  }

  onClickDelete = e => {
    this.setState({ deleteModal: true });
  };

  handleDelete = () => {
    const { roadshowDetail, onDeleteRoadshow, onDone } = this.props;
    if (roadshowDetail?.id) {
      onDeleteRoadshow(roadshowDetail?.id);
      onDone();
    }
  };

  render() {
    const {
      onDone,
      onAddNewRoadshow,
      onUpdateRoadshow,
      projectDetail,
      roadshowDetail,
    } = this.props;

    return (
      <>
        <DeleteModal
          show={this.state.deleteModal}
          onDeleteClick={this.handleDelete}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: roadshowDetail?.id,
            project_id: projectDetail?.id,
            status: roadshowDetail ? roadshowDetail.status ?? "" : "",
            name: roadshowDetail ? roadshowDetail.name ?? "" : "",
            description: roadshowDetail ? roadshowDetail.description ?? "" : "",
            lks: roadshowDetail ? roadshowDetail.lks ?? "" : "",
            budget: roadshowDetail ? roadshowDetail.budget ?? 0 : 0,
            cost: roadshowDetail ? roadshowDetail.cost ?? 0 : 0,
            start_date: roadshowDetail ? roadshowDetail.start_date ?? "" : "",
            end_date: roadshowDetail ? roadshowDetail.end_date ?? "" : "",
            notes: roadshowDetail ? roadshowDetail.notes ?? "" : "",
          }}
          validationSchema={Yup.object().shape({
            status: Yup.number().integer().min(0).max(3).required(),
            name: Yup.string().required(),
            description: Yup.string(),
            lks: Yup.string(),
            budget: Yup.number(),
            cost: Yup.number(),
            start_date: Yup.date().required(),
            end_date: Yup.date().required(),
            notes: Yup.string(),
          })}
          onSubmit={values => {
            if (values.id) {
              onUpdateRoadshow(values);
            } else {
              onAddNewRoadshow(values);
            }
            onDone();
          }}
        >
          {({ errors, values, touched, setFieldValue }) => (
            <Form>
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <Label className="form-label">Status</Label>
                    <Select
                      options={statuses}
                      value={statuses.find(s => s.value === values.status)}
                      getOptionValue={option => option.value}
                      getOptionLabel={option => option.name}
                      onChange={newValue => {
                        setFieldValue("status", newValue.value);
                      }}
                      className={
                        "form-control" +
                        (errors.status && touched.status ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="status"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Name</Label>
                    <Field
                      name="name"
                      type="text"
                      className={
                        "form-control" +
                        (errors.name && touched.name ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Description</Label>
                    <Field
                      name="description"
                      as="textarea"
                      className={
                        "form-control" +
                        (errors.description && touched.description
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">LKS_no</Label>
                    <Field
                      name="lks"
                      type="text"
                      className={
                        "form-control" +
                        (errors.lks && touched.lks ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="lks"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <Row className="mb-3">
                    <Col md="6">
                      <Label htmlFor="budget" className="col-form-label">
                        Budget
                      </Label>
                      <InputGroup>
                        <InputGroupText>EUR</InputGroupText>
                        <Field
                          name="budget"
                          type="number"
                          min="0"
                          className={
                            "form-control mb-0" +
                            (errors.budget && touched.budget
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="budget"
                          component="div"
                          className="invalid-feedback"
                        />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <Label htmlFor="cost" className="col-form-label">
                        Cost
                      </Label>
                      <InputGroup>
                        <InputGroupText>EUR</InputGroupText>
                        <Field
                          name="cost"
                          type="number"
                          min="0"
                          className={
                            "form-control mb-0" +
                            (errors.cost && touched.cost ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="cost"
                          component="div"
                          className="invalid-feedback"
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <div className="mb-3">
                    <Label className="form-label">Start Date</Label>
                    <Flatpickr
                      placeholder="D/M/Y"
                      options={{
                        inline: false,
                        altInput: true,
                        altFormat: "d/m/Y",
                        dateFormat: "Y-m-d",
                      }}
                      value={values.start_date}
                      name="start_date"
                      onChange={([value]) =>
                        setFieldValue(
                          "start_date",
                          moment(value).format("YYYY-MM-DD")
                        )
                      }
                      className={
                        "form-control" +
                        (errors.start_date && touched.start_date
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="start_date"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">End Date</Label>
                    <Flatpickr
                      placeholder="D/M/Y"
                      options={{
                        inline: false,
                        altInput: true,
                        altFormat: "d/m/Y",
                        dateFormat: "Y-m-d",
                      }}
                      name="end_date"
                      value={values.end_date}
                      onChange={([value]) =>
                        setFieldValue(
                          "end_date",
                          moment(value).format("YYYY-MM-DD")
                        )
                      }
                      className={
                        "form-control" +
                        (errors.end_date && touched.end_date
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="end_date"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Notes</Label>
                    <Field
                      name="notes"
                      as="textarea"
                      className={
                        "form-control" +
                        (errors.notes && touched.notes ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="notes"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <ButtonGroup>
                      <Button type="submit" color="success">
                        Save
                      </Button>
                      {roadshowDetail?.id && (
                        <Button onClick={this.onClickDelete} color="danger">
                          Delete
                        </Button>
                      )}
                    </ButtonGroup>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

RoadshowForm.propTypes = {
  roadshowDetail: PropTypes.object,
  projectDetail: PropTypes.object,
  onDone: PropTypes.func,
  onAddNewRoadshow: PropTypes.func,
  onUpdateRoadshow: PropTypes.func,
  onDeleteRoadshow: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  onAddNewRoadshow: roadshow => dispatch(addNewRoadshow(roadshow)),
  onUpdateRoadshow: roadshow => dispatch(updateRoadshow(roadshow)),
  onDeleteRoadshow: roadshow => dispatch(deleteRoadshow(roadshow)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoadshowForm);
