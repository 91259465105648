import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap";
import {
  addNewBrand,
  addNewClient,
  getBrandDetail,
  getBrands,
  getClients,
  updateBrand,
} from "store/actions";

class BrandForm extends Component {
  constructor() {
    super();
    this.state = {
      client: null,
    };
  }

  componentDidMount() {
    const { id, onGetBrands, onGetBrandDetail, onGetClients, clients } =
      this.props;
    onGetBrands();
    onGetBrandDetail(id);
    if (clients.length === 0) {
      onGetClients();
    }
  }

  render() {
    const {
      brandDetail,
      brands,
      clients,
      onUpdateBrand,
      onAddNewBrand,
      onDone,
    } = this.props;
    console.log(brandDetail);
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: brandDetail?.name ?? "",
          parent: brandDetail?.parent ?? "",
          parent_id: brandDetail?.parent_id,
          client: brandDetail?.client || this.state.client,
          client_id: brandDetail?.client_id,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Please Enter Brand Name"),
        })}
        onSubmit={values => {
          if (brandDetail?.id) {
            onUpdateBrand({ id: brandDetail.id, ...values });
          } else {
            onAddNewBrand(values);
          }
          onDone();
        }}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <React.Fragment>
            <Form>
              <Row className="mb-3">
                <Col lg="4">
                  <div className="mb-2">
                    <Label htmlFor="name" className="col-form-label">
                      Name
                    </Label>
                    <Field
                      name="name"
                      type="text"
                      className={
                        "form-control" +
                        (errors.name && touched.name ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
                <Col lg="4">
                  <div className="mb-2">
                    <Label htmlFor="name" className="col-form-label">
                      Client
                    </Label>
                    <Select
                      value={values.client}
                      isClearable
                      isSearchable
                      options={clients}
                      getOptionLabel={option => option.name || option.label}
                      getOptionValue={option => option.id}
                      onChange={option => {
                        setFieldValue("client", option);
                        setFieldValue("client_id", option ? option.id : null);
                      }}
                      onCreateOption={inputValue => {
                        const newClient = { name: inputValue };
                        onAddNewClient(newClient);
                        console.log(clients.at(-1));
                      }}
                      placeholder="Select Client"
                    />
                    <ErrorMessage
                      name="status"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
                <Col lg="4">
                  <div className="mb-2">
                    <Label htmlFor="name" className="col-form-label">
                      Parent Brand
                    </Label>
                    <Select
                      value={values.parent}
                      isClearable
                      isSearchable
                      options={brands}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      onChange={option => {
                        setFieldValue("parent", option);
                        setFieldValue("parent_id", option ? option.id : null);
                      }}
                      placeholder="Select Parent"
                    />
                    <ErrorMessage
                      name="status"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col lg="auto">
                  <Button type="submit" color="primary">
                    {brandDetail?.id ? "Update" : "Create"} Brand
                  </Button>
                </Col>
              </Row>
            </Form>
          </React.Fragment>
        )}
      </Formik>
    );
  }
}

BrandForm.propTypes = {
  id: PropTypes.any,
  brands: PropTypes.array,
  brandDetail: PropTypes.object,
  onGetBrands: PropTypes.func,
  onAddNewBrand: PropTypes.func,
  onGetBrandDetail: PropTypes.func,
  onUpdateBrand: PropTypes.func,
  clients: PropTypes.array,
  onGetClients: PropTypes.func,
  onAddNewClient: PropTypes.func,
  onDone: PropTypes.func,
};

const mapStateToProps = ({ Clients, Brands }) => ({
  brandDetail: Brands.brandDetail,
  brands: Brands.brands,
  clients: Clients.clients,
});

const mapDispatchToProps = dispatch => ({
  onGetBrands: () => dispatch(getBrands()),
  onAddNewBrand: brand => dispatch(addNewBrand(brand)),
  onGetBrandDetail: id => dispatch(getBrandDetail(id)),
  onUpdateBrand: brand => dispatch(updateBrand(brand)),
  onGetClients: () => dispatch(getClients()),
  onAddNewClient: client => dispatch(addNewClient(client)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandForm);
