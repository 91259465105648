import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap";
import {
  addNewBrand,
  addNewClient,
  getBrandDetail,
  getBrands,
  getClientDetail,
  getClients,
  updateBrand,
  updateClient,
} from "store/actions";

class ClientForm extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { id, brands, onGetBrands, onGetClientDetail } = this.props;
    onGetClientDetail(id);
    if (brands.length === 0) {
      onGetBrands();
    }
  }

  render() {
    const { clientDetail, brands, onUpdateClient, onAddNewClient, onDone } =
      this.props;

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: clientDetail?.name ?? "",
          brands: clientDetail?.brands ?? [],
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Please Enter Client Name"),
        })}
        onSubmit={values => {
          if (clientDetail?.id) {
            onUpdateClient({ id: clientDetail.id, ...values });
          } else {
            onAddNewClient(values);
          }
          onDone();
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <React.Fragment>
            <Form>
              <Row className="mb-3">
                <Col lg="6">
                  <div className="mb-2">
                    <Label htmlFor="name" className="col-form-label">
                      Name
                    </Label>
                    <Field
                      name="name"
                      type="text"
                      className={
                        "form-control" +
                        (errors.name && touched.name ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
                {clientDetail?.id && (
                  <Col lg="6">
                    <div className="mb-2">
                      <Label htmlFor="name" className="col-form-label">
                        Brands
                      </Label>
                      <Select
                        value={values.brands}
                        isMulti
                        isDisabled
                        options={brands}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                      />
                      <ErrorMessage
                        name="status"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                )}
              </Row>
              <Row className="justify-content-end">
                <Col lg="auto">
                  <Button type="submit" color="primary">
                    {clientDetail?.id ? "Update" : "Create"} Client
                  </Button>
                </Col>
              </Row>
            </Form>
          </React.Fragment>
        )}
      </Formik>
    );
  }
}

ClientForm.propTypes = {
  id: PropTypes.any,
  brands: PropTypes.array,
  clientDetail: PropTypes.object,
  onGetBrands: PropTypes.func,
  onAddNewClient: PropTypes.func,
  onGetClientDetail: PropTypes.func,
  onUpdateClient: PropTypes.func,
  onGetClients: PropTypes.func,
  onDone: PropTypes.func,
};

const mapStateToProps = ({ Clients, Brands }) => ({
  brands: Brands.brands,
  clientDetail: Clients.clientDetail,
});

const mapDispatchToProps = dispatch => ({
  onGetBrands: () => dispatch(getBrands()),
  onGetClients: () => dispatch(getClients()),
  onAddNewClient: client => dispatch(addNewClient(client)),
  onGetClientDetail: id => dispatch(getClientDetail(id)),
  onUpdateClient: client => dispatch(updateClient(client)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientForm);
