/* VENUES */
export const GET_VENUES = "GET_VENUES";
export const GET_VENUES_SUCCESS = "GET_VENUES_SUCCESS";
export const GET_VENUES_FAIL = "GET_VENUES_FAIL";

/* VENUES DETAIL*/
export const GET_VENUE_DETAIL = "GET_VENUE_DETAIL";
export const GET_VENUE_DETAIL_SUCCESS = "GET_VENUE_DETAIL_SUCCESS";
export const GET_VENUE_DETAIL_FAIL = "GET_VENUE_DETAIL_FAIL";

export const ADD_NEW_VENUE = "ADD_NEW_VENUE";
export const ADD_VENUE_SUCCESS = "ADD_VENUE_SUCCESS";
export const ADD_VENUE_FAIL = "ADD_VENUE_FAIL";

export const UPDATE_VENUE = "UPDATE_VENUE";
export const UPDATE_VENUE_SUCCESS = "UPDATE_VENUE_SUCCESS";
export const UPDATE_VENUE_FAIL = "UPDATE_VENUE_FAIL";

export const DELETE_VENUE = "DELETE_VENUE";
export const DELETE_VENUE_SUCCESS = "DELETE_VENUE_SUCCESS";
export const DELETE_VENUE_FAIL = "DELETE_VENUE_FAIL";
