import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { withRouter } from "react-router-dom";
import { isEmpty, map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import Task Cards
import ControlledBoard from "pages/Tasks/ControlledBoard";

import "assets/scss/tasks.scss";
import { getLocationDetail, getProjectDetail } from "store/actions";

class TasksBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {
      match: {
        params: { id, locId },
      },
      projectDetail,
      onGetLocationDetails,
      onGetProjectDetails,
    } = this.props;
    if (locId) {
      onGetLocationDetails(locId);
    }
    if (projectDetail?.id !== id) {
      onGetProjectDetails(id);
    }
  }

  onAddNewTask = () => {
    const {
      match: {
        params: { locId },
      },
      onGetLocationDetails,
    } = this.props;
    onGetLocationDetails(locId);
  };

  render() {
    const { locationDetail } = this.props;

    const data = map(locationDetail.boards, task => ({
      ...task,
      cards: task.tasks,
    }));

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Tasks | AD&amp;VISION - Event Management</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Project"
              breadcrumbItem={`${locationDetail.name} Tasks`}
            />
            {!isEmpty(data) && (
              <ControlledBoard
                locationDetail={locationDetail}
                board={{ columns: data }}
                handleAddNew={this.onAddNewTask}
              />
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

TasksBoard.propTypes = {
  match: PropTypes.object,
  locationDetail: PropTypes.object,
  projectDetail: PropTypes.object,
  onGetLocationDetails: PropTypes.func,
  onGetProjectDetails: PropTypes.func,
};

const mapStateToProps = ({ Locations, projects }) => ({
  locationDetail: Locations.locationDetail,
  projectDetail: projects.projectDetail,
});

const mapDispatchToProps = dispatch => ({
  onGetLocationDetails: id => dispatch(getLocationDetail(id)),
  onGetProjectDetails: id => dispatch(getProjectDetail(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TasksBoard));
