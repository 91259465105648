/* eslint-disable react/prop-types */
import React from "react";
import Question from "../models/Question";
import ListController from "../controllers/ListController";
import {
  Button,
  ButtonGroup,
  Col,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";

export default function QuestionForm({ question, setQuestion }) {
  function handleChangeText(e) {
    setQuestion(question.merge({ text: e.target.value }));
  }

  function handleChangeScore(e) {
    setQuestion(question.merge({ score: e.target.value }));
  }

  function handleChangeType(e) {
    setQuestion(question.merge({ type: e.target.value }));
  }

  function setOptions(options) {
    setQuestion(question.merge({ options }));
  }

  const listController = new ListController(question.options, setOptions);

  return (
    <div>
      <Label>Question Text:</Label>
      <Input type="text" value={question.text} onChange={handleChangeText} />
      <Input
        type="number"
        value={question.score}
        onChange={handleChangeScore}
      />

      <Label htmlFor="question-type">Question Type:</Label>
      <Input
        type="select"
        id="question-type"
        value={question.type}
        onChange={handleChangeType}
      >
        {Object.values(Question.TYPES).map(type => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </Input>

      {question.hasOptions && (
        <fieldset className="mt-2">
          <legend>Options</legend>

          {question.options.map((option, i) => (
            <Row key={i} className="align-items-center">
              <Col md={10}>
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Enter option"
                    name={option}
                    value={option.text}
                    onChange={e =>
                      listController.set(i, { ...option, text: e.target.value })
                    }
                  />
                  <Input
                    type="number"
                    placeholder="Enter score"
                    name={option}
                    value={option.score}
                    onChange={e =>
                      listController.set(i, {
                        ...option,
                        score: e.target.value,
                      })
                    }
                  />
                </InputGroup>
              </Col>
              <Col md={2}>
                <ButtonGroup>
                  <Button
                    outline
                    color="primary"
                    size="sm"
                    onClick={() => listController.moveUp(i)}
                  >
                    <i className="fas fa-angle-up" />
                  </Button>
                  <Button
                    outline
                    color="primary"
                    size="sm"
                    onClick={() => listController.moveDown(i)}
                  >
                    <i className="fas fa-angle-down" />
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    onClick={() => listController.remove(i)}
                  >
                    <i className="fas fa-trash-alt" />
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          ))}
          <p>
            <Button
              size="sm"
              color="success"
              onClick={() => listController.add({ text: "", score: 0 })}
            >
              <i className="fas fa-plus icon" />
              Add Option
            </Button>
          </p>
        </fieldset>
      )}
    </div>
  );
}
