import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { map } from "lodash";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import mini card widgets
import MiniCards from "./mini-card";

//Import Images
import profile1 from "assets/images/profile-img.png";

// import charts
import ApexRevenue from "../ApexRevenue";
import { getUserProfile } from "store/actions";
import images from "assets/images";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import projectColumns from "./projectColumns";

class ContactsProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      miniCards: [
        {
          title: "Completed Projects",
          iconClass: "bx-check-circle",
          text: "125",
        },
        { title: "Pending Projects", iconClass: "bx-hourglass", text: "12" },
        { title: "Total Revenue", iconClass: "bx-package", text: "€36,524" },
      ],
    };
  }

  componentDidMount() {
    const {
      match: { params },
      onGetUserProfile,
    } = this.props;
    onGetUserProfile(params?.id);
  }

  render() {
    const { userProfile } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Profile | AD&amp;VISION - Event Tool</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Contacts" breadcrumbItem="Profile" />
            {(Object.keys(userProfile).length === 0 && (
              <div className="page-content">
                <div className="spinner-chase">
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                  <div className="chase-dot"></div>
                </div>
              </div>
            )) || (
              <Row>
                <Col xl="4">
                  <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <Row>
                        <Col xs="7">
                          <div className="text-primary p-3">
                            {/* <h5 className="text-primary">Welcome Back !</h5>
                          <p>It will seem like simplified</p> */}
                          </div>
                        </Col>
                        <Col xs="5" className="align-self-end">
                          <img src={profile1} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <Row>
                        <Col sm="4">
                          <div className="avatar-md profile-user-wid mb-4">
                            {(userProfile.img && (
                              <img
                                src={images[userProfile.img]}
                                alt=""
                                className="img-thumbnail rounded-circle"
                              />
                            )) || (
                              <span
                                className={
                                  "avatar-title rounded-circle bg-" +
                                  userProfile.color +
                                  " text-white font-size-16 text-uppercase"
                                }
                              >
                                {userProfile.name.charAt(0)}
                                {userProfile.name.charAt(
                                  userProfile.name.indexOf(" ") + 1
                                )}
                              </span>
                            )}
                          </div>
                          <h5 className="font-size-15 text-truncate">
                            {userProfile.name}
                          </h5>
                          <p className="text-muted mb-0 text-truncate">
                            {userProfile.designation}
                          </p>
                        </Col>

                        <Col sm={8}>
                          <div className="pt-4">
                            <Row>
                              <Col xs="6">
                                <h5 className="font-size-15">
                                  {userProfile.projects_count}
                                </h5>
                                <p className="text-muted mb-0">Projects</p>
                              </Col>
                              <Col xs="6">
                                <h5 className="font-size-15">
                                  ${userProfile.revenue}
                                </h5>
                                <p className="text-muted mb-0">Revenue</p>
                              </Col>
                            </Row>
                            <div className="mt-4">
                              <Link to="" className="btn btn-primary btn-sm">
                                View Profile{" "}
                                <i className="mdi mdi-arrow-right ms-1" />
                              </Link>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4 h4">
                        Personal Information
                      </CardTitle>

                      <p className="text-muted mb-4">
                        {userProfile.personalDetail}
                      </p>
                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th scope="row">Full Name :</th>
                              <td>{userProfile.name}</td>
                            </tr>
                            <tr>
                              <th scope="row">Mobile :</th>
                              <td>{userProfile.phone}</td>
                            </tr>
                            <tr>
                              <th scope="row">E-mail :</th>
                              <td>{userProfile.email}</td>
                            </tr>
                            <tr>
                              <th scope="row">Location :</th>
                              <td>{userProfile.location}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <CardTitle className="mb-5">Experience</CardTitle>
                      <div className="">
                        <ul className="verti-timeline list-unstyled">
                          {map(
                            userProfile.experiences ?? [],
                            (experience, i) => (
                              <li
                                className={
                                  experience.id === 1
                                    ? "event-list active"
                                    : "event-list"
                                }
                                key={"_exp_" + i}
                              >
                                <div className="event-timeline-dot">
                                  <i
                                    className={
                                      experience.id === 1
                                        ? "bx bx-right-arrow-circle bx-fade-right"
                                        : "bx bx-right-arrow-circle"
                                    }
                                  />
                                </div>
                                <div className="d-flex">
                                  <div className="me-3">
                                    <i
                                      className={
                                        "bx " +
                                        experience.iconClass +
                                        " h4 text-primary"
                                      }
                                    />
                                  </div>
                                  <div className="flex-1">
                                    <div>
                                      <h5 className="font-size-15">
                                        <Link
                                          to={experience.link}
                                          className="text-dark"
                                        >
                                          {experience.designation}
                                        </Link>
                                      </h5>
                                      <span className="text-primary">
                                        {experience.timeDuration}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl="8">
                  <Row>
                    {this.state.miniCards.map((card, key) => (
                      <MiniCards
                        title={card.title}
                        text={card.text}
                        iconClass={card.iconClass}
                        key={"_card_" + key}
                      />
                    ))}
                  </Row>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4 h4">Revenue</CardTitle>
                      <div id="revenue-chart" className="apex-charts">
                        <ApexRevenue />
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4 h4">My Projects</CardTitle>
                      <ToolkitProvider
                        keyField="id"
                        data={userProfile.projects || []}
                        columns={projectColumns()}
                        bootstrap4
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-nowrap table-hover mb-0"
                                    }
                                    headerWrapperClasses={"tbody-light"}
                                    {...toolkitProps.baseProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ContactsProfile.propTypes = {
  match: PropTypes.any,
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile,
});

const mapDispatchToProps = dispatch => ({
  onGetUserProfile: id => dispatch(getUserProfile(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsProfile));
