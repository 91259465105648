import { call, put, takeEvery } from "redux-saga/effects";

// Event Redux States
import {
  GET_EVENTS,
  GET_EVENT_DETAIL,
  ADD_NEW_EVENT,
  DELETE_EVENT,
  UPDATE_EVENT,
} from "./actionTypes";
import {
  getEventsSuccess,
  getEventsFail,
  getEventDetailSuccess,
  getEventDetailFail,
  addEventFail,
  addEventSuccess,
  updateEventSuccess,
  updateEventFail,
  deleteEventSuccess,
  deleteEventFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getEvents,
  getEventsDetails,
  addNewEvent,
  updateEvent,
  deleteEvent,
} from "helpers/Api/events";

function* fetchEvents() {
  try {
    const response = yield call(getEvents);
    if (response.success) {
      yield put(getEventsSuccess(response.data));
    } else {
      yield put(getEventsFail(response.message));
    }
  } catch (error) {
    yield put(getEventsFail(error));
  }
}

function* fetchEventDetail({ eventId }) {
  try {
    if (eventId === undefined) {
      yield put(getEventDetailSuccess(null));
    } else {
      const response = yield call(getEventsDetails, eventId);
      if (response.success) {
        yield put(getEventDetailSuccess(response.data));
      } else {
        yield put(getEventDetailFail(response.message));
      }
    }
  } catch (error) {
    yield put(getEventDetailFail(error));
  }
}

function* onUpdateEvent({ payload: event }) {
  try {
    const response = yield call(updateEvent, event);
    if (response.success) {
      yield put(updateEventSuccess(response.data));
    } else {
      yield put(updateEventFail(response.message));
    }
  } catch (error) {
    yield put(updateEventFail(error));
  }
}

function* onDeleteEvent({ payload: event }) {
  try {
    const response = yield call(deleteEvent, event);
    if (response.success) {
      yield put(deleteEventSuccess(response.data));
    } else {
      yield put(deleteEventFail(response.message));
    }
  } catch (error) {
    yield put(deleteEventFail(error));
  }
}

function* onAddNewEvent({ payload: event }) {
  try {
    const response = yield call(addNewEvent, event);
    if (response.success) {
      yield put(addEventSuccess(response.data));
    } else {
      yield put(addEventFail(response.message));
    }
  } catch (error) {
    yield put(addEventFail(error));
  }
}

function* eventsSaga() {
  yield takeEvery(GET_EVENTS, fetchEvents);
  yield takeEvery(GET_EVENT_DETAIL, fetchEventDetail);
  yield takeEvery(ADD_NEW_EVENT, onAddNewEvent);
  yield takeEvery(UPDATE_EVENT, onUpdateEvent);
  yield takeEvery(DELETE_EVENT, onDeleteEvent);
}

export default eventsSaga;
