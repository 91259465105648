import {
  GET_TRAININGS,
  GET_TRAININGS_FAIL,
  GET_TRAININGS_SUCCESS,
  GET_TRAINING_DETAIL,
  GET_TRAINING_DETAIL_FAIL,
  GET_TRAINING_DETAIL_SUCCESS,
  ADD_NEW_TRAINING,
  ADD_TRAINING_SUCCESS,
  ADD_TRAINING_FAIL,
  SUBMIT_TRAINING,
  SUBMIT_TRAINING_SUCCESS,
  SUBMIT_TRAINING_FAIL,
  GET_TRAINING_ANSWER,
  GET_TRAINING_ANSWER_SUCCESS,
  GET_TRAINING_ANSWER_FAIL,
  UPDATE_TRAINING,
  UPDATE_TRAINING_SUCCESS,
  UPDATE_TRAINING_FAIL,
  DELETE_TRAINING,
  DELETE_TRAINING_SUCCESS,
  DELETE_TRAINING_FAIL,
} from "./actionTypes";

export const getTrainings = () => ({
  type: GET_TRAININGS,
});

export const getTrainingsSuccess = trainings => ({
  type: GET_TRAININGS_SUCCESS,
  payload: trainings,
});

export const getTrainingsFail = error => ({
  type: GET_TRAININGS_FAIL,
  payload: error,
});

export const getTrainingDetail = trainingId => ({
  type: GET_TRAINING_DETAIL,
  trainingId,
});

export const getTrainingDetailSuccess = trainingDetails => ({
  type: GET_TRAINING_DETAIL_SUCCESS,
  payload: trainingDetails,
});

export const getTrainingDetailFail = error => ({
  type: GET_TRAINING_DETAIL_FAIL,
  payload: error,
});

export const addNewTraining = training => ({
  type: ADD_NEW_TRAINING,
  payload: training,
});

export const addTrainingSuccess = training => ({
  type: ADD_TRAINING_SUCCESS,
  payload: training,
});

export const addTrainingFail = error => ({
  type: ADD_TRAINING_FAIL,
  payload: error,
});

export const submitTraining = training => ({
  type: SUBMIT_TRAINING,
  payload: training,
});

export const submitTrainingSuccess = training => ({
  type: SUBMIT_TRAINING_SUCCESS,
  payload: training,
});

export const submitTrainingFail = error => ({
  type: SUBMIT_TRAINING_FAIL,
  payload: error,
});

export const getTrainingAnswer = trainingId => ({
  type: GET_TRAINING_ANSWER,
  payload: trainingId,
});

export const getTrainingAnswerSuccess = answers => ({
  type: GET_TRAINING_ANSWER_SUCCESS,
  payload: answers,
});

export const getTrainingAnswerFail = error => ({
  type: GET_TRAINING_ANSWER_FAIL,
  payload: error,
});

export const updateTraining = training => ({
  type: UPDATE_TRAINING,
  payload: training,
});

export const updateTrainingSuccess = training => ({
  type: UPDATE_TRAINING_SUCCESS,
  payload: training,
});

export const updateTrainingFail = error => ({
  type: UPDATE_TRAINING_FAIL,
  payload: error,
});

export const deleteTraining = training => ({
  type: DELETE_TRAINING,
  payload: training,
});

export const deleteTrainingSuccess = training => ({
  type: DELETE_TRAINING_SUCCESS,
  payload: training,
});

export const deleteTrainingFail = error => ({
  type: DELETE_TRAINING_FAIL,
  payload: error,
});
