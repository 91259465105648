import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { map, size } from "lodash";

//Import Images
import images from "assets/images";

import { statusClasses } from "common/data/tasks";
import moment from "moment";
import TaskForm from "pages/Forms/TaskForm";

class CardTaskBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  openTask = () => {
    this.setState({ modal: true });
  };

  handleAddNew = () => {
    this.props.syncData();
    this.setState({ modal: false });
  };

  render() {
    const { data } = this.props;
    return (
      <React.Fragment>
        <Card className="task-box" onClick={this.openTask}>
          <CardBody className="board-width">
            <div>
              <h5 className="font-size-15">
                <Link to="#" className="text-dark">
                  {data.name}
                </Link>
              </h5>
              <p className="text-muted mb-4">
                {moment(data.end_date).format("DD/MM/YYYY")}
              </p>
            </div>

            <div className="avatar-group float-start">
              {data.assignee && (
                <div className="avatar-group-item">
                  <Link to="#" className="d-inline-block">
                    {data.assignee.userImg ? (
                      <img
                        src={images[data.assignee.userImg]}
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                    ) : (
                      <div className="avatar-xs">
                        <span className="avatar-title rounded-circle bg-info text-white font-size-16">
                          {data.assignee.name.charAt(0)}
                        </span>
                      </div>
                    )}
                  </Link>
                </div>
              )}
              {data.reporter && (
                <div className="avatar-group-item">
                  <Link to="#" className="d-inline-block">
                    {data.reporter.userImg ? (
                      <img
                        src={images[data.reporter.userImg]}
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                    ) : (
                      <div className="avatar-xs">
                        <span className="avatar-title rounded-circle bg-info text-white font-size-16">
                          {data.reporter.name.charAt(0)}
                        </span>
                      </div>
                    )}
                  </Link>
                </div>
              )}
            </div>

            <div className="text-end">
              <h5 className="font-size-15 mb-1">
                <i className="mdi mdi-currency-eur"></i> {data.budget}
              </h5>
              <p className="mb-0 text-muted">Budget</p>
            </div>
          </CardBody>
        </Card>
        <Modal isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle} tag="h4">
            Add <span className="text-capitalize">Task</span>
          </ModalHeader>
          <ModalBody>
            <TaskForm taskDetail={data} onDone={this.handleAddNew} />
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

CardTaskBox.propTypes = {
  data: PropTypes.object,
  syncData: PropTypes.func,
};

export default CardTaskBox;
