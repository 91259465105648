import { call, put, takeEvery } from "redux-saga/effects";

// Client Redux States
import {
  GET_CLIENTS,
  GET_CLIENT_DETAIL,
  ADD_NEW_CLIENT,
  DELETE_CLIENT,
  UPDATE_CLIENT,
} from "./actionTypes";
import {
  getClientsSuccess,
  getClientsFail,
  getClientDetailSuccess,
  getClientDetailFail,
  addClientFail,
  addClientSuccess,
  updateClientSuccess,
  updateClientFail,
  deleteClientSuccess,
  deleteClientFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getClients,
  getClientsDetails,
  addNewClient,
  updateClient,
  deleteClient,
} from "helpers/Api/clients";

function* fetchClients() {
  try {
    const response = yield call(getClients);
    if (response.success) {
      yield put(getClientsSuccess(response.data));
    } else {
      yield put(getClientsFail(response.message));
    }
  } catch (error) {
    yield put(getClientsFail(error));
  }
}

function* fetchClientDetail({ clientId }) {
  try {
    if (clientId === undefined) {
      yield put(getClientDetailSuccess(null));
    } else {
      const response = yield call(getClientsDetails, clientId);
      if (response.success) {
        yield put(getClientDetailSuccess(response.data));
      } else {
        yield put(getClientDetailFail(response.message));
      }
    }
  } catch (error) {
    yield put(getClientDetailFail(error));
  }
}

function* onUpdateClient({ payload: client }) {
  try {
    const response = yield call(updateClient, client);
    if (response.success) {
      yield put(updateClientSuccess(response.data));
    } else {
      yield put(updateClientFail(response.message));
    }
  } catch (error) {
    yield put(updateClientFail(error));
  }
}

function* onDeleteClient({ payload: client }) {
  try {
    const response = yield call(deleteClient, client);
    if (response.success) {
      yield put(deleteClientSuccess(response.data));
    } else {
      yield put(deleteClientFail(response.message));
    }
  } catch (error) {
    yield put(deleteClientFail(error));
  }
}

function* onAddNewClient({ payload: client }) {
  try {
    const response = yield call(addNewClient, client);
    if (response.success) {
      yield put(addClientSuccess(response.data));
    } else {
      yield put(addClientFail(response.message));
    }
  } catch (error) {
    yield put(addClientFail(error));
  }
}

function* clientsSaga() {
  yield takeEvery(GET_CLIENTS, fetchClients);
  yield takeEvery(GET_CLIENT_DETAIL, fetchClientDetail);
  yield takeEvery(ADD_NEW_CLIENT, onAddNewClient);
  yield takeEvery(UPDATE_CLIENT, onUpdateClient);
  yield takeEvery(DELETE_CLIENT, onDeleteClient);
}

export default clientsSaga;
