/* eslint-disable react/prop-types */
import React, { useState } from "react";
import QuestionForm from "./QuestionForm";

import { Button, ButtonGroup, Col, Input, Label, Row } from "reactstrap";

export default function SurveyQuestion({
  preview,
  question,
  answer,
  setQuestion,
  setAnswer,
  removeQuestion,
  moveQuestionUp,
  moveQuestionDown,
}) {
  const [editing, setEditing] = useState(false);
  const [tmp, setTmp] = useState(
    question.hasOptions && question.inputType === "checkbox" ? [] : ""
  );

  function toggleEditing() {
    setEditing(!editing);
  }

  function toggleOption(option) {
    let tmpAnswer = option;
    if (Array.isArray(tmp)) {
      let optionIndex = tmp.indexOf(option);
      if (optionIndex === -1) {
        tmpAnswer = [...tmp, option];
      } else {
        let t = [...tmp];
        t.splice(optionIndex, 1);
        tmpAnswer = t;
      }
    }
    setTmp(tmpAnswer);
    setAnswer({ question_id: question.id, answer: tmpAnswer });
  }

  const getCheckedOption = option => {
    if (answer?.answer) {
      return answer.answer.includes(option?.text);
    }
    if (Array.isArray(tmp)) {
      return tmp.includes(option.text);
    }
    return option.text === tmp;
  };

  return (
    <li className="border-top pt-3">
      {!preview && editing ? (
        <QuestionForm question={question} setQuestion={setQuestion} />
      ) : (
        <>
          <p>{question.text}</p>
          {question.hasOptions ? (
            question.options.map((option, i) => (
              <Label key={i} className="me-2">
                <Input
                  type={question.inputType}
                  id={option.text}
                  name={`question_${question.id}`}
                  value={option.text}
                  onChange={e => toggleOption(e.target.value)}
                  checked={getCheckedOption(option)}
                  disabled={!preview || answer?.answer !== undefined}
                />
                <span className="px-1">{option.text}</span>
              </Label>
            ))
          ) : (
            <Input
              type="textarea"
              rows={5}
              className="mb-2"
              value={answer?.answer}
              onChange={e => toggleOption(e.target.value)}
              disabled={!preview || answer?.answer !== undefined}
            />
          )}
        </>
      )}
      {!preview && (
        <Row className="justify-content-between">
          <Col md="auto" className="my-3">
            <ButtonGroup>
              <Button size="sm" color="primary" onClick={toggleEditing}>
                {editing ? (
                  <i className="fas fa-save" />
                ) : (
                  <i className="fas fa-pen" />
                )}
              </Button>
              <Button size="sm" color="danger" onClick={removeQuestion}>
                <i className="fas fa-trash-alt" />
              </Button>
            </ButtonGroup>
          </Col>
          <Col md="auto" className="my-3">
            <ButtonGroup>
              <Button
                size="sm"
                outline
                color="primary"
                onClick={moveQuestionUp}
              >
                <i className="fas fa-angle-up" />
              </Button>
              <Button
                size="sm"
                outline
                color="primary"
                onClick={moveQuestionDown}
              >
                <i className="fas fa-angle-down" />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      )}
    </li>
  );
}
