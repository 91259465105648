import React, { Component } from "react";
import PropTypes, { string } from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import { map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Card
import CardContact from "./card-contact";

import { getUsers } from "../../store/contacts/actions";
import UserForm from "pages/Forms/UserForm";
import ExcelPage from "components/Common/ExcelPage";

class ContactsImport extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      page: 1,
      perPage: 8,
      activeTab: "",
      filter: "",
      columns: [
        {
          title: "NAME",
          dataIndex: "name",
          editable: true,
        },
        {
          title: "DESIGNATION",
          dataIndex: "designation",
          editable: true,
        },
        {
          title: "EMAIL",
          dataIndex: "email",
          editable: true,
        },
      ],
    };
  }

  componentDidMount() {
    const { users, onGetUsers } = this.props;
    if (users && !users.length) {
      onGetUsers();
    }
  }

  handlePageClick = page => {
    this.setState({ page });
  };

  toggleTab = tab => {
    if (this.state.activeTab === tab) {
      this.setState({ page: 1, activeTab: "", filter: "" });
    } else {
      this.setState({ page: 1, activeTab: tab, filter: tab });
    }
  };

  filterUser = user => {
    if (this.state.filter === "agency") {
      return !["promoter", "client"].includes(user.designation?.toLowerCase());
    }
    return (
      !this.state.filter ||
      user.designation?.toLowerCase() === this.state.filter
    );
  };

  render() {
    const { page, perPage } = this.state;
    const { users } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Contacts | AD&amp;VISION Event Management</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Contacts" breadcrumbItem="Import" />
            <ExcelPage
              type="contacts"
              columns={this.state.columns}
              sample_file_url="https://staging-apps.com/demo_files/Users_Sample_Excel_Sheet.xlsx"
            />
          </Container>

          <Modal isOpen={this.state.modal}>
            <ModalHeader
              toggle={() => this.setState({ modal: !this.state.modal })}
              tag="h4"
            >
              Add User
            </ModalHeader>
            <ModalBody>
              <UserForm onDone={() => this.setState({ modal: false })} />
            </ModalBody>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

ContactsImport.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsImport));
