import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_PROJECTS,
  GET_PROJECT_DETAIL,
  ADD_NEW_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT,
  IMPORT_PROJECT_DETAILS,
} from "./actionTypes";
import {
  getProjectsSuccess,
  getProjectsFail,
  getProjectDetailSuccess,
  getProjectDetailFail,
  addProjectFail,
  addProjectSuccess,
  updateProjectSuccess,
  updateProjectFail,
  deleteProjectSuccess,
  deleteProjectFail,
  importProjectDetailsSuccess,
  importProjectDetailsFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getProjects,
  getProjectsDetails,
  addNewProject,
  updateProject,
  deleteProject,
  importProjectDetails,
} from "helpers/Api/projects";

function* fetchProjects({ payload: archived }) {
  try {
    const response = yield call(getProjects, archived);
    if (response.success) {
      yield put(getProjectsSuccess(response.data));
    }
  } catch (error) {
    yield put(getProjectsFail(error));
  }
}

function* fetchProjectDetail({ projectId }) {
  try {
    if (projectId === undefined) {
      yield put(getProjectDetailSuccess(null));
    } else {
      const response = yield call(getProjectsDetails, projectId);
      if (response.success) {
        yield put(getProjectDetailSuccess(response.data));
      } else {
        yield put(getProjectDetailFail(response.message));
      }
    }
  } catch (error) {
    yield put(getProjectDetailFail(error));
  }
}

function* onUpdateProject({ payload: project }) {
  try {
    const response = yield call(updateProject, project);
    if (response.success) {
      yield put(updateProjectSuccess(response.data));
    }
  } catch (error) {
    yield put(updateProjectFail(error));
  }
}

function* onDeleteProject({ payload: project, archive }) {
  try {
    const response = yield call(deleteProject, project, archive);
    if (response.success) {
      yield put(deleteProjectSuccess(response.data));
    }
  } catch (error) {
    yield put(deleteProjectFail(error));
  }
}

function* onAddNewProject({ payload: project }) {
  try {
    const response = yield call(addNewProject, project);
    if (response.success) {
      yield put(addProjectSuccess(response.data));
    }
  } catch (error) {
    yield put(addProjectFail(error));
  }
}

function* onImportProjectDetails({ payload: values }) {
  try {
    const response = yield call(importProjectDetails, values);
    if (response.success) {
      yield put(importProjectDetailsSuccess(response.data));
    } else {
      yield put(importProjectDetailsFail(response));
    }
  } catch (error) {
    yield put(importProjectDetailsFail(error?.data));
  }
}

function* projectsSaga() {
  yield takeEvery(GET_PROJECTS, fetchProjects);
  yield takeEvery(GET_PROJECT_DETAIL, fetchProjectDetail);
  yield takeEvery(ADD_NEW_PROJECT, onAddNewProject);
  yield takeEvery(IMPORT_PROJECT_DETAILS, onImportProjectDetails);
  yield takeEvery(UPDATE_PROJECT, onUpdateProject);
  yield takeEvery(DELETE_PROJECT, onDeleteProject);
}

export default projectsSaga;
