import {
  GET_TRAININGS_FAIL,
  GET_TRAININGS_SUCCESS,
  GET_TRAINING_DETAIL_FAIL,
  GET_TRAINING_DETAIL_SUCCESS,
  ADD_TRAINING_SUCCESS,
  ADD_TRAINING_FAIL,
  SUBMIT_TRAINING_SUCCESS,
  SUBMIT_TRAINING_FAIL,
  GET_TRAINING_ANSWER_SUCCESS,
  GET_TRAINING_ANSWER_FAIL,
  UPDATE_TRAINING_SUCCESS,
  UPDATE_TRAINING_FAIL,
  DELETE_TRAINING_SUCCESS,
  DELETE_TRAINING_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  trainings: [],
  trainingDetail: {},
  error: {},
  submission: {},
};

const Trainings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRAININGS_SUCCESS:
      return {
        ...state,
        trainings: action.payload,
      };

    case GET_TRAININGS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_TRAINING_DETAIL_SUCCESS:
      return {
        ...state,
        trainingDetail: action.payload,
      };

    case GET_TRAINING_DETAIL_FAIL:
      return {
        ...state,
        trainingDetail: null,
        error: action.payload,
      };

    case ADD_TRAINING_SUCCESS:
      return {
        ...state,
        trainings: [...state.trainings, action.payload],
      };

    case ADD_TRAINING_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case SUBMIT_TRAINING_SUCCESS:
      return {
        ...state,
        submission: action.payload,
        error: {},
      };

    case SUBMIT_TRAINING_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_TRAINING_ANSWER_SUCCESS:
      return {
        ...state,
        answer: action.payload,
        error: {},
      };

    case GET_TRAINING_ANSWER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_TRAINING_SUCCESS:
      return {
        ...state,
        trainings: state.trainings.map(training =>
          training.id.toString() === action.payload.id.toString()
            ? { training, ...action.payload }
            : training
        ),
      };

    case UPDATE_TRAINING_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_TRAINING_SUCCESS:
      return {
        ...state,
        trainings: state.trainings.filter(
          training => training.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_TRAINING_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Trainings;
