import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import guard from "helpers/jwt-token-access/apiGuard";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const { location } = props;
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        return (
          <Redirect
            to={{ pathname: "/login-user", state: { from: props.location } }}
          />
        );
      } else if (
        location.pathname === "/login" &&
        localStorage.getItem("authUser") &&
        guard === "admin"
      ) {
        return (
          <Redirect
            to={{ pathname: "/dashboard", state: { from: props.location } }}
          />
        );
      } else if (
        (location.pathname === "/login" ||
          location.pathname === "/login-user") &&
        localStorage.getItem("authUser") &&
        guard === "api"
      ) {
        return (
          <Redirect
            to={{ pathname: "/projects-grid", state: { from: props.location } }}
          />
        );
      }
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default AppRoute;
