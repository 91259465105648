import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Button,
} from "reactstrap";
import {
  addNewClient,
  getClientDetail,
  getBrands,
  updateClient,
} from "store/actions";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { any } from "prop-types";
import ClientForm from "pages/Forms/ClientForm";

class ClientCreate extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const {
      match: { params },
      onGetBrands,
      onGetClientDetail,
    } = this.props;
    onGetBrands();
    onGetClientDetail(params?.id);
  }

  render() {
    const { clientDetail, brands } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Clients | AD&amp;VISION - Event Management</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Home" breadcrumbItem="Clients" />
            <Card>
              <CardBody>
                <ClientForm
                  onDone={() => this.props.history.push("/clients")}
                />
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ClientCreate.propTypes = {
  history: any,
  brands: PropTypes.array,
  clientDetail: PropTypes.object,
  match: PropTypes.object,
  onGetBrands: PropTypes.func,
  onAddNewClient: PropTypes.func,
  onGetClientDetail: PropTypes.func,
  onUpdateClient: PropTypes.func,
  users: PropTypes.array,
};

const mapStateToProps = ({ Brands, Clients }) => ({
  clientDetail: Clients.clientDetail,
  brands: Brands.brands,
});

const mapDispatchToProps = dispatch => ({
  onGetBrands: () => dispatch(getBrands()),
  onAddNewClient: client => dispatch(addNewClient(client)),
  onGetClientDetail: id => dispatch(getClientDetail(id)),
  onUpdateClient: client => dispatch(updateClient(client)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClientCreate));
