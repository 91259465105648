import {
  GET_TASKS,
  GET_TASKS_FAIL,
  GET_TASKS_SUCCESS,
  GET_TASK_DETAIL,
  GET_TASK_DETAIL_FAIL,
  GET_TASK_DETAIL_SUCCESS,
  ADD_NEW_TASK,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAIL,
  UPDATE_TASK,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAIL,
  DELETE_TASK,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAIL,
} from "./actionTypes";

export const getTasks = location_id => ({
  type: GET_TASKS,
  location_id,
});

export const getTasksSuccess = tasks => ({
  type: GET_TASKS_SUCCESS,
  payload: tasks,
});

export const getTasksFail = error => ({
  type: GET_TASKS_FAIL,
  payload: error,
});

export const getTaskDetail = taskId => ({
  type: GET_TASK_DETAIL,
  taskId,
});

export const getTaskDetailSuccess = taskDetails => ({
  type: GET_TASK_DETAIL_SUCCESS,
  payload: taskDetails,
});

export const getTaskDetailFail = error => ({
  type: GET_TASK_DETAIL_FAIL,
  payload: error,
});

export const addNewTask = task => ({
  type: ADD_NEW_TASK,
  payload: task,
});

export const addTaskSuccess = task => ({
  type: ADD_TASK_SUCCESS,
  payload: task,
});

export const addTaskFail = error => ({
  type: ADD_TASK_FAIL,
  payload: error,
});

export const updateTask = task => ({
  type: UPDATE_TASK,
  payload: task,
});

export const updateTaskSuccess = task => ({
  type: UPDATE_TASK_SUCCESS,
  payload: task,
});

export const updateTaskFail = error => ({
  type: UPDATE_TASK_FAIL,
  payload: error,
});

export const deleteTask = task => ({
  type: DELETE_TASK,
  payload: task,
});

export const deleteTaskSuccess = task => ({
  type: DELETE_TASK_SUCCESS,
  payload: task,
});

export const deleteTaskFail = error => ({
  type: DELETE_TASK_FAIL,
  payload: error,
});
