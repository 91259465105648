import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getProjectDetail, updateEvent, updateLocation } from "store/actions";
import { FrappeGantt } from "frappe-gantt-react";
import moment from "moment";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import EventForm from "pages/Forms/EventForm";
import LocationForm from "pages/Forms/LocationForm";

const ViewMode = {
  Day: "Day",
  Week: "Week",
  Month: "Month",
};

class GanttChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: "Day",
    };
  }

  getGanttChartData = () => {
    const { projectDetail } = this.props;
    let data = false;
    if (projectDetail?.tour_plan?.length > 0) {
      data = [];
      projectDetail.tour_plan.forEach(roadshow => {
        data.push({
          id: `roadshow-${roadshow.id}`,
          name: roadshow.name,
          start: roadshow.start_date,
          end: roadshow.end_date,
        });
        roadshow.events.forEach(event => {
          data.push({
            id: `event-${event.id}`,
            name: event.name,
            start: event.start_date,
            end: event.end_date,
            dependencies: `roadshow-${roadshow.id}`,
          });
          event.locations.forEach(location => {
            data.push({
              id: `location-${location.id}`,
              name: location.name,
              start: location.start_date,
              end: location.end_date,
              dependencies: `event-${event.id}`,
            });
            if (location.tasks?.length > 0) {
              data.push({
                id: `tasks-${location.id}`,
                name: "Tasks",
                start: location.start_date,
                end: location.end_date,
                dependencies: `location-${location.id}`,
              });
            }
          });
        });
      });
    }
    return data;
  };

  onDateChange = (task, start, end) => {
    const { onUpdateEvent, onUpdateLocation } = this.props;
    const data = task.id?.split("-");
    const type = data[0];
    const id = data[1];
    const values = {
      ...task,
      id,
      start_date: moment(start).format("YYYY-MM-DD"),
      end_date: moment(end).format("YYYY-MM-DD"),
    };
    switch (type) {
      case "event":
        onUpdateEvent(values);
        break;
      case "location":
        onUpdateLocation(values);
        break;
    }
  };

  handleDone = () => {
    console.log("handleDone");
    const { projectDetail, onGetProjectDetail } = this.props;
    onGetProjectDetail(projectDetail.id);
    this.setState({
      modal: false,
      modalData: null,
      modalParent: null,
    });
  };

  onItemClick = task => {
    console.log(task);
    const { history, projectDetail } = this.props;
    const data = task.id?.split("-");
    const id = data[1];
    const modalType = data[0];
    const dependencies = task.dependencies[0];
    let parent_id = 0;
    if (dependencies !== undefined && dependencies.split("-").length > 1) {
      parent_id = dependencies.split("-")[1];
    }
    const modalParent = {
      id: parent_id,
    };
    const modalData = {
      id,
    };
    if (modalType === "tasks") {
      history.push(`/projects-overview/${projectDetail.id}/board/${id}`);
    } else if (modalType !== "roadshow") {
      this.setState({
        modal: true,
        modalType,
        modalData,
        modalParent,
      });
    }
  };

  render = () => (
    <React.Fragment>
      <div className="btn-group mb-3" role="group">
        {Object.keys(ViewMode).map(mode => (
          <button
            key={mode}
            type="button"
            className={`btn btn-outline-primary w-sm ${
              mode === this.state.mode ? "active" : ""
            }`}
            onClick={() => this.setState({ mode })}
          >
            {ViewMode[mode]}
          </button>
        ))}
      </div>
      {this.getGanttChartData() && (
        <FrappeGantt
          tasks={this.getGanttChartData()}
          viewMode={this.state.mode}
          onClick={task => this.onItemClick(task)}
          onDateChange={this.onDateChange}
          // onProgressChange={(task, progress) =>
          //   console.log(task, progress)
          // }
          // onTasksChange={tasks => console.log(tasks)}
        />
      )}
      <Modal isOpen={this.state.modal}>
        <ModalHeader toggle={this.handleDone} tag="h4">
          Edit <span className="text-capitalize">{this.state.modalType}</span>
        </ModalHeader>
        <ModalBody>
          {this.state.modalType === "event" && (
            <EventForm
              id={this.state.modalData?.id}
              eventDetail={this.state.modalData}
              roadshowDetail={this.state.modalParent}
              onDone={this.handleDone}
            />
          )}
          {this.state.modalType === "location" && (
            <LocationForm
              id={this.state.modalData?.id}
              locationDetail={this.state.modalData}
              eventDetail={this.state.modalParent}
              onDone={this.handleDone}
            />
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

GanttChart.propTypes = {
  history: PropTypes.any,
  match: PropTypes.object,
  projectDetail: PropTypes.object,
  onGetProjectDetail: PropTypes.func,
  onUpdateRoadshow: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onUpdateLocation: PropTypes.func,
};

const mapStateToProps = ({ projects }) => ({
  projectDetail: projects.projectDetail,
});

const mapDispatchToProps = dispatch => ({
  onGetProjectDetail: id => dispatch(getProjectDetail(id)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onUpdateLocation: location => dispatch(updateLocation(location)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GanttChart));
