import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Tree, TreeNode } from "react-organizational-chart";
import RoadshowForm from "pages/Forms/RoadshowForm";
import EventForm from "pages/Forms/EventForm";
import LocationForm from "pages/Forms/LocationForm";
import { connect } from "react-redux";
import { getProjectDetail } from "store/actions";
import { withRouter } from "react-router-dom";
import guard from "helpers/jwt-token-access/apiGuard";

class TourPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalType: "location",
      modalParent: null,
      modalData: null,
    };
  }

  /**
   * Handling the modal state
   */
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };

  handleAddNew = () => {
    const { projectDetail, onGetProjectDetail } = this.props;
    onGetProjectDetail(projectDetail.id);
    this.setState({ modal: false, modalData: null, modalParent: null });
  };

  render() {
    const { projectDetail } = this.props;

    const tour_plan = projectDetail?.tour_plan;

    if (!tour_plan?.length) {
      return <div />;
    }

    const roadshow = tour_plan[0];

    return (
      <>
        <Tree
          label={
            <Button outline={roadshow.status === 0}>{roadshow.name}</Button>
          }
        >
          {roadshow.events?.map((event, ti) => (
            <TreeNode
              key={ti}
              label={
                <Button
                  outline={event.status === 0}
                  onClick={() =>
                    guard === "admin" &&
                    this.setState({
                      modal: true,
                      modalType: "event",
                      modalData: event,
                      modalParent: roadshow,
                    })
                  }
                  color="info"
                >
                  {event.name}
                </Button>
              }
            >
              {event.locations?.map((location, li) => (
                <TreeNode
                  key={li}
                  label={
                    <Button
                      outline={location.status === 0}
                      onClick={() =>
                        guard === "admin" &&
                        this.setState({
                          modal: true,
                          modalType: "location",
                          modalData: location,
                          modalParent: event,
                        })
                      }
                    >
                      {location.name}
                    </Button>
                  }
                >
                  <TreeNode
                    label={
                      <Button
                        outline={
                          location.tasks ? location.tasks.length === 0 : true
                        }
                        size="sm"
                        color="success"
                        onClick={() =>
                          this.props.history.push(
                            `/projects-overview/${projectDetail.id}/board/${location.id}`
                          )
                        }
                      >
                        Tasks
                      </Button>
                    }
                  />
                </TreeNode>
              ))}
              {guard === "admin" && (
                <TreeNode
                  label={
                    <Button
                      outline
                      size="sm"
                      color="success"
                      onClick={() =>
                        this.setState({
                          modal: true,
                          modalType: "location",
                          modalParent: event,
                          modalData: null,
                        })
                      }
                    >
                      <i className="mdi mdi-plus" />
                    </Button>
                  }
                />
              )}
            </TreeNode>
          ))}
          {guard === "admin" && (
            <TreeNode
              label={
                <Button
                  outline
                  size="sm"
                  color="success"
                  onClick={() =>
                    this.setState({
                      modal: true,
                      modalType: "event",
                      modalParent: roadshow,
                      modalData: null,
                    })
                  }
                >
                  <i className="mdi mdi-plus" />
                </Button>
              }
            />
          )}
        </Tree>
        <Modal isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle} tag="h4">
            Add <span className="text-capitalize">{this.state.modalType}</span>
          </ModalHeader>
          <ModalBody>
            {this.state.modalType === "roadshow" && (
              <RoadshowForm
                roadshowDetail={this.state.modalData}
                projectDetail={projectDetail}
                onDone={this.handleAddNew}
              />
            )}
            {this.state.modalType === "event" && (
              <EventForm
                id={this.state.modalData?.id}
                eventDetail={this.state.modalData}
                roadshowDetail={this.state.modalParent}
                onDone={this.handleAddNew}
              />
            )}
            {this.state.modalType === "location" && (
              <LocationForm
                id={this.state.modalData?.id}
                locationDetail={this.state.modalData}
                projectDetail={projectDetail}
                eventDetail={this.state.modalParent}
                onDone={this.handleAddNew}
              />
            )}
          </ModalBody>
        </Modal>
      </>
    );
  }
}

TourPlan.propTypes = {
  history: PropTypes.any,
  projectDetail: PropTypes.object,
  onGetProjectDetail: PropTypes.func,
};

const mapStateToProps = ({ projects }) => ({
  projectDetail: projects.projectDetail,
});

const mapDispatchToProps = dispatch => ({
  onGetProjectDetail: id => dispatch(getProjectDetail(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TourPlan));
