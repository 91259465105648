/* LOCATIONS */
export const GET_LOCATIONS = "GET_LOCATIONS"
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS"
export const GET_LOCATIONS_FAIL = "GET_LOCATIONS_FAIL"

/* LOCATIONS DETAIL*/
export const GET_LOCATION_DETAIL = "GET_LOCATION_DETAIL"
export const GET_LOCATION_DETAIL_SUCCESS = "GET_LOCATION_DETAIL_SUCCESS"
export const GET_LOCATION_DETAIL_FAIL = "GET_LOCATION_DETAIL_FAIL"

export const ADD_NEW_LOCATION = "ADD_NEW_LOCATION"
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS"
export const ADD_LOCATION_FAIL = "ADD_LOCATION_FAIL"

export const UPDATE_LOCATION = "UPDATE_LOCATION"
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS"
export const UPDATE_LOCATION_FAIL = "UPDATE_LOCATION_FAIL"

export const DELETE_LOCATION = "DELETE_LOCATION"
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS"
export const DELETE_LOCATION_FAIL = "DELETE_LOCATION_FAIL"