import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// actions
import { deleteBrand, getBrands } from "store/actions";
import { MetaTags } from "react-meta-tags";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Link, withRouter } from "react-router-dom";
import { map } from "lodash";
import DeleteModal from "components/Common/DeleteModal";

class BrandsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brand: null,
      deleteModal: false,
    };
  }
  componentDidMount() {
    const { onGetBrands } = this.props;
    onGetBrands();
  }

  onClickDelete = brand => {
    this.setState({ brand, deleteModal: true });
  };

  onClickEdit = brand => {
    if (brand.id !== undefined) {
      this.props.history.push(`/brand-create/${brand.id}`);
    }
  };

  handleDeleteBrand = () => {
    const { onDeleteBrand } = this.props;
    const { brand } = this.state;
    if (brand.id !== undefined) {
      onDeleteBrand(brand.id);
      this.setState({ deleteModal: false });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ ...this.state, brands: this.props.brands });
    }
  }

  render() {
    const { brands } = this.props;
    const { deleteModal } = this.state;

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteBrand}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <MetaTags>
            <title>Starter Page | AD&amp;VISION - Event Tool</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Brands" breadcrumbItem="List" />
            <Card>
              <CardHeader className="h5 bg-transparent border-bottom text-end">
                <Button
                  color="success"
                  onClick={() => this.props.history.push("/brand-create")}
                >
                  Create New
                </Button>
              </CardHeader>
              <CardBody>
                <Table striped>
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "100px" }}>
                        #
                      </th>
                      <th scope="col">Brand</th>
                      <th scope="col">Client</th>
                      <th scope="col">Parent</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(brands, (brand, index) => (
                      <tr key={index}>
                        <td>{brand.id}</td>
                        <td>
                          <h5 className="text-truncate font-size-14">
                            <Link
                              to={`/brands-overview/${brand.id}`}
                              className="text-dark"
                            >
                              {brand.name}
                            </Link>
                          </h5>
                        </td>

                        <td>{brand.client?.name}</td>
                        <td>{brand.parent?.name}</td>
                        <td>
                          <ButtonGroup>
                            <Button
                              outline
                              color="success"
                              onClick={() => this.onClickEdit(brand)}
                            >
                              <i className="mdi mdi-pencil font-size-16 me-1" />{" "}
                            </Button>
                            <Button
                              outline
                              color="danger"
                              onClick={() => this.onClickDelete(brand)}
                            >
                              <i className="mdi mdi-trash-can font-size-16 me-1" />{" "}
                            </Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

BrandsList.propTypes = {
  history: PropTypes.any,
  brands: PropTypes.any,
  onGetBrands: PropTypes.func,
  onDeleteBrand: PropTypes.func,
};

const mapStateToProps = ({ Brands }) => ({
  brands: Brands.brands,
});

const mapDispatchToProps = dispatch => ({
  onGetBrands: () => dispatch(getBrands()),
  onDeleteBrand: id => dispatch(deleteBrand(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BrandsList));
