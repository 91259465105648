import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// get brand
export const getBrands = () => get(url.GET_BRANDS);

// get brand details
export const getBrandsDetails = id => get(`${url.GET_BRAND_DETAIL}/${id}`);

// add brand
export const addNewBrand = brand => post(url.ADD_NEW_BRAND, brand);

// update brand
export const updateBrand = brand => post(`${url.UPDATE_BRAND}/${brand.id}`, brand);

// delete brand
export const deleteBrand = id => del(`${url.DELETE_BRAND}/${id}`);
