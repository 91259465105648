import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Row,
  Alert,
  Label,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { connect } from "react-redux";
import { resetPassword } from "store/actions";

class Recoverpw extends Component {
  constructor(props) {
    super(props);
    this.form = createRef();
  }

  componentDidUpdate() {
    const { error } = this.props;
    this.form.current?.setErrors(error?.data?.errors ?? {});
  }

  render() {
    const { search } = this.props.location;
    const query = new URLSearchParams(search);

    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-sm-5">
          <MetaTags>
            <title>Recover Password | AD&amp;VISION - Event Tool</title>
          </MetaTags>
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary"> Reset Password</h5>
                          <p>AD&amp;VISION Management Tool</p>
                        </div>
                      </Col>
                      <Col xs={5} className="align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="dashboard">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>

                    <div className="p-2">
                      {this.props.successMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {this.props.successMsg}
                        </Alert>
                      ) : null}
                      {this.props.successMsg === "" && (
                        <Formik
                          innerRef={this.form}
                          enableReinitialize={true}
                          initialValues={{
                            token: query.get("token"),
                            email: query.get("email"),
                            password: "",
                            password_confirmation: "",
                          }}
                          validationSchema={Yup.object().shape({
                            email: Yup.string().required(
                              "Please Enter Your Email"
                            ),
                            password: Yup.string().required(
                              "Password is required"
                            ),
                            password_confirmation: Yup.string().test(
                              "passwords-match",
                              "Passwords must match",
                              function (value) {
                                return this.parent.password === value;
                              }
                            ),
                          })}
                          onSubmit={values => {
                            console.log(values, "values");
                            this.props.onResetPassword(values);
                          }}
                        >
                          {({ errors, status, touched }) => (
                            <Form className="form-horizontal">
                              {this.props.apiError &&
                              typeof this.props.apiError === "string" ? (
                                <Alert color="danger">
                                  {this.props.apiError}
                                </Alert>
                              ) : null}
                              {this.props.error &&
                              typeof this.props.error === "string" ? (
                                <Alert color="danger">{this.props.error}</Alert>
                              ) : null}

                              <div className="mb-3">
                                <Label for="email" className="form-label">
                                  Email
                                </Label>
                                <Field
                                  disabled
                                  name="email"
                                  placeholder="Enter email"
                                  type="email"
                                  className={
                                    "form-control" +
                                    (errors.email && touched.email
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mb-3">
                                <Label for="password" className="form-label">
                                  Password
                                </Label>
                                <Field
                                  name="password"
                                  placeholder="Enter password"
                                  type="password"
                                  className={
                                    "form-control" +
                                    (errors.password && touched.password
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mb-3">
                                <Label
                                  for="password_confirmation"
                                  className="form-label"
                                >
                                  Confirm Password
                                </Label>
                                <Field
                                  name="password_confirmation"
                                  placeholder="Enter password"
                                  type="password"
                                  className={
                                    "form-control" +
                                    (errors.password_confirmation &&
                                    touched.password_confirmation
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="password_confirmation"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="text-end">
                                <Button
                                  className="btn btn-primary w-md"
                                  color="primary"
                                  type="submit"
                                >
                                  Reset
                                </Button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      )}
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Remember It ?{" "}
                    <Link to="login-user" className="fw-medium text-primary">
                      {" "}
                      Sign In here
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Recoverpw.propTypes = {
  error: PropTypes.any,
  apiError: PropTypes.any,
  successMsg: PropTypes.string,
  location: PropTypes.any,
  onResetPassword: PropTypes.func,
};

const mapStateToProps = ({ Login }) => ({
  error: Login.error,
  apiError: Login.apiError,
  successMsg: Login.successMsg,
});

const mapDispatchToProps = dispatch => ({
  onResetPassword: data => dispatch(resetPassword(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Recoverpw));
