import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { getUsers } from "../../store/contacts/actions";
// Import Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//Import Date Picker
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { any } from "prop-types";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { addNewTask } from "store/actions";

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "undo",
      "redo",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  language: "de",
};

class TasksCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      inputFields: [{ name: "", file: "" }],
    };
    this.startDateChange.bind(this);
    this.endDateChange.bind(this);
    this.handleAddFields.bind(this);
    this.handleRemoveFields.bind(this);
  }

  componentDidMount() {
    const { users, onGetUsers } = this.props;
    if (users && !users.length) {
      onGetUsers();
    }
  }

  startDateChange = date => {
    this.setState({
      startDate: date,
    });
  };

  endDateChange = date => {
    this.setState({
      endDate: date,
    });
  };

  // Function for Create Input Fields
  handleAddFields = () => {
    var values = this.state.inputFields;
    values.push({ name: "", file: "" });
    this.setState({ inputFields: values });
  };

  // Function for Remove Input Fields
  handleRemoveFields = index => {
    var values = this.state.inputFields;
    values.splice(index, 1);
    this.setState({ inputFields: values });
  };

  render() {
    const { users, onAddNewTask } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Create Task | AD&amp;VISION - Event Tool</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Tasks" breadcrumbItem="Create Task" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Create New Task</CardTitle>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        location_id: 2,
                        name: "",
                        description: "",
                        start_date: "",
                        end_date: "",
                        budget: 0,
                        cost: 0,
                        reporter_id: "",
                        assignee_id: "",
                        status: 0,
                      }}
                      validationSchema={Yup.object().shape({})}
                      onSubmit={values => {
                        onAddNewTask(values);
                      }}
                    >
                      {({
                        values,
                        errors,
                        status,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                      }) => (
                        <Form className="outer-repeater">
                          <div
                            data-repeater-list="outer-group"
                            className="outer"
                          >
                            <div data-repeater-item className="outer">
                              <FormGroup className="mb-4" row>
                                <Label
                                  htmlFor="name"
                                  className="col-form-label col-lg-2"
                                >
                                  Task Name
                                </Label>
                                <Col lg="10">
                                  <Field
                                    value={values.name}
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Task Name..."
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup className="mb-4" row>
                                <Label className="col-form-label col-lg-2">
                                  Task Description
                                </Label>
                                <Col lg="10">
                                  <CKEditor
                                    editor={ClassicEditor}
                                    data={values.description}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      setFieldValue("description", data);
                                    }}
                                    onBlur={(event, editor) => {
                                      const data = editor.getData();
                                      setFieldValue("description", data);
                                    }}
                                    placeholder="Place Your Content Here..."
                                  />
                                </Col>
                              </FormGroup>

                              <FormGroup className="mb-4" row>
                                <Label className="col-form-label col-lg-2">
                                  Task Date
                                </Label>
                                <Col lg="10">
                                  <Row>
                                    <Col md="6">
                                      <Flatpickr
                                        placeholder="D/M/Y"
                                        options={{
                                          inline: false,
                                          altInput: true,
                                          altFormat: "d/m/Y",
                                          dateFormat: "Y-m-d",
                                        }}
                                        name="start_date"
                                        value={values.start_date}
                                        onChange={([value]) =>
                                          setFieldValue(
                                            "start_date",
                                            moment(value).format("YYYY-MM-DD")
                                          )
                                        }
                                        className={
                                          "form-control" +
                                          (errors.start_date &&
                                          touched.start_date
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="start_date"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                    <Col md="6">
                                      <Flatpickr
                                        placeholder="D/M/Y"
                                        options={{
                                          inline: false,
                                          altInput: true,
                                          altFormat: "d/m/Y",
                                          dateFormat: "Y-m-d H:i",
                                        }}
                                        name="end_date"
                                        value={values.end_date}
                                        onChange={([value]) =>
                                          setFieldValue(
                                            "end_date",
                                            moment(value).format("YYYY-MM-DD")
                                          )
                                        }
                                        className={
                                          "form-control" +
                                          (errors.end_date && touched.end_date
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="end_date"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </FormGroup>

                              <div className="inner-repeater mb-4">
                                <div className="inner form-group mb-0 row">
                                  <Label className="col-form-label col-lg-2">
                                    Assignee
                                  </Label>
                                  <div
                                    className="inner col-lg-10 ms-md-auto"
                                    id="repeater"
                                  >
                                    <Select
                                      name="assignee"
                                      isClearable
                                      value={values.assignee}
                                      options={users}
                                      getOptionLabel={option =>
                                        option.name || "Create New"
                                      }
                                      getOptionValue={option => option.id}
                                      onChange={newValue => {
                                        setFieldValue("assignee", newValue);
                                        setFieldValue(
                                          "assignee_id",
                                          newValue?.id
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="inner-repeater mb-4">
                                <div className="inner form-group mb-0 row">
                                  <Label className="col-form-label col-lg-2">
                                    Reporter
                                  </Label>
                                  <div
                                    className="inner col-lg-10 ms-md-auto"
                                    id="repeater"
                                  >
                                    <Select
                                      name="reporter"
                                      isClearable
                                      value={values.reporter}
                                      options={users}
                                      getOptionLabel={option =>
                                        option.name || "Create New"
                                      }
                                      getOptionValue={option => option.id}
                                      onChange={newValue => {
                                        setFieldValue("reporter", newValue);
                                        setFieldValue(
                                          "reporter_id",
                                          newValue?.id
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <FormGroup className="mb-4" row>
                                <label
                                  htmlFor="budget"
                                  className="col-form-label col-lg-2"
                                >
                                  Budget
                                </label>
                                <div className="col-lg-10">
                                  <Field
                                    name="budget"
                                    type="number"
                                    value={values.budget}
                                    placeholder="Enter Task Budget..."
                                    className="form-control"
                                  />
                                </div>
                              </FormGroup>
                            </div>
                          </div>
                          <Row className="justify-content-end">
                            <Col lg="10">
                              <Button type="submit" color="primary">
                                Create Task
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

TasksCreate.propTypes = {
  history: any,
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
  onAddNewTask: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
  onAddNewTask: task => dispatch(addNewTask(task)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TasksCreate));
