import {
  GET_VENUES_FAIL,
  GET_VENUES_SUCCESS,
  GET_VENUE_DETAIL_FAIL,
  GET_VENUE_DETAIL_SUCCESS,
  ADD_VENUE_SUCCESS,
  ADD_VENUE_FAIL,
  UPDATE_VENUE_SUCCESS,
  UPDATE_VENUE_FAIL,
  DELETE_VENUE_SUCCESS,
  DELETE_VENUE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  venues: [],
  venueDetail: {},
  error: {},
};

const Venues = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VENUES_SUCCESS:
      return {
        ...state,
        venues: action.payload,
      };

    case GET_VENUES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_VENUE_DETAIL_SUCCESS:
      return {
        ...state,
        venueDetail: action.payload,
      };

    case GET_VENUE_DETAIL_FAIL:
      return {
        ...state,
        venueDetail: null,
        error: action.payload,
      };

    case ADD_VENUE_SUCCESS:
      return {
        ...state,
        venues: [...state.venues, action.payload],
      };

    case ADD_VENUE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_VENUE_SUCCESS:
      return {
        ...state,
        venues: state.venues.map(venue =>
          venue.id.toString() === action.payload.id.toString()
            ? { venue, ...action.payload }
            : venue
        ),
      };

    case UPDATE_VENUE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_VENUE_SUCCESS:
      return {
        ...state,
        venues: state.venues.filter(
          venue => venue.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_VENUE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Venues;
