import {
  GET_LOCATIONS_FAIL,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATION_DETAIL_FAIL,
  GET_LOCATION_DETAIL_SUCCESS,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_FAIL,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAIL,
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  locations: [],
  locationDetail: {},
  error: {},
};

const Locations = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.payload,
      };

    case GET_LOCATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_LOCATION_DETAIL_SUCCESS:
      return {
        ...state,
        locationDetail: action.payload,
      };

    case GET_LOCATION_DETAIL_FAIL:
      return {
        ...state,
        locationDetail: null,
        error: action.payload,
      };

    case ADD_LOCATION_SUCCESS:
      return {
        ...state,
        locations: [...state.locations, action.payload],
        locationDetail: action.payload,
      };

    case ADD_LOCATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        locations: state.locations.map(location =>
          location.id.toString() === action.payload.id.toString()
            ? { location, ...action.payload }
            : location
        ),
        locationDetail: action.payload,
      };

    case UPDATE_LOCATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        locations: state.locations.filter(
          location => location.id.toString() !== action.payload.id.toString()
        ),
        locationDetail: {},
      };

    case DELETE_LOCATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Locations;
