import { call, put, takeEvery } from "redux-saga/effects";

// Training Redux States
import {
  GET_TRAININGS,
  GET_TRAINING_DETAIL,
  ADD_NEW_TRAINING,
  DELETE_TRAINING,
  UPDATE_TRAINING,
  SUBMIT_TRAINING,
  GET_TRAINING_ANSWER,
} from "./actionTypes";
import {
  getTrainingsSuccess,
  getTrainingsFail,
  getTrainingDetailSuccess,
  getTrainingDetailFail,
  addTrainingFail,
  addTrainingSuccess,
  updateTrainingSuccess,
  updateTrainingFail,
  deleteTrainingSuccess,
  deleteTrainingFail,
  submitTrainingSuccess,
  submitTrainingFail,
  getTrainingAnswerSuccess,
  getTrainingAnswerFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getTrainings,
  getTrainingDetails,
  addNewTraining,
  updateTraining,
  deleteTraining,
  submitTraining,
  getTrainingAnswer,
} from "helpers/Api/trainings";

function* fetchTrainings() {
  try {
    const response = yield call(getTrainings);
    if (response.success) {
      yield put(getTrainingsSuccess(response.data));
    } else {
      yield put(getTrainingsFail(response.message));
    }
  } catch (error) {
    yield put(getTrainingsFail(error));
  }
}

function* fetchTrainingDetail({ trainingId }) {
  try {
    if (trainingId === undefined) {
      yield put(getTrainingDetailSuccess(null));
    } else {
      const response = yield call(getTrainingDetails, trainingId);
      if (response.success) {
        yield put(getTrainingDetailSuccess(response.data));
      } else {
        yield put(getTrainingDetailFail(response.message));
      }
    }
  } catch (error) {
    yield put(getTrainingDetailFail(error));
  }
}

function* onUpdateTraining({ payload: training }) {
  try {
    const response = yield call(updateTraining, training);
    if (response.success) {
      yield put(updateTrainingSuccess(response.data));
    } else {
      yield put(updateTrainingFail(response.message));
    }
  } catch (error) {
    yield put(updateTrainingFail(error));
  }
}

function* onDeleteTraining({ payload: training }) {
  try {
    const response = yield call(deleteTraining, training);
    if (response.success) {
      yield put(deleteTrainingSuccess(response.data));
    } else {
      yield put(deleteTrainingFail(response.message));
    }
  } catch (error) {
    yield put(deleteTrainingFail(error));
  }
}

function* onAddNewTraining({ payload: training }) {
  try {
    const response = yield call(addNewTraining, training);
    if (response.success) {
      yield put(addTrainingSuccess(response.data));
    } else {
      yield put(addTrainingFail(response.message));
    }
  } catch (error) {
    yield put(addTrainingFail(error));
  }
}

function* onSubmitTraining({ payload: submission }) {
  try {
    const response = yield call(submitTraining, submission);
    if (response.success) {
      yield put(submitTrainingSuccess(response.data));
    } else {
      yield put(submitTrainingFail(response.message));
    }
  } catch (error) {
    yield put(submitTrainingFail(error));
  }
}

function* onGetTrainingAnswer({ payload: trainingId }) {
  try {
    const response = yield call(getTrainingAnswer, trainingId);
    if (response.success) {
      yield put(getTrainingAnswerSuccess(response.data));
    } else {
      yield put(getTrainingAnswerFail(response.message));
    }
  } catch (error) {
    yield put(getTrainingAnswerFail(error));
  }
}

function* trainingsSaga() {
  yield takeEvery(GET_TRAININGS, fetchTrainings);
  yield takeEvery(GET_TRAINING_DETAIL, fetchTrainingDetail);
  yield takeEvery(ADD_NEW_TRAINING, onAddNewTraining);
  yield takeEvery(UPDATE_TRAINING, onUpdateTraining);
  yield takeEvery(DELETE_TRAINING, onDeleteTraining);
  yield takeEvery(SUBMIT_TRAINING, onSubmitTraining);
  yield takeEvery(GET_TRAINING_ANSWER, onGetTrainingAnswer);
}

export default trainingsSaga;
