import {
  GET_VENUES,
  GET_VENUES_FAIL,
  GET_VENUES_SUCCESS,
  GET_VENUE_DETAIL,
  GET_VENUE_DETAIL_FAIL,
  GET_VENUE_DETAIL_SUCCESS,
  ADD_NEW_VENUE,
  ADD_VENUE_SUCCESS,
  ADD_VENUE_FAIL,
  UPDATE_VENUE,
  UPDATE_VENUE_SUCCESS,
  UPDATE_VENUE_FAIL,
  DELETE_VENUE,
  DELETE_VENUE_SUCCESS,
  DELETE_VENUE_FAIL,
} from "./actionTypes";

export const getVenues = () => ({
  type: GET_VENUES,
});

export const getVenuesSuccess = venues => ({
  type: GET_VENUES_SUCCESS,
  payload: venues,
});

export const getVenuesFail = error => ({
  type: GET_VENUES_FAIL,
  payload: error,
});

export const getVenueDetail = venueId => ({
  type: GET_VENUE_DETAIL,
  venueId,
});

export const getVenueDetailSuccess = venueDetails => ({
  type: GET_VENUE_DETAIL_SUCCESS,
  payload: venueDetails,
});

export const getVenueDetailFail = error => ({
  type: GET_VENUE_DETAIL_FAIL,
  payload: error,
});

export const addNewVenue = venue => ({
  type: ADD_NEW_VENUE,
  payload: venue,
});

export const addVenueSuccess = venue => ({
  type: ADD_VENUE_SUCCESS,
  payload: venue,
});

export const addVenueFail = error => ({
  type: ADD_VENUE_FAIL,
  payload: error,
});

export const updateVenue = venue => ({
  type: UPDATE_VENUE,
  payload: venue,
});

export const updateVenueSuccess = venue => ({
  type: UPDATE_VENUE_SUCCESS,
  payload: venue,
});

export const updateVenueFail = error => ({
  type: UPDATE_VENUE_FAIL,
  payload: error,
});

export const deleteVenue = venue => ({
  type: DELETE_VENUE,
  payload: venue,
});

export const deleteVenueSuccess = venue => ({
  type: DELETE_VENUE_SUCCESS,
  payload: venue,
});

export const deleteVenueFail = error => ({
  type: DELETE_VENUE_FAIL,
  payload: error,
});
