import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//E-commerce
import ecommerce from "./e-commerce/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//crypto
import crypto from "./crypto/reducer";

//invoices
import invoices from "./invoices/reducer";

//projects
import projects from "./projects/reducer";

//tasks
import Tasks from "./tasks/reducer";

//contacts
import contacts from "./contacts/reducer";

//mails
import mails from "./mails/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

//Brands
import Brands from "./brands/reducer";

//Clients
import Clients from "./clients/reducer";

//Roadshows
import Roadshows from "./roadshows/reducer";

//Events
import Events from "./events/reducer";

//Locations
import Locations from "./locations/reducer";

//Surveys
import Surveys from "./surveys/reducer";

//Venue
import Venues from "./venues/reducer";

//Trainings
import Trainings from "./trainings/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  projects,
  Tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  Brands,
  Clients,
  Roadshows,
  Events,
  Locations,
  Surveys,
  Venues,
  Trainings,
});

export default rootReducer;
