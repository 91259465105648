import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

// Login Redux States
import {
  GET_CURRENT_USER,
  LOGIN_USER,
  LOGOUT_USER,
  RESET_PASSWORD,
  SOCIAL_LOGIN,
} from "./actionTypes";
import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  getCurrentUserSuccess,
  resetPasswordSuccess,
  resetPasswordFail,
} from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { postSocialLogin } from "../../../helpers/fakebackend_helper";
import { getCurrentUser, postLogin, postResetPwd } from "helpers/Api/auth";
import guard from "helpers/jwt-token-access/apiGuard";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
      guard: user.guard ?? "admin",
    });
    if (response.success) {
      localStorage.setItem(
        "authUser",
        JSON.stringify({
          ...response.data.user,
          guard: response.data.token.guard,
          accessToken: response.data.token.access_token,
        })
      );
      yield put(loginSuccess(response.data.user));
      window.location.reload();
    } else {
      yield put(apiError(response.message));
    }
  } catch (error) {
    console.log("LoginUserError", error);
    yield put(apiError(error.data?.message));
  }
}

function* currentUser() {
  try {
    const response = yield call(getCurrentUser);
    if (response.success) {
      yield put(getCurrentUserSuccess(response.data));
    } else {
      yield put(apiError(response.message));
    }
  } catch (error) {
    yield put(apiError(error.data?.message));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    if (guard === "admin") {
      history.push("/login");
    } else {
      history.push("/login-user");
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* resetPassword({ payload }) {
  try {
    const response = yield call(postResetPwd, payload);
    if (response.success) {
      yield put(resetPasswordSuccess(response.message));
    } else {
      yield put(resetPasswordFail(response.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.socialLoginUser, data, type);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(GET_CURRENT_USER, currentUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export default authSaga;
