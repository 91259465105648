import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Row, Col, Label } from "reactstrap";
import { addNewUser, updateUser } from "store/actions";

class UserForm extends Component {
  constructor() {
    super();
    this.state = {};

    this.form = createRef();
  }

  componentDidMount() {
    const { user } = this.props;
    console.log(user);
  }

  componentDidUpdate(prevProps) {
    this.form.current.setErrors(this.props.error?.data?.errors ?? {});

    if (!this.props.error?.data) {
      window.location.reload();
    }
  }

  render() {
    const { user, onAddNewUser, onUpdateUser } = this.props;

    return (
      <Formik
        innerRef={this.form}
        enableReinitialize={true}
        initialValues={{
          name: (user && user.name) || "",
          designation: (user && user.designation) || "",
          email: (user && user.email) || "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Please Enter Your Name"),
          designation: Yup.string().required("Please Enter Your Designation"),
          email: Yup.string().required("Please Enter Your Email"),
        })}
        onSubmit={values => {
          if (user?.id) {
            const updateUser = {
              id: user.id,
              name: values.name,
              designation: values.designation,
              email: values.email,
            };

            // update user
            onUpdateUser(updateUser);
          } else {
            const newUser = {
              name: values["name"],
              designation: values["designation"],
              email: values["email"],
            };
            // save new user
            onAddNewUser(newUser);
          }
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">Name</Label>
                  <Field
                    name="name"
                    type="text"
                    className={
                      "form-control" +
                      (errors.name && touched.name ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Designation</Label>
                  <Field
                    name="designation"
                    type="text"
                    className={
                      "form-control" +
                      (errors.designation && touched.designation
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="designation"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Email</Label>
                  <Field
                    name="email"
                    type="text"
                    className={
                      "form-control" +
                      (errors.email && touched.email ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

UserForm.propTypes = {
  user: PropTypes.array,
  error: PropTypes.any,
  onAddNewUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
  onDone: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  error: contacts.error,
});

const mapDispatchToProps = dispatch => ({
  onAddNewUser: user => dispatch(addNewUser(user)),
  onUpdateUser: user => dispatch(updateUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
