import { call, put, takeEvery } from "redux-saga/effects";

// Survey Redux States
import {
  GET_SURVEYS,
  GET_SURVEY_DETAIL,
  ADD_NEW_SURVEY,
  DELETE_SURVEY,
  UPDATE_SURVEY,
  ANSWER_SURVEY,
  GET_SURVEY_ANSWER,
} from "./actionTypes";
import {
  getSurveysSuccess,
  getSurveysFail,
  getSurveyDetailSuccess,
  getSurveyDetailFail,
  addSurveyFail,
  addSurveySuccess,
  updateSurveySuccess,
  updateSurveyFail,
  deleteSurveySuccess,
  deleteSurveyFail,
  answerSurveySuccess,
  answerSurveyFail,
  getSurveyAnswerSuccess,
  getSurveyAnswerFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getSurveys,
  getSurveyDetails,
  addNewSurvey,
  updateSurvey,
  deleteSurvey,
  answerSurvey,
  getSurveyAnswer,
} from "helpers/Api/surveys";

function* fetchSurveys() {
  try {
    const response = yield call(getSurveys);
    if (response.success) {
      yield put(getSurveysSuccess(response.data));
    } else {
      yield put(getSurveysFail(response.message));
    }
  } catch (error) {
    yield put(getSurveysFail(error));
  }
}

function* fetchSurveyDetail({ surveyId }) {
  try {
    if (surveyId === undefined) {
      yield put(getSurveyDetailSuccess(null));
    } else {
      const response = yield call(getSurveyDetails, surveyId);
      if (response.success) {
        yield put(getSurveyDetailSuccess(response.data));
      } else {
        yield put(getSurveyDetailFail(response.message));
      }
    }
  } catch (error) {
    yield put(getSurveyDetailFail(error));
  }
}

function* onUpdateSurvey({ payload: survey }) {
  try {
    const response = yield call(updateSurvey, survey);
    if (response.success) {
      yield put(updateSurveySuccess(response.data));
    } else {
      yield put(updateSurveyFail(response.message));
    }
  } catch (error) {
    yield put(updateSurveyFail(error));
  }
}

function* onDeleteSurvey({ payload: survey }) {
  try {
    const response = yield call(deleteSurvey, survey);
    if (response.success) {
      yield put(deleteSurveySuccess(response.data));
    } else {
      yield put(deleteSurveyFail(response.message));
    }
  } catch (error) {
    yield put(deleteSurveyFail(error));
  }
}

function* onAddNewSurvey({ payload: survey }) {
  try {
    const response = yield call(addNewSurvey, survey);
    if (response.success) {
      yield put(addSurveySuccess(response.data));
    } else {
      yield put(addSurveyFail(response.message));
    }
  } catch (error) {
    yield put(addSurveyFail(error));
  }
}

function* onAnswerSurvey({ payload: answers }) {
  try {
    const response = yield call(answerSurvey, answers);
    if (response.success) {
      yield put(answerSurveySuccess(response.data));
    } else {
      yield put(answerSurveyFail(response.message));
    }
  } catch (error) {
    yield put(answerSurveyFail(error));
  }
}

function* onGetSurveyAnswer({ payload: surveyId }) {
  try {
    const response = yield call(getSurveyAnswer, surveyId);
    if (response.success) {
      yield put(getSurveyAnswerSuccess(response.data));
    } else {
      yield put(getSurveyAnswerFail(response.message));
    }
  } catch (error) {
    yield put(getSurveyAnswerFail(error));
  }
}

function* surveysSaga() {
  yield takeEvery(GET_SURVEYS, fetchSurveys);
  yield takeEvery(GET_SURVEY_DETAIL, fetchSurveyDetail);
  yield takeEvery(ADD_NEW_SURVEY, onAddNewSurvey);
  yield takeEvery(UPDATE_SURVEY, onUpdateSurvey);
  yield takeEvery(DELETE_SURVEY, onDeleteSurvey);
  yield takeEvery(ANSWER_SURVEY, onAnswerSurvey);
  yield takeEvery(GET_SURVEY_ANSWER, onGetSurveyAnswer);
}

export default surveysSaga;
