import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";

//css
import "@fullcalendar/bootstrap/main.css";

import { getProjectDetail } from "store/actions";

class ProjectCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getCalendarEvents = () => {
    const { projectDetail } = this.props;
    let data = [];
    if (projectDetail?.tour_plan?.length > 0) {
      data = [];
      projectDetail.tour_plan.forEach(roadshow => {
        data.push({
          id: `roadshow-${roadshow.id}`,
          title: roadshow.name,
          start: roadshow.start_date,
          end: roadshow.end_date,
          className: "bg-primary border-primary",
        });
        roadshow.events.forEach(event => {
          data.push({
            id: `event-${event.id}`,
            title: event.name,
            start: event.start_date,
            end: event.end_date,
            className: "bg-info border-info",
          });
          event.locations.forEach(location => {
            data.push({
              id: `location-${location.id}`,
              title: location.name,
              start: location.start_date,
              end: location.end_date,
              className: "bg-warning border-warning",
            });
            location.tasks?.forEach(task =>
              data.push({
                id: `task-${task.id}`,
                title: task.name,
                start: task.start_date,
                end: task.end_date,
                className: "bg-success border-success",
              })
            );
          });
        });
      });
    }
    return data;
  };

  render = () => (
    <FullCalendar
      // ref={this.calendarComponentRef}
      plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
      handleWindowResize={true}
      themeSystem="bootstrap"
      headerToolbar={{
        left: "prev today next",
        center: "title",
        right: "dayGridMonth,dayGridWeek,dayGridDay",
      }}
      events={this.getCalendarEvents()}
      editable={false}
      droppable={false}
      selectable={false}
      // dateClick={this.handleDateClick}
      // eventClick={this.handleEventClick}
      // drop={this.onDrop}
    />
  );
}

ProjectCalendar.propTypes = {
  match: PropTypes.object,
  projectDetail: PropTypes.object,
  onGetProjectDetails: PropTypes.func,
};

const mapStateToProps = ({ projects }) => ({
  projectDetail: projects.projectDetail,
});

const mapDispatchToProps = dispatch => ({
  onGetProjectDetails: id => dispatch(getProjectDetail(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectCalendar));
