import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import images from "assets/images";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getTrainings,
  addNewTraining,
  updateTraining,
  deleteTraining,
} from "store/trainings/actions";

import { isEmpty, size, map, values } from "lodash";
import authHeader from "helpers/jwt-token-access/auth-token-header";
import moment from "moment";

class TrainingsList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      trainings: [],
      training: "",
      modal: false,
      deleteModal: false,
      trainingListColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
        },
        {
          text: "Date",
          dataField: "created_at",
          sort: true,
          formatter: cellContent =>
            moment(cellContent).format("DD/MM/YYYY HH:mm"),
        },
        {
          text: "Title",
          dataField: "title",
          sort: true,
        },
        {
          text: "File",
          dataField: "file.name",
          sort: true,
          formatter: (cellContent, training) => (
            <a
              target="blank"
              href={training.file.link}
              className="d-block text-truncate"
              style={{ maxWidth: 150 }}
            >
              {training.file.name}
            </a>
          ),
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, training) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => this.handleTrainingClick(training)}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => this.onClickDelete(training)}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
    };
    this.handleTrainingClick = this.handleTrainingClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleTrainingClicks = this.handleTrainingClicks.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  componentDidMount() {
    const { trainings, onGetTrainings } = this.props;
    if (trainings && !trainings.length) {
      onGetTrainings();
    }
    this.setState({ trainings });
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleTrainingClicks = () => {
    this.setState({ training: "", isEdit: false });
    this.toggle();
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { trainings } = this.props;
    if (!isEmpty(trainings) && size(prevProps.trainings) !== size(trainings)) {
      this.setState({ trainings: {}, isEdit: false });
    }
  }

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  /* Insert,Update Delete data */

  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };

  onClickDelete = trainings => {
    this.setState({ trainings: trainings });
    this.setState({ deleteModal: true });
  };

  handleDeleteTraining = () => {
    const { onDeleteTraining } = this.props;
    const { trainings } = this.state;
    if (trainings.id !== undefined) {
      onDeleteTraining(trainings.id);
      this.setState({ deleteModal: false });
    }
  };

  handleTrainingClick = arg => {
    const training = arg;

    this.setState({
      training: {
        id: training.id,
        title: training.title,
        file: training.file?.link,
      },
      isEdit: true,
    });

    this.toggle();
  };

  render() {
    // const { trainings } = this.state
    const { SearchBar } = Search;

    const { trainings } = this.props;

    const { isEdit, deleteModal } = this.state;

    const { onAddNewTraining, onUpdateTraining } = this.props;
    const { selectedTraining } = this.state;
    const training = this.state.training;

    const pageOptions = {
      sizePerPage: 10,
      totalSize: trainings.length, // replace later with size(trainings),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    const selectRow = {
      mode: "checkbox",
    };

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteTraining}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <MetaTags>
            <title>Trainings List | AD&amp;VISION - Event Tool</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Trainings" breadcrumbItem="Trainings List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.trainingListColumns}
                      data={trainings}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.trainingListColumns}
                          data={trainings}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      color="primary"
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={this.handleTrainingClicks}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create New Training
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      selectRow={selectRow}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />

                                    <Modal
                                      isOpen={this.state.modal}
                                      className={this.props.className}
                                    >
                                      <ModalHeader
                                        toggle={this.toggle}
                                        tag="h4"
                                      >
                                        {!!isEdit
                                          ? "Edit Training"
                                          : "Add Training"}
                                      </ModalHeader>
                                      <ModalBody>
                                        <Formik
                                          enableReinitialize={true}
                                          initialValues={{
                                            title:
                                              (training && training.title) ||
                                              "",
                                            file:
                                              (training && training.file) || "",
                                          }}
                                          validationSchema={Yup.object().shape({
                                            title: Yup.string().required(
                                              "Please Enter Training Title"
                                            ),
                                            file: Yup.string().required(
                                              "File is required"
                                            ),
                                          })}
                                          onSubmit={async values => {
                                            let newTraining = {
                                              id: training?.id,
                                              title: values.title,
                                            };
                                            const filesData = new FormData();
                                            if (this.state.file) {
                                              filesData.append(
                                                "files[]",
                                                this.state.file
                                              );

                                              const res = await fetch(
                                                `${process.env.REACT_APP_API_URL}/v1/upload`,
                                                {
                                                  method: "POST",
                                                  body: filesData,
                                                  headers: authHeader(),
                                                }
                                              );
                                              const files = await res.json();
                                              newTraining.data = {
                                                file: files[0],
                                              };
                                            }
                                            if (isEdit) {
                                              // update training
                                              onUpdateTraining(newTraining);
                                            } else {
                                              // save new training
                                              onAddNewTraining(newTraining);
                                            }
                                            this.setState({
                                              selectedTraining: null,
                                            });
                                            this.toggle();
                                          }}
                                        >
                                          {({
                                            errors,
                                            handleChange,
                                            touched,
                                          }) => (
                                            <Form>
                                              <Row>
                                                <Col className="col-12">
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      Title
                                                    </Label>
                                                    <Field
                                                      name="title"
                                                      type="text"
                                                      className={
                                                        "form-control" +
                                                        (errors.title &&
                                                        touched.title
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="title"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  {!isEdit && (
                                                    <div className="mb-3">
                                                      <Label className="form-label">
                                                        File
                                                      </Label>
                                                      <Field
                                                        name="file"
                                                        type="file"
                                                        className={
                                                          "form-control" +
                                                          (errors.file &&
                                                          touched.file
                                                            ? " is-invalid"
                                                            : "")
                                                        }
                                                        onChange={e => {
                                                          handleChange(e);
                                                          this.setState({
                                                            file: e
                                                              .currentTarget
                                                              .files[0],
                                                          });
                                                        }}
                                                      />
                                                      <ErrorMessage
                                                        name="file"
                                                        component="div"
                                                        className="invalid-feedback"
                                                      />
                                                    </div>
                                                  )}
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col>
                                                  <div className="text-end">
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success save-training"
                                                    >
                                                      Save
                                                    </button>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Form>
                                          )}
                                        </Formik>
                                      </ModalBody>
                                    </Modal>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

TrainingsList.propTypes = {
  trainings: PropTypes.array,
  className: PropTypes.any,
  onGetTrainings: PropTypes.func,
  onAddNewTraining: PropTypes.func,
  onDeleteTraining: PropTypes.func,
  onUpdateTraining: PropTypes.func,
};

const mapStateToProps = ({ Trainings }) => ({
  trainings: Trainings.trainings,
});

const mapDispatchToProps = dispatch => ({
  onGetTrainings: () => dispatch(getTrainings()),
  onAddNewTraining: training => dispatch(addNewTraining(training)),
  onUpdateTraining: training => dispatch(updateTraining(training)),
  onDeleteTraining: trainingId => dispatch(deleteTraining(trainingId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TrainingsList));
