/* CLIENTS */
export const GET_CLIENTS = "GET_CLIENTS"
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS"
export const GET_CLIENTS_FAIL = "GET_CLIENTS_FAIL"

/* CLIENTS DETAIL*/
export const GET_CLIENT_DETAIL = "GET_CLIENT_DETAIL"
export const GET_CLIENT_DETAIL_SUCCESS = "GET_CLIENT_DETAIL_SUCCESS"
export const GET_CLIENT_DETAIL_FAIL = "GET_CLIENT_DETAIL_FAIL"

export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT"
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS"
export const ADD_CLIENT_FAIL = "ADD_CLIENT_FAIL"

export const UPDATE_CLIENT = "UPDATE_CLIENT"
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS"
export const UPDATE_CLIENT_FAIL = "UPDATE_CLIENT_FAIL"

export const DELETE_CLIENT = "DELETE_CLIENT"
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS"
export const DELETE_CLIENT_FAIL = "DELETE_CLIENT_FAIL"