import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { map } from "lodash";
import { Badge, Card, CardBody, Col, Row } from "reactstrap";
import images from "assets/images";
import companies from "assets/images/companies";

class CardProject extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        {map(this.props.projects, (project, dkey) => (
          <Col xl="4" sm="6" key={"__projectcd_" + dkey}>
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="avatar-md me-4">
                    <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
                      <img src={companies[project.img]} alt="" height="30" />
                    </span>
                  </div>

                  <div className="flex-grow-1 overflow-hidden">
                    <h5 className="text-truncate font-size-15">
                      <Link
                        to={`/projects-overview/${project.id}`}
                        className="text-dark"
                      >
                        {project.name}
                      </Link>
                    </h5>
                    <p className="text-muted mb-4">
                      {project.description &&
                        project.description
                          .replace(/(<([^>]+)>)/gi, "")
                          .substr(0, 30)}
                      &hellip;
                    </p>
                    <Row className="mb-4">
                      <Col className="text-muted">
                        Client: {project.client?.name}
                      </Col>
                      <Col className="text-muted">
                        Brand: {project.brand?.name}
                      </Col>
                    </Row>
                    <div className="mb-4 text-muted">
                      <i className="bx bx-calendar me-1" /> {project.start_date}{" "}
                      - {project.end_date}
                    </div>
                    <div className="avatar-group">
                      {project.team &&
                        project.team.map((team, secondkey) =>
                          team.img || team.img !== null ? (
                            <React.Fragment key={"_teamaa__" + secondkey}>
                              <div className="avatar-group-item">
                                <Link
                                  to={`/contacts-profile/${team.id}`}
                                  className="d-inline-block"
                                  id={"member" + team.id}
                                  key={"_team_" + secondkey}
                                >
                                  <img
                                    src={images[team.img]}
                                    className="rounded-circle avatar-xs"
                                    alt=""
                                  />
                                </Link>
                              </div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment key={"__teama_" + secondkey}>
                              <div className="avatar-group-item">
                                <Link
                                  to={`/contacts-profile/${team.id}`}
                                  className="d-inline-block"
                                  id={"member" + team.id}
                                >
                                  <div className="avatar-xs">
                                    <span
                                      className={
                                        "avatar-title rounded-circle bg-" +
                                        team.color +
                                        " text-white font-size-16"
                                      }
                                    >
                                      {team.name.substr(0, 1)}
                                    </span>
                                  </div>
                                </Link>
                              </div>
                            </React.Fragment>
                          )
                        )}
                    </div>
                  </div>
                </div>
              </CardBody>
              <div className="px-4 py-3 border-top">
                <Row className="justify-content-between">
                  <Col xs="auto">
                    <Badge
                      color={project.color}
                      className={"bg-" + project.color}
                    >
                      {project.kind}
                    </Badge>
                  </Col>
                  <Col xs="auto">LKS#{project.lks}</Col>
                  <Col xs="auto">KVA#{project.kva}</Col>
                  <Col xs="auto">
                    <Badge color={"project-status-" + project.status}>
                      {project.status_text}
                    </Badge>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

CardProject.propTypes = {
  projects: PropTypes.array,
};

export default CardProject;
