import React, { Component } from "react";
import PropTypes from "prop-types";
import Board from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
import { Col, Row } from "reactstrap";
import CardTaskBox from "./card-task-box";
import RenderCardTitle from "./render-card-title";
import { connect } from "react-redux";
import { updateTask } from "store/actions";

class ControlledBoard extends Component {
  render() {
    const { locationDetail, board, onUpdateTask, handleAddNew } = this.props;

    return (
      <React.Fragment>
        <Row className="mb-4">
          <Col>
            <Board
              disableColumnDrag
              renderColumnHeader={({ id, title }) => (
                <RenderCardTitle
                  locationId={locationDetail.id}
                  status={id}
                  title={title}
                  handleAddNew={handleAddNew}
                />
              )}
              renderCard={(data, { dragging }) => (
                <CardTaskBox
                  data={data}
                  dragging={dragging}
                  syncData={handleAddNew}
                >
                  {data}
                </CardTaskBox>
              )}
              onCardDragEnd={(task, from, to) => {
                console.log(to);
                if (to) {
                  task.status = to.toColumnId;
                  onUpdateTask(task);
                  handleAddNew();
                }
              }}
            >
              {board}
            </Board>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

ControlledBoard.propTypes = {
  board: PropTypes.any,
  locationDetail: PropTypes.object,
  onUpdateTask: PropTypes.func,
  handleAddNew: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  onUpdateTask: task => dispatch(updateTask(task)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ControlledBoard);
