import {
  GET_ROADSHOWS_FAIL,
  GET_ROADSHOWS_SUCCESS,
  GET_ROADSHOW_DETAIL_FAIL,
  GET_ROADSHOW_DETAIL_SUCCESS,
  ADD_ROADSHOW_SUCCESS,
  ADD_ROADSHOW_FAIL,
  UPDATE_ROADSHOW_SUCCESS,
  UPDATE_ROADSHOW_FAIL,
  DELETE_ROADSHOW_SUCCESS,
  DELETE_ROADSHOW_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  roadshows: [],
  roadshowDetail: {},
  error: {},
};

const Roadshows = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROADSHOWS_SUCCESS:
      return {
        ...state,
        roadshows: action.payload,
      };

    case GET_ROADSHOWS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_ROADSHOW_DETAIL_SUCCESS:
      return {
        ...state,
        roadshowDetail: action.payload,
      };

    case GET_ROADSHOW_DETAIL_FAIL:
      return {
        ...state,
        roadshowDetail: null,
        error: action.payload,
      };

    case ADD_ROADSHOW_SUCCESS:
      return {
        ...state,
        roadshows: [...state.roadshows, action.payload],
      };

    case ADD_ROADSHOW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_ROADSHOW_SUCCESS:
      return {
        ...state,
        roadshows: state.roadshows.map(roadshow =>
          roadshow.id.toString() === action.payload.id.toString()
            ? { roadshow, ...action.payload }
            : roadshow
        ),
      };

    case UPDATE_ROADSHOW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_ROADSHOW_SUCCESS:
      return {
        ...state,
        roadshows: state.roadshows.filter(
          roadshow => roadshow.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_ROADSHOW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Roadshows;
