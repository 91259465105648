import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";
// import toastr from "toastr";
// import "toastr/build/toastr.min.css";

//pass new generated access token here
const token = accessToken;

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (error?.response.status === 401) {
      // var title = "";
      // var message = error.response.data;

      // toastr.warning(message, title);

      // const obj = JSON.parse(localStorage.getItem("authUser"));
      // if (obj) {
      //   localStorage.removeItem("authUser");
      //   window.location.reload();
      // }
    }
    return Promise.reject(error?.response);
  }
);

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
