import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Col,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import { connect } from "react-redux";
import {
  addNewLocation,
  deleteLocation,
  getLocationDetail,
  getLocations,
  updateLocation,
} from "store/actions";
import DeleteModal from "components/Common/DeleteModal";
import { statuses } from "helpers/constants";

class LocationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
      modal: false,
      modalType: "",
    };
  }

  componentDidMount() {
    const { id, locations, onGetLocations, onGetLocationDetail } = this.props;
    if (locations.length === 0) {
      onGetLocations();
    }
    onGetLocationDetail(id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(
      "LocationForm Update",
      prevProps.locationDetail?.updated_at,
      this.props.locationDetail?.updated_at
    );
    if (
      !_.isEmpty(prevProps.locationDetail) &&
      (prevProps.locationDetail?.updated_at !==
        this.props.locationDetail?.updated_at ||
        _.isEmpty(this.props.locationDetail))
    ) {
      this.props.onDone();
    }
  }

  onClickDelete = e => {
    this.setState({ deleteModal: true });
  };

  handleDelete = () => {
    const { locationDetail, onDeleteLocation, onDone } = this.props;
    if (locationDetail?.id) {
      onDeleteLocation(locationDetail?.id);
    }
  };

  render() {
    const {
      locations,
      onDone,
      locationDetail,
      // projectDetail,
      eventDetail,
      onAddNewLocation,
      onUpdateLocation,
    } = this.props;

    const types = [
      ...new Set(locations.map(e => `${e.type}`.toLowerCase())),
    ].map(e => ({
      value: e.toLowerCase(),
      name: e.toUpperCase(),
    }));

    return (
      <>
        <DeleteModal
          show={this.state.deleteModal}
          onDeleteClick={this.handleDelete}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: locationDetail?.id,
            event_id: eventDetail?.id,
            status: locationDetail?.status ?? "",
            type: locationDetail?.type ?? "",
            name: locationDetail?.name ?? "",
            street: locationDetail?.street ?? "",
            building: locationDetail?.building ?? "",
            city: locationDetail?.city ?? "",
            postcode: locationDetail?.postcode ?? "",
            budget: locationDetail?.budget ?? 0,
            cost: locationDetail?.cost ?? 0,
            start_date: locationDetail?.start_date ?? "",
            end_date: locationDetail?.end_date ?? "",
            notes: locationDetail?.notes ?? "",
            contacts: locationDetail?.contacts ?? "",
            desired_appointment: locationDetail?.desired_appointment ?? "",
          }}
          validationSchema={Yup.object().shape({
            status: Yup.number().integer().min(0).max(3).required(),
            type: Yup.string().required(),
            name: Yup.string().required(),
            street: Yup.string().required(),
            building: Yup.string().required(),
            city: Yup.string().required(),
            postcode: Yup.string().required(),
            budget: Yup.number(),
            cost: Yup.number(),
            start_date: Yup.date().required(),
            end_date: Yup.date().required(),
            notes: Yup.string(),
          })}
          onSubmit={values => {
            if (values.id) {
              onUpdateLocation(values);
            } else {
              onAddNewLocation(values);
            }
          }}
        >
          {({ values, errors, setFieldValue, touched, handleSubmit }) => (
            <Form>
              <Row>
                <Col className="col-12">
                  <div className="mb-3">
                    <Label className="form-label">Status</Label>
                    <Select
                      options={statuses}
                      value={statuses.find(s => s.value === values.status)}
                      getOptionValue={option => option.value}
                      getOptionLabel={option => option.name}
                      onChange={newValue => {
                        setFieldValue("status", newValue.value);
                      }}
                      className={
                        "form-control" +
                        (errors.status && touched.status ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="status"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Type</Label>
                    <CreatableSelect
                      options={types}
                      value={types.find(s => s.value === values.type)}
                      getOptionValue={option => option.value}
                      getOptionLabel={option =>
                        option.__isNew__ ? option.label : option.name
                      }
                      onChange={newValue => {
                        setFieldValue("type", newValue.value);
                      }}
                      onCreateOption={inputValue => {
                        types.push({
                          value: inputValue.toLowerCase(),
                          name: inputValue.toUpperCase(),
                        });
                        setFieldValue("type", inputValue);
                      }}
                      className={
                        "form-control" +
                        (errors.type && touched.type ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="type"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Name</Label>
                    <Field
                      name="name"
                      type="text"
                      className={
                        "form-control" +
                        (errors.name && touched.name ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <Row className="mb-3">
                    <Col md="6">
                      <Label htmlFor="budget" className="col-form-label">
                        Budget
                      </Label>
                      <InputGroup>
                        <InputGroupText>EUR</InputGroupText>
                        <Field
                          name="budget"
                          type="number"
                          min="0"
                          className={
                            "form-control mb-0" +
                            (errors.budget && touched.budget
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="budget"
                          component="div"
                          className="invalid-feedback"
                        />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <Label htmlFor="cost" className="col-form-label">
                        Cost
                      </Label>
                      <InputGroup>
                        <InputGroupText>EUR</InputGroupText>
                        <Field
                          name="cost"
                          type="number"
                          min="0"
                          className={
                            "form-control mb-0" +
                            (errors.cost && touched.cost ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="cost"
                          component="div"
                          className="invalid-feedback"
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">Start Date</Label>
                        <Flatpickr
                          placeholder="D/M/Y"
                          options={{
                            inline: false,
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "Y-m-d",
                          }}
                          name="start_date"
                          value={values.start_date}
                          onChange={([value]) =>
                            setFieldValue(
                              "start_date",
                              moment(value).format("YYYY-MM-DD")
                            )
                          }
                          className={
                            "form-control mb-0" +
                            (errors.start_date && touched.start_date
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="start_date"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label className="form-label">End Date</Label>
                        <Flatpickr
                          placeholder="D/M/Y"
                          options={{
                            inline: false,
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "Y-m-d H:i",
                          }}
                          name="end_date"
                          value={values.end_date}
                          onChange={([value]) =>
                            setFieldValue(
                              "end_date",
                              moment(value).format("YYYY-MM-DD")
                            )
                          }
                          className={
                            "form-control mb-0" +
                            (errors.end_date && touched.end_date
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="end_date"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </Col>
                  </Row>
                  {/* <div className="mb-3">
                    <Label className="form-label">Team</Label>
                    <Select
                      name="team"
                      isClearable
                      isMulti
                      value={values.team}
                      options={projectDetail.team}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      onChange={(newValue, actionMeta) => {
                        setFieldValue("team", newValue);
                        console.log(newValue, actionMeta);
                      }}
                    />
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div> */}
                  <div className="mb-3">
                    <Label className="form-label">Street</Label>
                    <Field
                      name="street"
                      type="text"
                      className={
                        "form-control" +
                        (errors.street && touched.street ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="street"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Building No. #</Label>
                    <Field
                      name="building"
                      type="text"
                      className={
                        "form-control" +
                        (errors.building && touched.building
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="building"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">City</Label>
                    <Field
                      name="city"
                      type="text"
                      className={
                        "form-control" +
                        (errors.city && touched.city ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Postcode</Label>
                    <Field
                      name="postcode"
                      type="text"
                      className={
                        "form-control" +
                        (errors.postcode && touched.postcode
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="postcode"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Notes</Label>
                    <Field
                      name="notes"
                      as="textarea"
                      className={
                        "form-control" +
                        (errors.notes && touched.notes ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="notes"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  {/* <div className="mb-3">
                    <Label className="form-label">Contacts</Label>
                    <Select
                      name="contacts"
                      isClearable
                      isMulti
                      value={values.contacts}
                      options={projectDetail.team}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      onChange={(newValue, actionMeta) => {
                        setFieldValue("contacts", newValue);
                        console.log(newValue, actionMeta);
                      }}
                    />
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div> */}
                  <div className="mb-3">
                    <Label className="form-label">Desired Appointment</Label>
                    <Field
                      name="desired_appointment"
                      type="text"
                      className={
                        "form-control" +
                        (errors.city && touched.city ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <ButtonGroup>
                      <Button type="submit" color="success">
                        Save
                      </Button>
                      {locationDetail?.id && (
                        <>
                          <Button
                            onClick={e => {
                              setFieldValue("id", "");
                              handleSubmit(e);
                            }}
                            color="info"
                          >
                            Duplicate
                          </Button>
                          <Button onClick={this.onClickDelete} color="danger">
                            Delete
                          </Button>
                        </>
                      )}
                    </ButtonGroup>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

LocationForm.propTypes = {
  id: PropTypes.any,
  locations: PropTypes.array,
  locationDetail: PropTypes.object,
  projectDetail: PropTypes.object,
  eventDetail: PropTypes.object,
  onAddNewLocation: PropTypes.func,
  onDone: PropTypes.func,
  onGetLocationDetail: PropTypes.func,
  onUpdateLocation: PropTypes.func,
  onDeleteLocation: PropTypes.func,
  onGetLocations: PropTypes.func,
};

const mapStateToProps = ({ Locations }) => ({
  locations: Locations.locations,
  locationDetail: Locations.locationDetail,
});

const mapDispatchToProps = dispatch => ({
  onGetLocations: () => dispatch(getLocations()),
  onGetLocationDetail: locationId => dispatch(getLocationDetail(locationId)),
  onAddNewLocation: location => dispatch(addNewLocation(location)),
  onUpdateLocation: location => dispatch(updateLocation(location)),
  onDeleteLocation: id => dispatch(deleteLocation(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationForm);
