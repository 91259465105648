import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Button, Popconfirm, Row, Col, Icon, Upload } from "antd";
import Select from "react-select";
import { ExcelRenderer } from "react-excel-renderer";
import { EditableCell, EditableFormRow } from "utils/Editable";
import "antd/dist/antd.css";
import { addBulkUsers, importProjectDetails } from "store/actions";
import { connect } from "react-redux";
import StickyTable from "react-sticky-table-thead";
import { Form, Formik } from "formik";
import { getJsDateFromExcel } from "excel-date-to-js";
import moment from "moment";

class ExcelPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: null,
      headers: {},
      fileHeaders: [],
      cols: [],
      rows: [],
      errorMessage: null,
      columns: [
        ...props.columns,
        {
          title: "Action",
          dataIndex: "action",
          render: (text, record) =>
            this.state.rows.length >= 1 ? (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => this.handleDelete(record.key)}
              >
                <Icon
                  type="delete"
                  theme="filled"
                  style={{ color: "red", fontSize: "20px" }}
                />
              </Popconfirm>
            ) : null,
        },
      ],
    };
  }

  handleSave = row => {
    const newData = [...this.state.rows];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ rows: newData });
  };

  checkFile(file) {
    let errorMessage = "";
    if (!file || !file[0]) {
      return;
    }
    const isExcel =
      file[0].type === "application/vnd.ms-excel" ||
      file[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcel) {
      errorMessage = "You can only upload Excel file!";
    }
    const isLt2M = file[0].size / 1024 / 1024 < 2;
    if (!isLt2M) {
      errorMessage = "File must be smaller than 2MB!";
    }
    return errorMessage;
  }

  fileHandler = fileList => {
    let fileObj = fileList;
    if (!fileObj) {
      this.setState({
        errorMessage: "No file uploaded!",
      });
      return false;
    }
    if (
      !(
        fileObj.type === "application/vnd.ms-excel" ||
        fileObj.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    ) {
      this.setState({
        errorMessage: "Unknown file format. Only Excel files are uploaded!",
      });
      return false;
    }
    this.setState({ fileList });
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log("ExcelPage Error:", err);
      } else {
        if (Object.keys(this.state.headers).length === 0) {
          this.setState({ fileHeaders: resp.rows[0] });
          return;
        }
        let newRows = [];
        resp.rows.slice(1).map((row, index) => {
          if (row && row !== "undefined" && (row[0] || row[1] || row[2])) {
            let tmp = {
              key: index,
            };
            this.props.columns.forEach((col, colIndex) => {
              if (this.state.headers[col.dataIndex] !== undefined) {
                tmp[col.dataIndex] = row[this.state.headers[col.dataIndex]];
                try {
                  if (col.dataIndex.includes("date")) {
                    let d = getJsDateFromExcel(
                      row[this.state.headers[col.dataIndex]]
                    );
                    tmp[col.dataIndex] = moment(d).format("YYYY-MM-DDTHH:mm");
                  }
                } catch (error) {
                  console.log(
                    row[this.state.headers[col.dataIndex]],
                    "ERROR",
                    error
                  );
                }
              }
            });
            newRows.push(tmp);
          }
        });
        if (newRows.length === 0) {
          this.setState({
            errorMessage: "No data found in file!",
          });
          return false;
        } else {
          this.setState({
            cols: resp.cols,
            rows: newRows,
            errorMessage: null,
          });
        }
      }
    });
    return false;
  };

  handleDelete = key => {
    const rows = [...this.state.rows];
    this.setState({ rows: rows.filter(item => item.key !== key) });
  };

  handleAdd = () => {
    const { count, rows } = this.state;
    let newData = {
      key: count,
    };
    this.props.columns.forEach(col => {
      newData[col.dataIndex] = "";
    });
    this.setState({
      rows: [newData, ...rows],
      count: count + 1,
    });
  };

  handleSubmit = async () => {
    const {
      type,
      onAddBulkUsers,
      onImportProjectDetails,
      project_id: id,
    } = this.props;
    console.log("submitting: ", type, this.state.rows);
    switch (type) {
      case "contacts":
        onAddBulkUsers(this.state.rows);
        break;
      case "project":
        onImportProjectDetails({ id, ...this.state.rows });
        break;
    }
  };

  render() {
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const columns = this.state.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });

    return (
      <>
        <Row gutter={16} justify="space-between">
          {this.state.fileList === null && (
            <>
              <Col
                span={8}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "5%",
                }}
              >
                <Upload
                  name="file"
                  beforeUpload={this.fileHandler}
                  onRemove={() => this.setState({ rows: [] })}
                  multiple={false}
                >
                  <Button>
                    <Icon type="upload" /> Click to Upload Excel File
                  </Button>
                </Upload>
              </Col>
              <Col span={8}>
                {this.props.sample_file_url && (
                  <a
                    href={this.props.sample_file_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    Sample excel sheet
                  </a>
                )}
              </Col>
            </>
          )}
          <Col
            span={8}
            align="right"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {this.state.rows.length > 0 && (
              <>
                <Button
                  onClick={this.handleAdd}
                  size="large"
                  type="info"
                  style={{ marginBottom: 16 }}
                >
                  <Icon type="plus" />
                  Add a row
                </Button>{" "}
                <Button
                  onClick={this.handleSubmit}
                  size="large"
                  type="primary"
                  style={{ marginBottom: 16, marginLeft: 10 }}
                >
                  Submit Data
                </Button>
              </>
            )}
          </Col>
        </Row>
        {this.props.errors && (
          <p className="text-danger">{this.props.errors.message}</p>
        )}
        {this.props.project_error && (
          <p className="text-danger">{this.props.project_error.message}</p>
        )}
        {this.props.message && (
          <p className="alert alert-success">{this.props.message}</p>
        )}
        <div style={{ marginTop: 20 }}>
          {(Object.keys(this.state.headers).length === 0 &&
            this.state.fileHeaders.length > 0 && (
              <Formik
                // enableReinitialize={true}
                initialValues={{
                  headers: {},
                }}
                // validationSchema={Yup.object().shape({
                //   email: Yup.string().required("Please Enter Your Email"),
                //   password: Yup.string().required("Please Enter Valid Password"),
                // })}
                onSubmit={values => {
                  console.log(values.headers);
                  this.setState({ headers: values.headers }, () =>
                    this.fileHandler(this.state.fileList)
                  );
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    {columns.map(
                      col =>
                        col.dataIndex !== "action" && (
                          <Row key={col.dataIndex} className="mb-3">
                            <Col span={6}>{col.title}</Col>
                            <Col span={6}>
                              <Select
                                options={this.state.fileHeaders.map((h, i) => ({
                                  label: h,
                                  value: i,
                                }))}
                                onChange={option => {
                                  let tmp = values.headers;
                                  tmp[col.dataIndex] = option?.value;
                                  setFieldValue("headers", tmp);
                                }}
                              />
                            </Col>
                          </Row>
                        )
                    )}
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            )) || (
            <StickyTable>
              <Table
                components={components}
                rowClassName={() => "editable-row"}
                dataSource={this.state.rows}
                columns={columns}
              />
            </StickyTable>
          )}
        </div>
      </>
    );
  }
}

ExcelPage.propTypes = {
  sample_file_url: PropTypes.string,
  type: PropTypes.string,
  project_id: PropTypes.any,
  onAddBulkUsers: PropTypes.func,
  onImportProjectDetails: PropTypes.func,
  errors: PropTypes.any,
  project_error: PropTypes.any,
  message: PropTypes.string,
  columns: PropTypes.array,
};

ExcelPage.defaultProps = {
  sample_file_url: "",
  type: "",
  project_id: 0,
  columns: [],
};

const mapStateToProps = ({ contacts, projects }) => ({
  errors: contacts.error,
  project_error: projects.error,
  message: contacts.message,
});

const mapDispatchToProps = dispatch => ({
  onAddBulkUsers: users => dispatch(addBulkUsers(users)),
  onImportProjectDetails: values => dispatch(importProjectDetails(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExcelPage);
