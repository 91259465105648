/* TASKS */
export const GET_TASKS = "GET_TASKS";
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS";
export const GET_TASKS_FAIL = "GET_TASKS_FAIL";

/* TASKS DETAIL*/
export const GET_TASK_DETAIL = "GET_TASK_DETAIL";
export const GET_TASK_DETAIL_SUCCESS = "GET_TASK_DETAIL_SUCCESS";
export const GET_TASK_DETAIL_FAIL = "GET_TASK_DETAIL_FAIL";

export const ADD_NEW_TASK = "ADD_NEW_TASK";
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS";
export const ADD_TASK_FAIL = "ADD_TASK_FAIL";

export const UPDATE_TASK = "UPDATE_TASK";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_FAIL = "UPDATE_TASK_FAIL";

export const DELETE_TASK = "DELETE_TASK";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAIL = "DELETE_TASK_FAIL";
