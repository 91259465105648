import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";
import { currentUser } from "store/actions";
import { connect } from "react-redux";

class WelcomeComp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { onGetCurrentUser } = this.props;
    onGetCurrentUser();
  }

  render() {
    const { loggedInUser } = this.props;

    return (
      <React.Fragment>
        <Card className="overflow-hidden">
          <div className="bg-primary bg-soft">
            <Row>
              <Col xs="7">
                <div className="text-primary p-3">
                  <h5 className="text-primary">Welcome Back !</h5>
                  <p>AD&amp;VISION Event Management</p>
                </div>
              </Col>
              <Col xs="5" className="align-self-end">
                <img src={profileImg} alt="" className="img-fluid" />
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <Row>
              <Col sm="4">
                <div className="avatar-md profile-user-wid mb-4">
                  <img
                    src={avatar1}
                    alt=""
                    className="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 className="font-size-15 text-truncate">
                  {loggedInUser?.name}
                </h5>
                {/* <p className="text-muted mb-0 text-truncate">Event Manager</p> */}
              </Col>

              <Col sm="8">
                <div className="pt-4">
                  <Row>
                    <Col xs="6">
                      <h5 className="font-size-15">125</h5>
                      <p className="text-muted mb-0">Projects</p>
                    </Col>
                    <Col xs="6">
                      <h5 className="font-size-15">€1245</h5>
                      <p className="text-muted mb-0">Revenue</p>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    {/* <Link to="" className="btn btn-primary btn-sm">
                      View Profile <i className="mdi mdi-arrow-right ms-1" />
                    </Link> */}
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

WelcomeComp.propTypes = {
  loggedInUser: PropTypes.any,
  onGetCurrentUser: PropTypes.func,
};

const mapStateToProps = ({ Login }) => ({
  loggedInUser: Login.currentUser,
});

const mapDispatchToProps = dispatch => ({
  onGetCurrentUser: () => dispatch(currentUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeComp);
