import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// get roadshow
export const getRoadshows = () => get(url.GET_ROADSHOWS);

// get roadshow details
export const getRoadshowsDetails = id =>
  get(`${url.GET_ROADSHOW_DETAIL}/${id}`);

// add roadshow
export const addNewRoadshow = roadshow => post(url.ADD_NEW_ROADSHOW, roadshow);

// update roadshow
export const updateRoadshow = roadshow =>
  post(`${url.UPDATE_ROADSHOW}/${roadshow.id}`, roadshow);

// delete roadshow
export const deleteRoadshow = id => del(`${url.DELETE_ROADSHOW}/${id}`);
