import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Row, Col, Label, Button } from "reactstrap";
import {
  addNewVenue,
  getVenueDetail,
  getVenues,
  updateVenue,
} from "store/actions";

class VenueForm extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { id, onGetVenueDetail } = this.props;
    onGetVenueDetail(id);
  }

  render() {
    const { venueDetail, onUpdateVenue, onAddNewVenue, onDone } = this.props;

    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: venueDetail?.name ?? "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Please Enter Venue Name"),
        })}
        onSubmit={values => {
          if (venueDetail?.id) {
            onUpdateVenue({ id: venueDetail.id, ...values });
          } else {
            onAddNewVenue(values);
          }
          onDone();
        }}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <React.Fragment>
            <Form>
              <Row className="mb-3">
                <Col lg="6">
                  <div className="mb-2">
                    <Label htmlFor="name" className="col-form-label">
                      Name
                    </Label>
                    <Field
                      name="name"
                      type="text"
                      className={
                        "form-control" +
                        (errors.name && touched.name ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col lg="auto">
                  <Button type="submit" color="primary">
                    {venueDetail?.id ? "Update" : "Create"} Venue
                  </Button>
                </Col>
              </Row>
            </Form>
          </React.Fragment>
        )}
      </Formik>
    );
  }
}

VenueForm.propTypes = {
  id: PropTypes.any,
  venueDetail: PropTypes.object,
  onAddNewVenue: PropTypes.func,
  onGetVenueDetail: PropTypes.func,
  onUpdateVenue: PropTypes.func,
  onGetVenues: PropTypes.func,
  onDone: PropTypes.func,
};

const mapStateToProps = ({ Venues }) => ({
  venueDetail: Venues.venueDetail,
});

const mapDispatchToProps = dispatch => ({
  onGetVenues: () => dispatch(getVenues()),
  onAddNewVenue: venue => dispatch(addNewVenue(venue)),
  onGetVenueDetail: id => dispatch(getVenueDetail(id)),
  onUpdateVenue: venue => dispatch(updateVenue(venue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VenueForm);
