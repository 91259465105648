import React from "react";
import PropTypes from "prop-types";
import { Form, Input } from "antd";
const { getJsDateFromExcel } = require("excel-date-to-js");

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

EditableRow.propTypes = {
  form: PropTypes.any,
  index: PropTypes.any,
};

export const EditableFormRow = Form.create()(EditableRow);

export class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  save = e => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error && error[e.currentTarget.id]) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  getInputType = data => {
    let type = "text";
    if (data.indexOf("email") !== -1) {
      type = "email";
    } else if (data.indexOf("date") !== -1) {
      type = "datetime-local";
    }
    return type;
  };

  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;
    return editing ? (
      <Form.Item style={{ margin: 0 }}>
        {form.getFieldDecorator(dataIndex, {
          rules: [
            {
              required: false,
              message: `${title} is required.`,
            },
            {
              type: dataIndex.indexOf("email") !== -1 ? "email" : "",
              message:
                dataIndex.indexOf("email") !== -1
                  ? "The input is not valid Email!"
                  : "",
            },
          ],
          initialValue: record[dataIndex],
        })(
          <Input
            ref={node => (this.input = node)}
            type={this.getInputType(dataIndex)}
            onPressEnter={this.save}
            onBlur={this.save}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24, minHeight: 32 }}
        onClick={this.toggleEdit}
      >
        {children}
      </div>
    );
  };

  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editable ? (
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        ) : (
          children
        )}
      </td>
    );
  }
}

EditableCell.propTypes = {
  editable: PropTypes.any,
  dataIndex: PropTypes.any,
  title: PropTypes.string,
  record: PropTypes.any,
  index: PropTypes.any,
  handleSave: PropTypes.any,
  children: PropTypes.any,
};
