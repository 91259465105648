import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_USERS,
  GET_USER_PROFILE,
  ADD_NEW_USER,
  DELETE_USER,
  UPDATE_USER,
  ADD_BULK_USERS,
} from "./actionTypes";

import {
  getUsersSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  addBulkSuccess,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getUsers,
  getUserProfile,
  addNewUser,
  updateUser,
  deleteUser,
  addBulkUsers,
} from "helpers/Api/users";

function* fetchUsers() {
  try {
    const response = yield call(getUsers);
    if (response.success) {
      yield put(getUsersSuccess(response.data));
    }
  } catch (error) {
    yield put(getUsersFail(error));
  }
}

function* fetchUserProfile({ userId }) {
  try {
    const response = yield call(getUserProfile, userId);
    if (response.success) {
      yield put(getUserProfileSuccess(response.data));
    }
  } catch (error) {
    yield put(getUserProfileFail(error));
  }
}

function* onAddNewUser({ payload: user }) {
  try {
    const response = yield call(addNewUser, user);
    if (response.success) {
      yield put(addUserSuccess(response.data));
    }
  } catch (error) {
    yield put(addUserFail(error));
  }
}

function* onAddBulkUsers({ payload: users }) {
  try {
    const response = yield call(addBulkUsers, users);
    if (response.success) {
      yield put(addBulkSuccess(response.data, response.message));
    }
  } catch (error) {
    yield put(addUserFail(error.data));
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const response = yield call(updateUser, user);
    if (response.success) {
      yield put(updateUserSuccess(response.data));
    }
  } catch (error) {
    yield put(updateUserFail(error));
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    const response = yield call(deleteUser, user);
    if (response.success) {
      yield put(deleteUserSuccess(response.data));
    }
  } catch (error) {
    yield put(deleteUserFail(error));
  }
}

function* contactsSaga() {
  yield takeEvery(GET_USERS, fetchUsers);
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile);
  yield takeEvery(ADD_NEW_USER, onAddNewUser);
  yield takeEvery(ADD_BULK_USERS, onAddBulkUsers);
  yield takeEvery(UPDATE_USER, onUpdateUser);
  yield takeEvery(DELETE_USER, onDeleteUser);
}

export default contactsSaga;
