/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Button, Col, Input, Row } from "reactstrap";
import styled from "styled-components";

export default function SurveyTitle({ title, preview, handleChangeTitle }) {
  const [editing, setEditing] = useState(false);

  function toggleEditing() {
    setEditing(!editing);
  }

  return (
    <Row className="justify-content-between align-items-center">
      <Col md="10">
        {!preview && editing ? (
          <Input type="text" value={title} onChange={handleChangeTitle} />
        ) : (
          title
        )}
      </Col>
      <Col md="auto">
        {!preview && (
          <Button outline color="primary" onClick={toggleEditing}>
            {editing ? (
              <i className="fas fa-save" />
            ) : (
              <i className="fas fa-pen" />
            )}
          </Button>
        )}
      </Col>
    </Row>
  );
}

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Heading = styled.h1`
  flex: 1 0;
  margin-right: 0.3em;
`;
