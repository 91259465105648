import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";
import TaskForm from "pages/Forms/TaskForm";
import guard from "helpers/jwt-token-access/apiGuard";

class RenderCardTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalData: null,
    };
  }

  onAddClick = () => {
    this.setState({
      modal: true,
    });
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  handleAddNew = () => {
    this.props.handleAddNew();
    this.setState({ modal: false });
  };

  render() {
    const { locationId, status } = this.props;
    return (
      <React.Fragment>
        {guard === "admin" && (
          <UncontrolledDropdown className="float-end">
            <DropdownToggle className="arrow-none" tag="a">
              <i className="mdi mdi-dots-vertical m-0 text-muted h5" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem onClick={this.onAddClick}>Create Task</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
        <CardTitle className="mb-4 h4">{this.props.title}</CardTitle>
        <Modal isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle} tag="h4">
            Add Task
          </ModalHeader>
          <ModalBody>
            <TaskForm
              taskDetail={{ location_id: locationId, status }}
              roadshowDetail={this.state.modalParent}
              onDone={this.handleAddNew}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

RenderCardTitle.propTypes = {
  title: PropTypes.string,
  locationId: PropTypes.number,
  status: PropTypes.number,
  handleAddNew: PropTypes.func,
};

export default RenderCardTitle;
