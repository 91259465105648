import {
  GET_EVENTS_FAIL,
  GET_EVENTS_SUCCESS,
  GET_EVENT_DETAIL_FAIL,
  GET_EVENT_DETAIL_SUCCESS,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAIL,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAIL,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  events: [],
  eventDetail: {},
  error: {},
};

const Events = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
      };

    case GET_EVENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        eventDetail: action.payload,
      };

    case GET_EVENT_DETAIL_FAIL:
      return {
        ...state,
        eventDetail: null,
        error: action.payload,
      };

    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        events: [...state.events, action.payload],
        eventDetail: action.payload,
      };

    case ADD_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.map(event =>
          event.id.toString() === action.payload.id.toString()
            ? { event, ...action.payload }
            : event
        ),
        eventDetail: action.payload,
      };

    case UPDATE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        events: state.events.filter(
          event => event.id.toString() !== action.payload.id.toString()
        ),
        eventDetail: {},
      };

    case DELETE_EVENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Events;
