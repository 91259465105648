import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// get training
export const getTrainings = () => get(url.GET_TRAININGS);

// get training details
export const getTrainingDetails = id => get(`${url.GET_TRAINING_DETAIL}/${id}`);

// add training
export const addNewTraining = training => post(url.ADD_NEW_TRAINING, training);

// submit training
export const submitTraining = submission =>
  post(url.SUBMIT_TRAINING, submission);

// get training answers
export const getTrainingAnswer = surveyId =>
  get(`${url.GET_TRAINING_DETAIL}/${surveyId}/answer`);

// update training
export const updateTraining = training =>
  post(`${url.UPDATE_TRAINING}/${training.id}`, training);

// delete training
export const deleteTraining = id => del(`${url.DELETE_TRAINING}/${id}`);
