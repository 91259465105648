import { call, put, takeEvery } from "redux-saga/effects";

// Location Redux States
import {
  GET_LOCATIONS,
  GET_LOCATION_DETAIL,
  ADD_NEW_LOCATION,
  DELETE_LOCATION,
  UPDATE_LOCATION,
} from "./actionTypes";
import {
  getLocationsSuccess,
  getLocationsFail,
  getLocationDetailSuccess,
  getLocationDetailFail,
  addLocationFail,
  addLocationSuccess,
  updateLocationSuccess,
  updateLocationFail,
  deleteLocationSuccess,
  deleteLocationFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getLocations,
  getLocationsDetails,
  addNewLocation,
  updateLocation,
  deleteLocation,
} from "helpers/Api/locations";

function* fetchLocations() {
  try {
    const response = yield call(getLocations);
    if (response.success) {
      yield put(getLocationsSuccess(response.data));
    } else {
      yield put(getLocationsFail(response.message));
    }
  } catch (error) {
    yield put(getLocationsFail(error));
  }
}

function* fetchLocationDetail({ locationId }) {
  try {
    if (locationId === undefined) {
      yield put(getLocationDetailSuccess(null));
    } else {
      const response = yield call(getLocationsDetails, locationId);
      if (response.success) {
        yield put(getLocationDetailSuccess(response.data));
      } else {
        yield put(getLocationDetailFail(response.message));
      }
    }
  } catch (error) {
    yield put(getLocationDetailFail(error));
  }
}

function* onUpdateLocation({ payload: location }) {
  try {
    const response = yield call(updateLocation, location);
    if (response.success) {
      yield put(updateLocationSuccess(response.data));
    } else {
      yield put(updateLocationFail(response.message));
    }
  } catch (error) {
    yield put(updateLocationFail(error));
  }
}

function* onDeleteLocation({ payload: location }) {
  try {
    const response = yield call(deleteLocation, location);
    if (response.success) {
      yield put(deleteLocationSuccess(response.data));
    } else {
      yield put(deleteLocationFail(response.message));
    }
  } catch (error) {
    yield put(deleteLocationFail(error));
  }
}

function* onAddNewLocation({ payload: location }) {
  try {
    const response = yield call(addNewLocation, location);
    if (response.success) {
      yield put(addLocationSuccess(response.data));
    } else {
      yield put(addLocationFail(response.message));
    }
  } catch (error) {
    yield put(addLocationFail(error));
  }
}

function* locationsSaga() {
  yield takeEvery(GET_LOCATIONS, fetchLocations);
  yield takeEvery(GET_LOCATION_DETAIL, fetchLocationDetail);
  yield takeEvery(ADD_NEW_LOCATION, onAddNewLocation);
  yield takeEvery(UPDATE_LOCATION, onUpdateLocation);
  yield takeEvery(DELETE_LOCATION, onDeleteLocation);
}

export default locationsSaga;
