import {
  GET_TASKS_FAIL,
  GET_TASKS_SUCCESS,
  GET_TASK_DETAIL_FAIL,
  GET_TASK_DETAIL_SUCCESS,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAIL,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAIL,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  tasks: [],
  taskDetail: {},
  error: {},
};

const Tasks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.payload,
      };

    case GET_TASKS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_TASK_DETAIL_SUCCESS:
      return {
        ...state,
        taskDetail: action.payload,
      };

    case GET_TASK_DETAIL_FAIL:
      return {
        ...state,
        taskDetail: null,
        error: action.payload,
      };

    case ADD_TASK_SUCCESS:
      return {
        ...state,
        tasks: [...state.tasks, action.payload],
      };

    case ADD_TASK_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_TASK_SUCCESS:
      return {
        ...state,
        tasks: state.tasks.map(task =>
          task.id.toString() === action.payload.id.toString()
            ? { task, ...action.payload }
            : task
        ),
      };

    case UPDATE_TASK_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_TASK_SUCCESS:
      return {
        ...state,
        tasks: state.tasks.filter(
          task => task.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_TASK_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Tasks;
