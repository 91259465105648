import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";

import img7 from "../../../assets/images/product/img-7.png";
import img4 from "../../../assets/images/product/img-4.png";

class EcommerceOrdersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={this.props.toggle}
        >
          <div className="modal-content">
            <ModalHeader toggle={this.props.toggle}>
              Quotation Details
            </ModalHeader>
            <ModalBody>
              <p className="mb-2">
                ID: <span className="text-primary">#ADV2540</span>
              </p>
              <p className="mb-4">
                Client: <span className="text-primary">Ferrero</span>
              </p>

              <div className="table-responsive">
                <Table className="table align-middle table-nowrap">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Service</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <div></div>
                      </th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14">
                            Service 1
                          </h5>
                          <p className="text-muted mb-0">€ 225</p>
                        </div>
                      </td>
                      <td>€ 255</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div></div>
                      </th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14">
                            Service 2
                          </h5>
                          <p className="text-muted mb-0">€ 145</p>
                        </div>
                      </td>
                      <td>€ 145</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-end">Sub Total:</h6>
                      </td>
                      <td>€ 400</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-end">VAT:</h6>
                      </td>
                      <td>14%</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <h6 className="m-0 text-end">Total:</h6>
                      </td>
                      <td>€ 456</td>
                    </tr>
                  </tbody>
                </Table>
                <p>Notes</p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={this.props.toggle}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default EcommerceOrdersModal;
