import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// get survey
export const getSurveys = () => get(url.GET_SURVEYS);

// get survey details
export const getSurveyDetails = id => get(`${url.GET_SURVEY_DETAIL}/${id}`);

// add survey
export const addNewSurvey = survey => post(url.ADD_NEW_SURVEY, survey);

// answer survey
export const answerSurvey = survey =>
  post(`${url.GET_SURVEY_DETAIL}/${survey.id}/answer`, survey);

// get survey answers
export const getSurveyAnswer = surveyId =>
  get(`${url.GET_SURVEY_DETAIL}/${surveyId}/answer`);

// update survey
export const updateSurvey = survey =>
  post(`${url.UPDATE_SURVEY}/${survey.id}`, survey);

// delete survey
export const deleteSurvey = id => del(`${url.DELETE_SURVEY}/${id}`);
