import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// get user
export const getUsers = () => get(url.GET_USERS);

// get user details
export const getUserProfile = id => get(`${url.GET_USER_PROFILE}/${id}`);

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user);

// import users
export const addBulkUsers = users => post(url.ADD_BULK_USERS, users);

// update user
export const updateUser = user => post(`${url.UPDATE_USER}/${user.id}`, user);

// delete user
export const deleteUser = id => del(`${url.DELETE_USER}/${id}`);
