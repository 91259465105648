import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Button,
} from "reactstrap";
import {
  addNewBrand,
  addNewClient,
  getBrandDetail,
  getBrands,
  getClients,
  updateBrand,
} from "store/actions";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { any } from "prop-types";
import BrandForm from "pages/Forms/BrandForm";

class BrandCreate extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const {
      match: { params },
      onGetBrands,
      onGetBrandDetail,
      onGetClients,
      clients,
    } = this.props;
    onGetBrands();
    onGetBrandDetail(params?.id);
    if (clients.length === 0) {
      onGetClients();
    }
  }

  render() {
    const {
      match: { params },
    } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Brands | AD&amp;VISION - Event Management</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Projects" breadcrumbItem="Brands" />
            <Card>
              <CardBody>
                <BrandForm
                  id={params?.id}
                  onDone={() => this.props.history.push("/brands")}
                />
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

BrandCreate.propTypes = {
  history: any,
  brands: PropTypes.array,
  brandDetail: PropTypes.object,
  match: PropTypes.object,
  onGetBrands: PropTypes.func,
  onAddNewBrand: PropTypes.func,
  onGetBrandDetail: PropTypes.func,
  onUpdateBrand: PropTypes.func,
  users: PropTypes.array,
  clients: PropTypes.array,
  onGetClients: PropTypes.func,
  onAddNewClient: PropTypes.func,
};

const mapStateToProps = ({ Clients, Brands }) => ({
  brandDetail: Brands.brandDetail,
  brands: Brands.brands,
  clients: Clients.clients,
});

const mapDispatchToProps = dispatch => ({
  onGetBrands: () => dispatch(getBrands()),
  onAddNewBrand: brand => dispatch(addNewBrand(brand)),
  onGetBrandDetail: id => dispatch(getBrandDetail(id)),
  onUpdateBrand: brand => dispatch(updateBrand(brand)),
  onGetClients: () => dispatch(getClients()),
  onAddNewClient: client => dispatch(addNewClient(client)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BrandCreate));
