import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// get event
export const getLocations = () => get(url.GET_LOCATIONS);

// get event details
export const getLocationsDetails = id => get(`${url.GET_LOCATION_DETAIL}/${id}`);

// add event
export const addNewLocation = event => post(url.ADD_NEW_LOCATION, event);

// update event
export const updateLocation = event =>
  post(`${url.UPDATE_LOCATION}/${event.id}`, event);

// delete event
export const deleteLocation = id => del(`${url.DELETE_LOCATION}/${id}`);
