export const GET_TRAININGS = "GET_TRAININGS";
export const GET_TRAININGS_SUCCESS = "GET_TRAININGS_SUCCESS";
export const GET_TRAININGS_FAIL = "GET_TRAININGS_FAIL";

export const GET_TRAINING_DETAIL = "GET_TRAINING_DETAIL";
export const GET_TRAINING_DETAIL_SUCCESS = "GET_TRAINING_DETAIL_SUCCESS";
export const GET_TRAINING_DETAIL_FAIL = "GET_TRAINING_DETAIL_FAIL";

export const ADD_NEW_TRAINING = "ADD_NEW_TRAINING";
export const ADD_TRAINING_SUCCESS = "ADD_TRAINING_SUCCESS";
export const ADD_TRAINING_FAIL = "ADD_TRAINING_FAIL";

export const SUBMIT_TRAINING = "SUBMIT_NEW_TRAINING";
export const SUBMIT_TRAINING_SUCCESS = "SUBMIT_TRAINING_SUCCESS";
export const SUBMIT_TRAINING_FAIL = "SUBMIT_TRAINING_FAIL";

export const GET_TRAINING_ANSWER = "GET_TRAINING_ANSWER";
export const GET_TRAINING_ANSWER_SUCCESS = "GET_TRAINING_ANSWER_SUCCESS";
export const GET_TRAINING_ANSWER_FAIL = "GET_TRAINING_ANSWER_FAIL";

export const UPDATE_TRAINING = "UPDATE_TRAINING";
export const UPDATE_TRAINING_SUCCESS = "UPDATE_TRAINING_SUCCESS";
export const UPDATE_TRAINING_FAIL = "UPDATE_TRAINING_FAIL";

export const DELETE_TRAINING = "DELETE_TRAINING";
export const DELETE_TRAINING_SUCCESS = "DELETE_TRAINING_SUCCESS";
export const DELETE_TRAINING_FAIL = "DELETE_TRAINING_FAIL";
