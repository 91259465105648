import { call, put, takeEvery } from "redux-saga/effects";

// Venue Redux States
import {
  GET_VENUES,
  GET_VENUE_DETAIL,
  ADD_NEW_VENUE,
  DELETE_VENUE,
  UPDATE_VENUE,
} from "./actionTypes";
import {
  getVenuesSuccess,
  getVenuesFail,
  getVenueDetailSuccess,
  getVenueDetailFail,
  addVenueFail,
  addVenueSuccess,
  updateVenueSuccess,
  updateVenueFail,
  deleteVenueSuccess,
  deleteVenueFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getVenues,
  getVenuesDetails,
  addNewVenue,
  updateVenue,
  deleteVenue,
} from "helpers/Api/venues";

function* fetchVenues() {
  try {
    const response = yield call(getVenues);
    if (response.success) {
      yield put(getVenuesSuccess(response.data));
    } else {
      yield put(getVenuesFail(response.message));
    }
  } catch (error) {
    yield put(getVenuesFail(error));
  }
}

function* fetchVenueDetail({ venueId }) {
  try {
    if (venueId === undefined) {
      yield put(getVenueDetailSuccess(null));
    } else {
      const response = yield call(getVenuesDetails, venueId);
      if (response.success) {
        yield put(getVenueDetailSuccess(response.data));
      } else {
        yield put(getVenueDetailFail(response.message));
      }
    }
  } catch (error) {
    yield put(getVenueDetailFail(error));
  }
}

function* onUpdateVenue({ payload: venue }) {
  try {
    const response = yield call(updateVenue, venue);
    if (response.success) {
      yield put(updateVenueSuccess(response.data));
    } else {
      yield put(updateVenueFail(response.message));
    }
  } catch (error) {
    yield put(updateVenueFail(error));
  }
}

function* onDeleteVenue({ payload: venue }) {
  try {
    const response = yield call(deleteVenue, venue);
    if (response.success) {
      yield put(deleteVenueSuccess(response.data));
    } else {
      yield put(deleteVenueFail(response.message));
    }
  } catch (error) {
    yield put(deleteVenueFail(error));
  }
}

function* onAddNewVenue({ payload: venue }) {
  try {
    const response = yield call(addNewVenue, venue);
    if (response.success) {
      yield put(addVenueSuccess(response.data));
    } else {
      yield put(addVenueFail(response.message));
    }
  } catch (error) {
    yield put(addVenueFail(error));
  }
}

function* venuesSaga() {
  yield takeEvery(GET_VENUES, fetchVenues);
  yield takeEvery(GET_VENUE_DETAIL, fetchVenueDetail);
  yield takeEvery(ADD_NEW_VENUE, onAddNewVenue);
  yield takeEvery(UPDATE_VENUE, onUpdateVenue);
  yield takeEvery(DELETE_VENUE, onDeleteVenue);
}

export default venuesSaga;
