import { del, get, post, put } from "../api_helper";
import * as url from "../url_helper";

// get event
export const getTasks = (location_id = null) =>
  get(url.GET_TASKS, { params: { location_id } });

// get event details
export const getTasksDetails = id => get(`${url.GET_TASK_DETAIL}/${id}`);

// add event
export const addNewTask = event => post(url.ADD_NEW_TASK, event);

// update event
export const updateTask = event =>
  post(`${url.UPDATE_TASK}/${event.id}`, event);

// delete event
export const deleteTask = id => del(`${url.DELETE_TASK}/${id}`);
