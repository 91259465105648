import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { isEmpty, map } from "lodash";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { getProjectDetail } from "store/projects/actions";
import ProjectDetail from "./projectDetail";
import TeamMembers from "./teamMembers";
import OverviewChart from "./overviewChart";
import { options, series } from "common/data/projects";
import AttachedFiles from "./attachedFiles";
import classnames from "classnames";
import TourPlan from "./tourPlan";
import GanttChart from "./ganttChart";
import ProjectCalendar from "./projectCalendar";

//css
import "@fullcalendar/bootstrap/main.css";
import SurveyBuilder from "components/SurveyBuilder/components/SurveyBuilder";
import moment from "moment";
import guard from "helpers/jwt-token-access/apiGuard";
import ExcelPage from "components/Common/ExcelPage";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { submitTraining } from "store/actions";
import authHeader from "helpers/jwt-token-access/auth-token-header";
import authUser from "helpers/jwt-token-access/authUser";

class ProjectsOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trainingTab: 1,
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const {
      match: { params },
      onGetProjectDetail,
    } = this.props;
    if (params && params.id) {
      onGetProjectDetail(params.id);
    } else {
      onGetProjectDetail(0); //remove this after full integration
    }
    if (!params.activeTab) {
      this.toggle(1);
    }
  }

  toggle(tab) {
    if (this.props.match.params.activeTab !== tab) {
      this.props.history.push(
        `/projects-overview/${this.props.match.params.id}/${tab}`
      );
    }
  }

  toggleTrainingTabs(trainingTab) {
    this.setState({ trainingTab });
  }

  render() {
    const {
      match: {
        params: { activeTab },
      },
      projectDetail,
      onSubmitTraining,
    } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Project Overview | AD&amp;VISION - Event Tool</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Projects" breadcrumbItem="Project Overview" />

            <Nav tabs className="nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  GanttChart
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "3",
                  })}
                  onClick={() => {
                    this.toggle("3");
                  }}
                >
                  Calendar
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "4",
                  })}
                  onClick={() => {
                    this.toggle("4");
                  }}
                >
                  Tour plan
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "5",
                  })}
                  onClick={() => {
                    this.toggle("5");
                  }}
                >
                  Training
                </NavLink>
              </NavItem>
              {guard === "admin" && (
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "6",
                    })}
                    onClick={() => {
                      this.toggle("6");
                    }}
                  >
                    Import
                  </NavLink>
                </NavItem>
              )}
            </Nav>

            <TabContent activeTab={activeTab} className="py-3 text-muted">
              <TabPane tabId="1">
                {!isEmpty(projectDetail) && (
                  <>
                    <Row>
                      <Col lg="12">
                        <ProjectDetail project={projectDetail} />
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="4">
                        <TeamMembers team={projectDetail.team} />
                      </Col>
                      <Col lg="8">
                        <AttachedFiles files={projectDetail.files} />
                      </Col>

                      {/* <Col lg="4">
                        <OverviewChart options={options} series={series} />
                      </Col> */}

                      {/* <Col lg="4">
                        <Comments comments={projectDetail.comments} />
                      </Col> */}
                    </Row>
                  </>
                )}
              </TabPane>
              <TabPane tabId="2">
                <GanttChart />
              </TabPane>
              <TabPane tabId="3"></TabPane>
              <TabPane tabId="4">
                <Card>
                  <CardBody>
                    <div className="w-100 overflow-scroll">
                      <TourPlan projectDetail={projectDetail} />
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="5">
                <Card>
                  <CardBody>
                    <Nav pills className="justify-content-center">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.trainingTab === 1,
                          })}
                          onClick={() => {
                            this.toggleTrainingTabs(1);
                          }}
                        >
                          Training
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.trainingTab === 2,
                          })}
                          onClick={() => {
                            this.toggleTrainingTabs(2);
                          }}
                        >
                          Builder
                        </NavLink>
                      </NavItem>
                      {guard === "admin" && (
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: this.state.trainingTab === 3,
                            })}
                            onClick={() => {
                              this.toggleTrainingTabs(3);
                            }}
                          >
                            Results
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                    <TabContent
                      activeTab={this.state.trainingTab}
                      className="py-3 text-muted"
                    >
                      <TabPane tabId={1}>
                        <Table className="table align-middle table-nowrap">
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>File</th>
                              <th>Submissions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {projectDetail?.trainings?.map(t => (
                              <tr key={t.id}>
                                <td>{t.title}</td>
                                <td>
                                  {t.file && (
                                    <a
                                      className="d-block text-truncate"
                                      style={{ maxWidth: 150 }}
                                      target="blank"
                                      href={t.file.link}
                                    >
                                      {t.file.name}
                                    </a>
                                  )}
                                </td>
                                <td>
                                  {(guard === "admin" && (
                                    <ul className="list-unstyled">
                                      {t.submissions.map(ts => (
                                        <li key={ts.id}>
                                          <a
                                            target="blank"
                                            href={ts.file?.link}
                                          >
                                            {ts.user?.name} (
                                            {moment(ts.created_at).format(
                                              "DD/MM/YYYY HH:mm"
                                            )}
                                            )
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  )) ||
                                    (t.submissions.find(
                                      ts => ts.user_id === authUser?.id
                                    ) && (
                                      <a
                                        target="blank"
                                        href={
                                          t.submissions.find(
                                            ts => ts.user_id === authUser?.id
                                          ).file?.link
                                        }
                                      >
                                        {
                                          t.submissions.find(
                                            ts => ts.user_id === authUser?.id
                                          ).file?.name
                                        }
                                      </a>
                                    )) || (
                                      <Button
                                        color="primary"
                                        onClick={() =>
                                          this.setState({
                                            trainingModal: true,
                                            currentTraining: t,
                                          })
                                        }
                                      >
                                        Submit
                                      </Button>
                                    )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </TabPane>
                      <TabPane tabId={2}>
                        <SurveyBuilder projectDetail={projectDetail} />
                      </TabPane>
                      <TabPane tabId={3}>
                        {(projectDetail?.survey?.survey_answers?.length ===
                          0 && (
                          <h5 className="my-4 text-center">No results yet!</h5>
                        )) || (
                          <Table className="table align-middle table-nowrap">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Score</th>
                              </tr>
                            </thead>
                            <tbody>
                              {projectDetail?.survey?.survey_answers?.map(a => (
                                <tr key={"_member_" + a.id}>
                                  <td style={{ width: "50px" }}>
                                    <div className="avatar-xs">
                                      <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
                                        {a.respondent?.name?.charAt(0)}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <h5 className="font-size-14 m-0">
                                      {a.respondent?.name}
                                    </h5>
                                  </td>
                                  <td>
                                    <span className="muted">
                                      {moment(a.created_at).format(
                                        "DD/MM/YYYY HH:mm"
                                      )}
                                    </span>
                                  </td>
                                  <td>{a.score}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </TabPane>
              {guard === "admin" && (
                <TabPane tabId="6">
                  <ExcelPage
                    type="project"
                    sample_file_url="https://staging-apps.com/demo_files/Project_Details_Sample_Excel_Sheet.xlsx"
                    project_id={projectDetail?.id}
                    columns={[
                      {
                        title: "Start Date",
                        dataIndex: "start_date",
                        editable: true,
                      },
                      {
                        title: "End Date",
                        dataIndex: "end_date",
                        editable: true,
                      },
                      {
                        title: "Venue Name",
                        dataIndex: "venue_name",
                        editable: true,
                      },
                      {
                        title: "Venue PLZ",
                        dataIndex: "venue_plz",
                        editable: true,
                      },
                      {
                        title: "Venue City",
                        dataIndex: "venue_city",
                        editable: true,
                      },
                      {
                        title: "Venue Street",
                        dataIndex: "venue_street",
                        editable: true,
                      },
                      {
                        title: "Team Leader Email",
                        dataIndex: "team_leader_email",
                        editable: true,
                      },
                      {
                        title: "Promoter Email",
                        dataIndex: "promoter_email",
                        editable: true,
                      },
                      {
                        title: "WA 1 Email",
                        dataIndex: "wa_1_email",
                        editable: true,
                      },
                      {
                        title: "WA 2 Email",
                        dataIndex: "wa_2_email",
                        editable: true,
                      },
                      {
                        title: "Supervisor Email",
                        dataIndex: "supervisor_email",
                        editable: true,
                      },
                      {
                        title: "Venue Contact Person",
                        dataIndex: "venue_contact_name",
                        editable: true,
                      },
                      {
                        title: "Venue Contact Phone",
                        dataIndex: "venue_contact_phone",
                        editable: true,
                      },
                      {
                        title: "Client Account Manager",
                        dataIndex: "client_key_account_manager_name",
                        editable: true,
                      },
                      {
                        title: "Client Account Manager Email",
                        dataIndex: "client_key_account_manager_email",
                        editable: true,
                      },
                      {
                        title: "Account Manager Phone",
                        dataIndex: "client_key_account_manager_phone",
                        editable: true,
                      },
                      {
                        title: "Client Supervisor",
                        dataIndex: "client_supervisor_name",
                        editable: true,
                      },
                      {
                        title: "Client Supervisor Phone",
                        dataIndex: "client_supervisor_phone",
                        editable: true,
                      },
                      {
                        title: "Client Supervisor Email",
                        dataIndex: "client_supervisor_email",
                        editable: true,
                      },
                      {
                        title: "Client VGL",
                        dataIndex: "client_vgl_name",
                        editable: true,
                      },
                      {
                        title: "Client VGL Phone",
                        dataIndex: "client_vgl_phone",
                        editable: true,
                      },
                      {
                        title: "Client VGL Email",
                        dataIndex: "client_vgl_email",
                        editable: true,
                      },
                      {
                        title: "Venue Category",
                        dataIndex: "venue_category",
                        editable: true,
                      },
                    ]}
                  />
                </TabPane>
              )}
            </TabContent>
            {activeTab === "3" && (
              <Card>
                <CardBody>
                  <ProjectCalendar />
                </CardBody>
              </Card>
            )}
          </Container>
        </div>
        <Modal isOpen={this.state.trainingModal}>
          <ModalHeader toggle={this.toggle} tag="h4">
            Submit Training
          </ModalHeader>
          <ModalBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                project_id: projectDetail?.id,
                training_id: this.state.currentTraining?.id,
                file: "",
              }}
              validationSchema={Yup.object().shape({
                file: Yup.string().required("File is required"),
              })}
              onSubmit={async values => {
                console.log("submit", values);
                let newSubmission = {
                  ...values,
                };
                const filesData = new FormData();
                if (this.state.file) {
                  filesData.append("files[]", this.state.file);

                  const res = await fetch(
                    `${process.env.REACT_APP_API_URL}/v1/upload`,
                    {
                      method: "POST",
                      body: filesData,
                      headers: authHeader(),
                    }
                  );
                  const files = await res.json();
                  newSubmission.data = {
                    file: files[0],
                  };
                }
                // submit training file
                onSubmitTraining(newSubmission);

                this.setState({
                  trainingModal: false,
                  currentTraining: null,
                  file: null,
                });
              }}
            >
              {({ values, errors, handleChange, touched }) => (
                <Form>
                  <Row>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">File</Label>
                        <Field
                          name="file"
                          type="file"
                          className={
                            "form-control" +
                            (errors.file && touched.file ? " is-invalid" : "")
                          }
                          onChange={e => {
                            handleChange(e);
                            this.setState({
                              file: e.currentTarget.files[0],
                            });
                          }}
                        />
                        <ErrorMessage
                          name="file"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      {JSON.stringify(errors)}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button type="submit" className="btn btn-success">
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

ProjectsOverview.propTypes = {
  history: PropTypes.any,
  projectDetail: PropTypes.any,
  match: PropTypes.object,
  onGetProjectDetail: PropTypes.func,
  onSubmitTraining: PropTypes.func,
};

const mapStateToProps = ({ projects }) => ({
  projectDetail: projects.projectDetail,
});

const mapDispatchToProps = dispatch => ({
  onGetProjectDetail: id => dispatch(getProjectDetail(id)),
  onSubmitTraining: submission => dispatch(submitTraining(submission)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectsOverview));
