import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { Container, Card, CardBody } from "reactstrap";
import {
  addNewVenue,
  getVenueDetail,
  getBrands,
  updateVenue,
} from "store/actions";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { any } from "prop-types";
import VenueForm from "pages/Forms/VenueForm";

class VenueCreate extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const {
      match: { params },
      onGetBrands,
      onGetVenueDetail,
    } = this.props;
    onGetBrands();
    onGetVenueDetail(params?.id);
  }

  render() {
    const { venueDetail, brands } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Venues | AD&amp;VISION - Event Management</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Home" breadcrumbItem="Venues" />
            <Card>
              <CardBody>
                <VenueForm onDone={() => this.props.history.push("/venues")} />
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

VenueCreate.propTypes = {
  history: any,
  brands: PropTypes.array,
  venueDetail: PropTypes.object,
  match: PropTypes.object,
  onGetBrands: PropTypes.func,
  onAddNewVenue: PropTypes.func,
  onGetVenueDetail: PropTypes.func,
  onUpdateVenue: PropTypes.func,
  users: PropTypes.array,
};

const mapStateToProps = ({ Brands, Venues }) => ({
  venueDetail: Venues.venueDetail,
  brands: Brands.brands,
});

const mapDispatchToProps = dispatch => ({
  onGetBrands: () => dispatch(getBrands()),
  onAddNewVenue: venue => dispatch(addNewVenue(venue)),
  onGetVenueDetail: id => dispatch(getVenueDetail(id)),
  onUpdateVenue: venue => dispatch(updateVenue(venue)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VenueCreate));
