import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, CardFooter } from "reactstrap";
import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { Chart } from "react-google-charts";
import { FrappeGantt } from "frappe-gantt-react";

function daysToMilliseconds(days) {
  return days * 24 * 60 * 60 * 1000;
}

const columns = [
  { type: "string", label: "Task ID" },
  { type: "string", label: "Task Name" },
  { type: "date", label: "Start Date" },
  { type: "date", label: "End Date" },
  { type: "number", label: "Duration" },
  { type: "number", label: "Percent Complete" },
  { type: "string", label: "Dependencies" },
];

const rows = [
  [
    "Task1",
    "Task 1",
    new Date(2015, 0, 1),
    new Date(2015, 0, 5),
    null,
    100,
    null,
  ],
  [
    "Task2",
    "Task 2",
    null,
    new Date(2015, 0, 9),
    daysToMilliseconds(3),
    25,
    "Task1,Task5",
  ],
  [
    "Task3",
    "Task 3",
    null,
    new Date(2015, 0, 7),
    daysToMilliseconds(1),
    20,
    "Task1",
  ],
  [
    "Task4",
    "Task 4",
    null,
    new Date(2015, 0, 10),
    daysToMilliseconds(1),
    0,
    "Task3,Task2",
  ],
  [
    "Task5",
    "Task 5",
    null,
    new Date(2015, 0, 6),
    daysToMilliseconds(1),
    100,
    "Task1",
  ],
];

const data = [columns, ...rows];

const ViewMode = {
  Day: "Day",
  Week: "Week",
  Month: "Month",
};

class GanttChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: [
        {
          id: "Task 1",
          name: "Task Title 1",
          start: "2021-12-28",
          end: "2021-12-31",
          progress: 10,
          dependencies: "",
        },
        {
          id: "Task 2",
          name: "Task Title 2",
          start: "2022-01-01",
          end: "2022-01-11",
          progress: 20,
          dependencies: "Task 1",
        },
        {
          id: "Task 3",
          name: "Task Title 3",
          start: "2022-01-05",
          end: "2022-01-22",
          progress: 0,
          dependencies: "Task 2, Task 1",
        },
        {
          id: "Task 4",
          name: "Task Title 4",
          start: "2021-12-31",
          end: "2022-01-22",
          progress: 0,
          dependencies: "Task 1",
        },
      ],
      mode: "Day",
    };
  }

  render() {
    return (
      <React.Fragment>
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Gantt Chart | AD&amp;VISION - Event Tool</title>
            </MetaTags>
            <div className="container-fluid">
              <Breadcrumbs title="Project" breadcrumbItem="Gantt Chart" />

              <Row>
                {/* <Col xl={12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Project Roadmap</CardTitle>
                      <Chart
                        chartType="Gantt"
                        width="100%"
                        height="50%"
                        data={data}
                      />
                    </CardBody>
                  </Card>
                </Col> */}
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">
                        <div className="btn-group mb-3" role="group">
                          {Object.keys(ViewMode).map(mode => (
                            <button
                              key={mode}
                              type="button"
                              className={`btn btn-outline-primary w-sm ${
                                mode === this.state.mode ? "active" : ""
                              }`}
                              onClick={() => this.setState({ mode })}
                            >
                              {ViewMode[mode]}
                            </button>
                          ))}
                        </div>
                      </CardTitle>
                      <FrappeGantt
                        tasks={this.state.tasks}
                        viewMode={this.state.mode}
                        onClick={task => console.log(task)}
                        onDateChange={(task, start, end) =>
                          console.log(task, start, end)
                        }
                        onProgressChange={(task, progress) =>
                          console.log(task, progress)
                        }
                        onTasksChange={tasks => console.log(tasks)}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default GanttChart;
