import React, { Component } from "react";
import PropTypes from "prop-types";
import { map, get } from "lodash";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import img1 from "../../../assets/images/companies/img-1.png";
import { Link, withRouter } from "react-router-dom";
import guard from "helpers/jwt-token-access/apiGuard";
import { connect } from "react-redux";
import { deleteProject } from "store/actions";
import DeleteModal from "components/Common/DeleteModal";

class ProjectDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
    };
  }

  handleDeleteProject = () => {
    const { project, history } = this.props;
    this.props.onArchiveProject(project.id);
    history.push("/projects-grid");
  };

  render() {
    const { deleteModal } = this.state;
    const { project } = this.props;

    return (
      <Card>
        <CardBody>
          <DeleteModal
            archive
            show={deleteModal}
            onDeleteClick={this.handleDeleteProject}
            onCloseClick={() => this.setState({ deleteModal: false })}
          />
          <div className="d-flex">
            <img src={img1} alt="" className="avatar-sm me-4" />

            <div className="flex-grow-1 overflow-hidden">
              <h5 className="text-truncate font-size-15">{project.name}</h5>
              <p
                className="text-muted m-0"
                dangerouslySetInnerHTML={{
                  __html: project.description,
                }}
              />
            </div>

            {guard === "admin" && (
              <>
                <Link
                  to={`/projects-create/${project.id}`}
                  className="d-flex align-items-center"
                >
                  <i className="mdi mdi-24px mdi-pencil"></i>
                </Link>
                <Button
                  color="link"
                  className="mx-2"
                  onClick={() => this.setState({ deleteModal: true })}
                >
                  <i className="mdi mdi-24px mdi-archive"></i>
                </Button>
              </>
            )}
          </div>

          <h5 className="font-size-15 mt-4">Project Details :</h5>

          <p className="text-muted">
            {get(project, "projectDetails.description")}
          </p>

          <div className="text-muted mt-4">
            {project.projectDetails &&
              map(project.projectDetails.points, (point, index) => (
                <p key={index}>
                  <i className="mdi mdi-chevron-right text-primary me-1" />
                  {point}
                </p>
              ))}
          </div>

          <Row className="task-dates">
            <Col sm="2" xs="6">
              <div className="mt-4">
                <h5 className="font-size-14">
                  <i className="bx bx-calendar me-1 text-primary" /> Dates
                </h5>
                <p className="text-muted mb-0">
                  {project.start_date} <br /> {project.end_date}
                </p>
              </div>
            </Col>

            <Col sm="2" xs="6">
              <div className="mt-4">
                <h5 className="font-size-14">Venue</h5>
                <p className="text-muted mb-0">{project.venue?.name}</p>
              </div>
            </Col>

            <Col sm="2" xs="6">
              <div className="mt-4">
                <h5 className="font-size-14">Client</h5>
                <p className="text-muted mb-0">{project.client?.name}</p>
              </div>
            </Col>

            <Col sm="2" xs="6">
              <div className="mt-4">
                <h5 className="font-size-14">Brand</h5>
                <p className="text-muted mb-0">{project.brand?.name}</p>
              </div>
            </Col>

            <Col sm="2" xs="6">
              <div className="mt-4">
                <h5 className="font-size-14">LKS#</h5>
                <p className="text-muted mb-0">{project.lks}</p>
              </div>
            </Col>

            <Col sm="2" xs="6">
              <div className="mt-4">
                <h5 className="font-size-14">KVA#</h5>
                <p className="text-muted mb-0">{project.kva}</p>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

ProjectDetail.propTypes = {
  history: PropTypes.any,
  project: PropTypes.object,
  onArchiveProject: PropTypes.func,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = dispatch => ({
  onArchiveProject: id => dispatch(deleteProject(id, true)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectDetail));
