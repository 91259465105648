import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SurveyTitle from "./SurveyTitle";
import SurveyQuestion from "./SurveyQuestion";
import { useInputValue } from "../hooks";
import Question from "../models/Question";
import ListController from "../controllers/ListController";

import "../styles.css";
import { Button, ButtonGroup } from "reactstrap";
import { connect } from "react-redux";
import { addNewSurvey, answerSurvey, getSurveyAnswer } from "store/actions";
import guard from "helpers/jwt-token-access/apiGuard";

function SurveyBuilder(props) {
  const [preview, setPreview] = useState(guard !== "admin");
  const [title, handleChangeTitle] = useInputValue("New Survey");
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState(props.surveyAnswer?.answers ?? []);

  const listController = new ListController(questions, setQuestions);
  const listAnswers = new ListController(answers, setAnswers);

  useEffect(() => {
    if (props.projectDetail?.survey) {
      const {
        projectDetail: { survey },
        onGetSurveyAnswer,
      } = props;
      if (guard !== "admin") {
        onGetSurveyAnswer(survey.id);
      }
      handleChangeTitle({ target: { value: survey.title } });
      setQuestions(
        survey.questions.map(question => new Question({ ...question }))
      );
    }
  }, [props.projectDetail?.survey]);

  useEffect(() => {
    setAnswers(props.surveyAnswer.answers ?? []);
  }, [props.surveyAnswer]);

  const saveSurvey = () => {
    const { onAddNewSurvey } = props;
    onAddNewSurvey({ project_id: props.projectDetail?.id, title, questions });
  };

  const togglePreview = () => {
    setPreview(!preview);
  };

  const submitAnswers = () => {
    if (props.projectDetail?.survey) {
      const {
        projectDetail: { survey },
        onAnswerSurvey,
      } = props;
      onAnswerSurvey({
        id: survey.id,
        answers: listAnswers.array,
      });
    }
  };

  const { surveyAnswer } = props;

  return (
    <div className="small-container">
      <SurveyTitle
        preview={preview}
        title={title}
        handleChangeTitle={handleChangeTitle}
      />

      <ol className="p-0">
        {questions.map((question, i) => (
          <SurveyQuestion
            key={question.id}
            preview={preview}
            question={question}
            answer={surveyAnswer?.answers?.find(
              a => a.question_id === question.id
            )}
            setQuestion={question => listController.set(i, question)}
            setAnswer={answer => listAnswers.set(i, answer)}
            removeQuestion={() => listController.remove(i)}
            moveQuestionUp={() => listController.moveUp(i)}
            moveQuestionDown={() => listController.moveDown(i)}
          />
        ))}
      </ol>
      {!preview && (
        <Button
          color="success"
          onClick={() => listController.add(new Question())}
        >
          <i className="fas fa-plus icon" />
          Add Question
        </Button>
      )}
      <ButtonGroup className="float-end">
        {!preview && (
          <Button color="success" onClick={saveSurvey}>
            <i className="fas fa-save icon" />
            Save
          </Button>
        )}
        {guard === "admin" && (
          <Button color="primary" onClick={togglePreview}>
            {!preview && <i className="fas fa-eye icon" />}
            {preview && <i className="fas fa-edit icon" />}
            {preview ? "Edit" : "Preview"}
          </Button>
        )}
        {guard !== "admin" && (
          <Button
            disabled={surveyAnswer?.answers?.length > 0}
            color="primary"
            onClick={submitAnswers}
          >
            Submit
            <i className="fas fa-chevron-circle-right ms-2" />
          </Button>
        )}
      </ButtonGroup>
    </div>
  );
}

SurveyBuilder.propTypes = {
  projectDetail: PropTypes.any,
  onAddNewSurvey: PropTypes.func,
  onAnswerSurvey: PropTypes.func,
  surveyAnswer: PropTypes.any,
  onGetSurveyAnswer: PropTypes.func,
};

const mapStateToProps = ({ Surveys }) => ({
  surveyAnswer: Surveys.answer,
});

const mapDispatchToProps = dispatch => ({
  onAddNewSurvey: survey => dispatch(addNewSurvey(survey)),
  onAnswerSurvey: answers => dispatch(answerSurvey(answers)),
  onGetSurveyAnswer: surveyId => dispatch(getSurveyAnswer(surveyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyBuilder);
