import { call, put, takeEvery } from "redux-saga/effects";

// Task Redux States
import {
  GET_TASKS,
  GET_TASK_DETAIL,
  ADD_NEW_TASK,
  DELETE_TASK,
  UPDATE_TASK,
} from "./actionTypes";
import {
  getTasksSuccess,
  getTasksFail,
  getTaskDetailSuccess,
  getTaskDetailFail,
  addTaskFail,
  addTaskSuccess,
  updateTaskSuccess,
  updateTaskFail,
  deleteTaskSuccess,
  deleteTaskFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getTasks,
  getTasksDetails,
  addNewTask,
  updateTask,
  deleteTask,
} from "helpers/Api/tasks";

function* fetchTasks({ location_id }) {
  try {
    const response = yield call(getTasks, location_id);
    if (response.success) {
      yield put(getTasksSuccess(response.data));
    } else {
      yield put(getTasksFail(response.message));
    }
  } catch (error) {
    yield put(getTasksFail(error));
  }
}

function* fetchTaskDetail({ taskId }) {
  try {
    if (taskId === undefined) {
      yield put(getTaskDetailSuccess(null));
    } else {
      const response = yield call(getTasksDetails, taskId);
      if (response.success) {
        yield put(getTaskDetailSuccess(response.data));
      } else {
        yield put(getTaskDetailFail(response.message));
      }
    }
  } catch (error) {
    yield put(getTaskDetailFail(error));
  }
}

function* onUpdateTask({ payload: task }) {
  try {
    const response = yield call(updateTask, task);
    if (response.success) {
      yield put(updateTaskSuccess(response.data));
    } else {
      yield put(updateTaskFail(response.message));
    }
  } catch (error) {
    yield put(updateTaskFail(error));
  }
}

function* onDeleteTask({ payload: task }) {
  try {
    const response = yield call(deleteTask, task);
    if (response.success) {
      yield put(deleteTaskSuccess(response.data));
    } else {
      yield put(deleteTaskFail(response.message));
    }
  } catch (error) {
    yield put(deleteTaskFail(error));
  }
}

function* onAddNewTask({ payload: task }) {
  try {
    const response = yield call(addNewTask, task);
    if (response.success) {
      yield put(addTaskSuccess(response.data));
    } else {
      yield put(addTaskFail(response.message));
    }
  } catch (error) {
    yield put(addTaskFail(error));
  }
}

function* tasksSaga() {
  yield takeEvery(GET_TASKS, fetchTasks);
  yield takeEvery(GET_TASK_DETAIL, fetchTaskDetail);
  yield takeEvery(ADD_NEW_TASK, onAddNewTask);
  yield takeEvery(UPDATE_TASK, onUpdateTask);
  yield takeEvery(DELETE_TASK, onDeleteTask);
}

export default tasksSaga;
