import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// actions
import { deleteClient, getClients } from "store/actions";
import { MetaTags } from "react-meta-tags";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Link, withRouter } from "react-router-dom";
import { map } from "lodash";
import DeleteModal from "components/Common/DeleteModal";

class ClientsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: null,
      deleteModal: false,
    };
  }
  componentDidMount() {
    const { onGetClients } = this.props;
    onGetClients();
  }

  onClickDelete = client => {
    this.setState({ client, deleteModal: true });
  };

  onClickEdit = client => {
    if (client.id !== undefined) {
      this.props.history.push(`/client-create/${client.id}`);
    }
  };

  handleDeleteClient = () => {
    const { onDeleteClient } = this.props;
    const { client } = this.state;
    if (client.id !== undefined) {
      onDeleteClient(client.id);
      this.setState({ deleteModal: false });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ ...this.state, clients: this.props.clients });
    }
  }

  render() {
    const { clients } = this.props;
    const { deleteModal } = this.state;

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteClient}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <MetaTags>
            <title>Starter Page | AD&amp;VISION - Event Tool</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Clients" breadcrumbItem="List" />
            <Card>
              <CardHeader className="h5 bg-transparent border-bottom text-end">
                <Button
                  color="success"
                  onClick={() => this.props.history.push("/client-create")}
                >
                  Create New
                </Button>
              </CardHeader>
              <CardBody>
                <Table striped>
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "100px" }}>
                        #
                      </th>
                      <th scope="col">Client</th>
                      <th scope="col">Contacts</th>
                      <th scope="col">Brands</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {map(clients, (client, index) => (
                      <tr key={index}>
                        <td>{client.id}</td>
                        <td>
                          <h5 className="text-truncate font-size-14">
                            <Link
                              to={`/clients-overview/${client.id}`}
                              className="text-dark"
                            >
                              {client.name}
                            </Link>
                          </h5>
                        </td>
                        <td>
                          <ul className="list-unstyled">
                            {client.staff?.map(c => (
                              <li key={c.id} className="border-bottom mb-2">
                                {c.name}{" "}
                                {c.email && (
                                  <>
                                    <br />
                                    <a href={`mailto:${c.email}`}>{c.email}</a>
                                  </>
                                )}
                                {c.phone && (
                                  <>
                                    <br />
                                    {c.phone}
                                  </>
                                )}
                              </li>
                            ))}
                          </ul>
                        </td>
                        <td>
                          {client.brands.map(
                            brand =>
                              brand.parent_id === null && (
                                <Link
                                  key={brand.id}
                                  to={`/brands-overview/${brand.id}`}
                                >
                                  <Badge className="m-1" color="primary" pill>
                                    {brand.name}
                                  </Badge>
                                </Link>
                              )
                          )}
                        </td>
                        <td>
                          <ButtonGroup>
                            <Button
                              outline
                              color="success"
                              onClick={() => this.onClickEdit(client)}
                            >
                              <i className="mdi mdi-pencil font-size-16 me-1" />{" "}
                            </Button>
                            <Button
                              outline
                              color="danger"
                              onClick={() => this.onClickDelete(client)}
                            >
                              <i className="mdi mdi-trash-can font-size-16 me-1" />{" "}
                            </Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ClientsList.propTypes = {
  history: PropTypes.any,
  clients: PropTypes.any,
  onGetClients: PropTypes.func,
  onDeleteClient: PropTypes.func,
};

const mapStateToProps = ({ Clients }) => ({
  clients: Clients.clients,
});

const mapDispatchToProps = dispatch => ({
  onGetClients: () => dispatch(getClients()),
  onDeleteClient: id => dispatch(deleteClient(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClientsList));
