/* BRANDS */
export const GET_BRANDS = "GET_BRANDS"
export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS"
export const GET_BRANDS_FAIL = "GET_BRANDS_FAIL"

/* BRANDS DETAIL*/
export const GET_BRAND_DETAIL = "GET_BRAND_DETAIL"
export const GET_BRAND_DETAIL_SUCCESS = "GET_BRAND_DETAIL_SUCCESS"
export const GET_BRAND_DETAIL_FAIL = "GET_BRAND_DETAIL_FAIL"

export const ADD_NEW_BRAND = "ADD_NEW_BRAND"
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS"
export const ADD_BRAND_FAIL = "ADD_BRAND_FAIL"

export const UPDATE_BRAND = "UPDATE_BRAND"
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS"
export const UPDATE_BRAND_FAIL = "UPDATE_BRAND_FAIL"

export const DELETE_BRAND = "DELETE_BRAND"
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS"
export const DELETE_BRAND_FAIL = "DELETE_BRAND_FAIL"