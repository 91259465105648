import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  API_ERROR,
  SOCIAL_LOGIN,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
} from "./actionTypes";

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const currentUser = () => {
  return {
    type: GET_CURRENT_USER,
    payload: {},
  };
};

export const getCurrentUserSuccess = user => {
  return {
    type: GET_CURRENT_USER_SUCCESS,
    payload: user,
  };
};

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const resetPassword = data => {
  return {
    type: RESET_PASSWORD,
    payload: data,
  };
};

export const resetPasswordSuccess = data => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const resetPasswordFail = error => {
  return {
    type: RESET_PASSWORD_FAIL,
    payload: error,
  };
};

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  };
};
