import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import moment from "moment";
import { connect } from "react-redux";
import { addNewTask, deleteTask, updateTask } from "store/actions";
import DeleteModal from "components/Common/DeleteModal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import guard from "helpers/jwt-token-access/apiGuard";

class TaskForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
      modal: false,
      modalType: "",
    };
  }

  types = [
    { value: 1, name: "Market" },
    { value: 2, name: "Fair" },
    { value: 3, name: "Other" },
  ];

  onClickDelete = e => {
    this.setState({ deleteModal: true });
  };

  handleDelete = () => {
    const { taskDetail, onDeleteTask, onDone } = this.props;
    if (taskDetail?.id) {
      onDeleteTask(taskDetail?.id);
      onDone();
    }
  };

  render() {
    const { onDone, taskDetail, projectDetail, onAddNewTask, onUpdateTask } =
      this.props;

    const { team } = projectDetail;

    return (
      <>
        <DeleteModal
          show={this.state.deleteModal}
          onDeleteClick={this.handleDelete}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <Formik
          enableReinitialize={true}
          initialValues={{
            id: taskDetail.id,
            location_id: taskDetail.location_id,
            name: taskDetail.name ?? "",
            description: taskDetail.description ?? "",
            start_date: taskDetail.start_date ?? "",
            end_date: taskDetail.end_date ?? "",
            budget: taskDetail.budget ?? 0,
            cost: taskDetail.cost ?? 0,
            reporter_id: taskDetail.reporter_id ?? "",
            assignee_id: taskDetail.assignee_id ?? "",
            reporter: taskDetail.reporter ?? "",
            assignee: taskDetail.assignee ?? "",
            status: taskDetail.status ?? 0,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(),
            description: Yup.string(),
            budget: Yup.number(),
            cost: Yup.number(),
            start_date: Yup.date().required(),
            end_date: Yup.date().required(),
            reporter_id: Yup.number().required(),
            assignee_id: Yup.number().required(),
            notes: Yup.string(),
          })}
          onSubmit={values => {
            if (values.id) {
              onUpdateTask(values);
            } else {
              onAddNewTask(values);
            }
            onDone();
          }}
        >
          {({
            values,
            errors,
            status,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => (
            <Form className="outer-repeater">
              <div data-repeater-list="outer-group" className="outer">
                <div data-repeater-item className="outer">
                  <FormGroup className="mb-4" row>
                    <Label htmlFor="name" className="col-form-label col-lg-2">
                      Task Name
                    </Label>
                    <Col lg="10">
                      <Field
                        name="name"
                        type="text"
                        className={
                          "form-control" +
                          (errors.name && touched.name ? " is-invalid" : "")
                        }
                        placeholder="Enter Task Name..."
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="mb-4" row>
                    <Label className="col-form-label col-lg-2">
                      Task Description
                    </Label>
                    <Col lg="10">
                      <CKEditor
                        editor={ClassicEditor}
                        data={values.description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue("description", data);
                        }}
                        onBlur={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue("description", data);
                        }}
                        placeholder="Place Your Content Here..."
                        className={
                          "form-control" +
                          (errors.description && touched.description
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="invalid-feedback"
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup className="mb-4" row>
                    <Label className="col-form-label col-lg-2">Task Date</Label>
                    <Col lg="10">
                      <Row>
                        <Col md="6">
                          <Flatpickr
                            placeholder="D/M/Y"
                            options={{
                              inline: false,
                              altInput: true,
                              altFormat: "d/m/Y",
                              dateFormat: "Y-m-d",
                            }}
                            disabled={guard !== "admin"}
                            name="start_date"
                            value={values.start_date}
                            onChange={([value]) =>
                              setFieldValue(
                                "start_date",
                                moment(value).format("YYYY-MM-DD")
                              )
                            }
                            className={
                              "form-control" +
                              (errors.start_date && touched.start_date
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="start_date"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                        <Col md="6">
                          <Flatpickr
                            placeholder="D/M/Y"
                            options={{
                              inline: false,
                              altInput: true,
                              altFormat: "d/m/Y",
                              dateFormat: "Y-m-d H:i",
                            }}
                            disabled={guard !== "admin"}
                            name="end_date"
                            value={values.end_date}
                            onChange={([value]) =>
                              setFieldValue(
                                "end_date",
                                moment(value).format("YYYY-MM-DD")
                              )
                            }
                            className={
                              "form-control" +
                              (errors.end_date && touched.end_date
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="end_date"
                            component="div"
                            className="invalid-feedback"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>

                  <div className="inner-repeater mb-4">
                    <div className="inner form-group mb-0 row">
                      <Label className="col-form-label col-lg-2">
                        Assignee
                      </Label>
                      <div className="inner col-lg-10 ms-md-auto" id="repeater">
                        <Select
                          name="assignee"
                          isDisabled={guard !== "admin"}
                          isClearable
                          value={values.assignee}
                          options={team}
                          getOptionLabel={option => option.name || "Create New"}
                          getOptionValue={option => option.id}
                          onChange={newValue => {
                            setFieldValue("assignee", newValue);
                            setFieldValue("assignee_id", newValue?.id);
                          }}
                          className={
                            errors.assignee_id && touched.assignee_id
                              ? " is-invalid"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="assignee_id"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="inner-repeater mb-4">
                    <div className="inner form-group mb-0 row">
                      <Label className="col-form-label col-lg-2">
                        Reporter
                      </Label>
                      <div className="inner col-lg-10 ms-md-auto" id="repeater">
                        <Select
                          name="reporter"
                          isDisabled={guard !== "admin"}
                          isClearable
                          value={values.reporter}
                          options={team}
                          getOptionLabel={option => option.name || "Create New"}
                          getOptionValue={option => option.id}
                          onChange={newValue => {
                            setFieldValue("reporter", newValue);
                            setFieldValue("reporter_id", newValue?.id);
                          }}
                          className={
                            errors.reporter_id && touched.reporter_id
                              ? "is-invalid"
                              : ""
                          }
                        />
                        <ErrorMessage
                          name="reporter_id"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  {guard === "admin" && (
                    <>
                      <FormGroup className="mb-4" row>
                        <label
                          htmlFor="budget"
                          className="col-form-label col-lg-2"
                        >
                          Budget
                        </label>
                        <div className="col-lg-10">
                          <Field
                            name="budget"
                            type="number"
                            value={values.budget}
                            placeholder="Enter Task Budget..."
                            className="form-control"
                          />
                          <ErrorMessage
                            name="budget"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="mb-4" row>
                        <label
                          htmlFor="budget"
                          className="col-form-label col-lg-2"
                        >
                          Cost
                        </label>
                        <div className="col-lg-10">
                          <Field
                            name="cost"
                            type="number"
                            value={values.cost}
                            placeholder="Enter Task Cost..."
                            className="form-control"
                          />
                          <ErrorMessage
                            name="cost"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </FormGroup>
                    </>
                  )}
                </div>
              </div>
              <Row className="justify-content-end">
                <Col lg="auto">
                  <ButtonGroup>
                    <Button type="submit" color="primary">
                      Save
                    </Button>
                    {taskDetail?.id && guard === "admin" && (
                      <Button onClick={this.onClickDelete} color="danger">
                        Delete
                      </Button>
                    )}
                  </ButtonGroup>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

TaskForm.propTypes = {
  taskDetail: PropTypes.object,
  projectDetail: PropTypes.object,
  onAddNewTask: PropTypes.func,
  onDone: PropTypes.func,
  onUpdateTask: PropTypes.func,
  onDeleteTask: PropTypes.func,
};

const mapStateToProps = ({ projects }) => ({
  projectDetail: projects.projectDetail,
});

const mapDispatchToProps = dispatch => ({
  onAddNewTask: task => dispatch(addNewTask(task)),
  onUpdateTask: task => dispatch(updateTask(task)),
  onDeleteTask: id => dispatch(deleteTask(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskForm);
