import guard from "helpers/jwt-token-access/apiGuard";
import { post } from "../api_helper";
import * as url from "../url_helper";

// Login Method
export const postLogin = data => post(url.POST_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = data => post(url.POST_PASSWORD_FORGET, data);

// resetPwd
export const postResetPwd = data => post(url.POST_PASSWORD_RESET, data);

// Get Current User
export const getCurrentUser = (admin = guard !== "api") =>
  post(admin ? url.GET_CURRENT_ADMIN : url.GET_CURRENT_USER);
