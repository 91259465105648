import {
  GET_ROADSHOWS,
  GET_ROADSHOWS_FAIL,
  GET_ROADSHOWS_SUCCESS,
  GET_ROADSHOW_DETAIL,
  GET_ROADSHOW_DETAIL_FAIL,
  GET_ROADSHOW_DETAIL_SUCCESS,
  ADD_NEW_ROADSHOW,
  ADD_ROADSHOW_SUCCESS,
  ADD_ROADSHOW_FAIL,
  UPDATE_ROADSHOW,
  UPDATE_ROADSHOW_SUCCESS,
  UPDATE_ROADSHOW_FAIL,
  DELETE_ROADSHOW,
  DELETE_ROADSHOW_SUCCESS,
  DELETE_ROADSHOW_FAIL,
} from "./actionTypes";

export const getRoadshows = () => ({
  type: GET_ROADSHOWS,
});

export const getRoadshowsSuccess = roadshows => ({
  type: GET_ROADSHOWS_SUCCESS,
  payload: roadshows,
});

export const getRoadshowsFail = error => ({
  type: GET_ROADSHOWS_FAIL,
  payload: error,
});

export const getRoadshowDetail = roadshowId => ({
  type: GET_ROADSHOW_DETAIL,
  roadshowId,
});

export const getRoadshowDetailSuccess = roadshowDetails => ({
  type: GET_ROADSHOW_DETAIL_SUCCESS,
  payload: roadshowDetails,
});

export const getRoadshowDetailFail = error => ({
  type: GET_ROADSHOW_DETAIL_FAIL,
  payload: error,
});

export const addNewRoadshow = roadshow => ({
  type: ADD_NEW_ROADSHOW,
  payload: roadshow,
});

export const addRoadshowSuccess = roadshow => ({
  type: ADD_ROADSHOW_SUCCESS,
  payload: roadshow,
});

export const addRoadshowFail = error => ({
  type: ADD_ROADSHOW_FAIL,
  payload: error,
});

export const updateRoadshow = roadshow => ({
  type: UPDATE_ROADSHOW,
  payload: roadshow,
});

export const updateRoadshowSuccess = roadshow => ({
  type: UPDATE_ROADSHOW_SUCCESS,
  payload: roadshow,
});

export const updateRoadshowFail = error => ({
  type: UPDATE_ROADSHOW_FAIL,
  payload: error,
});

export const deleteRoadshow = roadshow => ({
  type: DELETE_ROADSHOW,
  payload: roadshow,
});

export const deleteRoadshowSuccess = roadshow => ({
  type: DELETE_ROADSHOW_SUCCESS,
  payload: roadshow,
});

export const deleteRoadshowFail = error => ({
  type: DELETE_ROADSHOW_FAIL,
  payload: error,
});
