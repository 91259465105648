import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";
import guard from "helpers/jwt-token-access/apiGuard";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guard: guard,
    };
  }

  componentDidMount() {
    let matchingMenuItem = null;
    const ul = document.getElementById("navigation");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  };

  render() {
    const { guard } = this.state;
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  {guard === "api" && (
                    <>
                      <Link to="/projects-grid" className="nav-link">
                        <i className="bx bx-customize me-2" />
                        {this.props.t("Projects")}
                      </Link>
                      <Link to="/chat" className="nav-link">
                        <i className="bx bx-chat me-2" />
                        {this.props.t("Chat")}
                      </Link>
                    </>
                  )}
                  {guard === "admin" && (
                    <>
                      <Link to="/dashboard" className="nav-link">
                        <i className="bx bx-home-circle me-2" />
                        {this.props.t("Dashboard")}
                      </Link>
                      <li className="nav-item dropdown">
                        <Link
                          to="/#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              projectState: !this.state.projectState,
                            });
                          }}
                          className="nav-link dropdown-toggle arrow-none"
                        >
                          <i className="bx bx-customize me-2" />
                          {this.props.t("Projects")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.projectState,
                          })}
                        >
                          <Link to="/projects" className="dropdown-item">
                            {this.props.t("Projects Overview")}
                          </Link>
                          <Link to="/projects-create" className="dropdown-item">
                            {this.props.t("Create New")}
                          </Link>
                          <Link to="/projects-grid" className="dropdown-item">
                            {this.props.t("All Projects")}
                          </Link>
                          <Link
                            to="/projects-list/archived"
                            className="dropdown-item"
                          >
                            {this.props.t("Archived Projects")}
                          </Link>
                          <Link to="/brands" className="dropdown-item">
                            {this.props.t("Brands")}
                          </Link>
                          <Link to="/clients" className="dropdown-item">
                            {this.props.t("Clients")}
                          </Link>
                          <Link to="/venues" className="dropdown-item">
                            {this.props.t("Venues")}
                          </Link>
                        </div>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/contacts-grid"
                          className="nav-link dropdown-toggle arrow-none"
                        >
                          <i className="bx bxs-contact me-2" />
                          {this.props.t("Contacts")}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/chat" className="nav-link">
                          <i className="bx bx-chat me-2" />
                          {this.props.t("Chat")}
                        </Link>
                      </li>
                      {/* <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle arrow-none"
                          to="/#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              extraState: !this.state.extraState,
                            });
                          }}
                        >
                          <i className="bx bx-file me-2" />
                          {this.props.t("Extras")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.extraState,
                          })}
                        >
                          <Link to="/calendar" className="dropdown-item">
                            {this.props.t("Calendar")}
                          </Link>
                          <Link to="/chat" className="dropdown-item">
                            {this.props.t("Chat")}
                          </Link>
                          <Link to="/tasks-kanban" className="dropdown-item">
                            {this.props.t("Board")}
                          </Link>
                          <Link to="/gantt-chart" className="dropdown-item">
                            {this.props.t("Gantt")}
                          </Link>
                          <Link
                            to="/apps-filemanager"
                            className="dropdown-item"
                          >
                            {this.props.t("File Manager")}
                          </Link>
                        </div>
                      </li> */}
                      <li className="nav-item">
                        <Link className="nav-link" to="/trainings">
                          <i className="bx bxs-graduation me-2" />
                          {this.props.t("Training")}
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Navbar));
